import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { E2ETaxonomyService } from '../../service/e2e-taxonomy.service';
import { UseCaseService } from '../../service/use-case.service';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from '../../../layout/service/app.layout.service';
import { AuthService } from '../../service/auth.service';
import { DbService } from '../../service/db.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DiagramComponent } from '../diagram/diagram.component';
import { Poc, PocInput } from './types';
import { DesignNexusService } from './design-nexus.service';
import { DesignNexusModalComponent } from './components/modal/design-nexus-modal.component';

@Component({
	templateUrl: './design-nexus.component.html',
	styleUrls: ['./design-nexus.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class DesignNexusComponent implements OnInit {
	constructor(
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public dialogService: DialogService,
		public layoutService: LayoutService,
		public authService: AuthService,
		public e2ETaxonomyService: E2ETaxonomyService,
		public useCaseService: UseCaseService,
		public route: ActivatedRoute,
		public dbService: DbService,
		public service: DesignNexusService,
	) {}

	loading: boolean = true;

	async ngOnInit() {
		this.layoutService.updateTitle('Hubble Design');

		await this.getData();

		this.loading = false;

		this.authService.organizationSource$.subscribe(() => {
			setTimeout(() => {
				this.getData();
			}, 150);
		});

		this.dbService.dbOrganizationUpdateSource$.subscribe(() => {
			setTimeout(() => {
				this.getData();
			}, 150);
		});
	}

	openModal(rowData: any): DynamicDialogRef {
		const useCase = this.service.useCases.find((u) => u.id === rowData.useCaseId);
		const opportunity = useCase?.opportunities.find((op) => op.id === rowData.opportunityId);
		console.log('opportunity', opportunity)
		const pocInput: PocInput = {
			...(useCase?.opportunities.find((op) => op.id === rowData.opportunityId)?.poc as Poc),
			useCaseId: rowData.useCaseId,
			aiOpportunityId: rowData.opportunityId,
		};
		return this.dialogService.open(DesignNexusModalComponent, {
			data: { pocInput, opportunity  },
			header: pocInput.title || 'Use Case',
			width: '95%',
			height: '100%',
			contentStyle: { overflow: 'auto' },
			showHeader: true,
			baseZIndex: 1,
			maximizable: false,
			closeOnEscape: false,
			styleClass: 'design-nexus-modal',
		});
	}

	loading_data: boolean = false;

	async getData() {
		this.loading_data = true;

		await this.service.onLoad();

		this.loading_data = false;
	}

	async openDiagram(useCaseId: string, diagramId: string): Promise<DynamicDialogRef> {
		document.body.style.cursor = 'wait';
		await this.useCaseService.setCurrentUseCase(useCaseId);
		const ref: DynamicDialogRef = this.dialogService.open(DiagramComponent, {
			data: { diagramId },
			header: 'Diagram',
			width: '100%',
			height: '100%',
			styleClass: 'diagram-modal',
			contentStyle: { overflow: 'auto' },
			showHeader: false,
			baseZIndex: 100,
			maximizable: false,
			closeOnEscape: false,
		});
		setTimeout(() => {
			document.body.style.cursor = 'default';
		}, 150);

		ref.onClose.subscribe(() => {
			this.service.getData();
		});
		return ref;
	}
}
