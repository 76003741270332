import { NgModule } from '@angular/core';
import {
	AsyncPipe, JsonPipe,
	NgClass,
	NgForOf,
	NgIf,
	NgOptimizedImage,
	NgStyle,
	NgSwitch, NgSwitchCase, NgSwitchDefault,
	NgTemplateOutlet,
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApolloModule } from 'apollo-angular';

import { NotfoundComponent } from './components/notfound/notfound.component';
import { WorkSiteComponent } from './components/work-site/work-site.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LoadTokenComponent } from './components/load-token/load-token.component';
import { DiagramComponent } from './components/diagram/diagram.component';
import { AiExplorerComponent } from './components/ai-explorer/ai-explorer.component';
import { AiExplorerService } from './components/ai-explorer/ai-explorer.service';
import { UseCasesService } from './components/simulation/use-cases.service';
import { MaturityFrameworkComponent } from './components/maturity-framework/maturity-framework';
import { MaturityFrameworkAssessmentComponent } from './components/maturity-framework/assessment/maturity-framework-assessment';
import { SimulationComponent } from './components/simulation/simulation.component';
import { M1PrepComponent } from './components/simulation/m1-prep/m1-prep.component';
import { SimulationItemComponent } from './components/simulation/simulation-item/simulation-item.component';
import { DashboardInfoComponent } from './components/simulation/dashboard-info/dashboard-info.component';
import { TaxonomyComponent } from './components/simulation/taxonomy/taxonomy.component';
import { EnterpriseContextComponent } from './components/simulation/enterprise-context/enterprise-context.component';
import { UseCaseSimulationItemComponent } from './components/simulation/use-case-simulation-item/use-case-simulation-item.component';
import { UseCaseLibraryItemComponent } from './components/simulation/use-case-library-item/use-case-library-item.component';
import { UseCaseDetailComponent } from './components/simulation/use-case-detail/use-case-detail.component';
import { PromptStudioComponent } from './components/simulation/prompt-studio/prompt-studio.component';
import { DesignNexusComponent } from './components/design-nexus/design-nexus.component';
import { DesignNexusModalComponent } from './components/design-nexus/components/modal/design-nexus-modal.component';
import { DesignNexusRequirementsComponent } from './components/design-nexus/components/requirements/design-nexus-requirements.component';
import { AttachmentModalComponent } from './components/design-nexus/components/attachment-modal/design-nexus-attachment-modal.component';
import { UseCasesComponent } from './components/use-cases/use-cases.component';
import { UseCaseReadinessComponent } from './components/use-cases/readiness/stepper/use-case-readiness.component';
import { CategoryFormReadinessComponent } from './components/use-cases/readiness/category-form/category-form.component';
import { GeneralFormReadinessComponent } from './components/use-cases/readiness/general-form/general-form.component';
import { ResultReadinessComponent } from './components/use-cases/readiness/result/result.component';
import { BenefitsAssessmentComponent } from './components/use-cases/benefits/benefits-assessment';

import { RtpRoutingModule } from './rtp-routing.module';
import { AppLayoutModule } from '../layout/app.layout.module';

import { GraphqlService } from './service/graphql.service';
import { DbService } from './service/db.service';
import { CommonService } from './service/common.service';
import { AuthService } from './service/auth.service';
import { E2ETaxonomyService } from './service/e2e-taxonomy.service';
import { E2ETaxonomyV2Service } from './service/e2e-taxonomy_v2.service';
import { SolutionService } from './service/solution.service';
import { UseCaseService } from './service/use-case.service';
import { ApiInterceptor } from './service/http.interceptor';
import { ApiService } from './service/api.service';

import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SplitterModule } from 'primeng/splitter';
import { InputTextModule } from 'primeng/inputtext';
import { TreeModule } from 'primeng/tree';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataViewModule } from 'primeng/dataview';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DropdownModule } from 'primeng/dropdown';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ChipModule } from 'primeng/chip';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { ChartModule } from 'primeng/chart';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { MenubarModule } from 'primeng/menubar';
import { FileUploadModule } from 'primeng/fileupload';
import { TreeSelectModule } from 'primeng/treeselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { StepsModule } from 'primeng/steps';
import { MatStepperModule } from '@angular/material/stepper';
import { InputNumberModule } from 'primeng/inputnumber';
import { EditorModule } from 'primeng/editor';
import { SliderModule } from 'primeng/slider';
import { SkeletonModule } from 'primeng/skeleton';
import { NgxSliderModule } from 'ngx-slider-v2';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { SlideMenuModule } from 'primeng/slidemenu';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { PasswordModule } from 'primeng/password';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BlockUIModule } from 'primeng/blockui';
import { MatIconModule } from '@angular/material/icon';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { RippleModule } from 'primeng/ripple';
import { CardModule } from 'primeng/card';
import { MatCardModule } from '@angular/material/card';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PaginatorModule } from 'primeng/paginator';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';

import { GenAIComponent } from './components/ai-explorer/svg/gen-ai.component';
import { PAComponent } from './components/ai-explorer/svg/pa.component';
import { CAComponent } from './components/ai-explorer/svg/ca.component';
import { IDCComponent } from './components/ai-explorer/svg/idc.component';
import { IOComponent } from './components/ai-explorer/svg/io.component';
import { TAComponent } from './components/ai-explorer/svg/ta.component';
import { OTPComponent } from './components/ai-explorer/svg/otp.component';
import { Filter1Component } from './components/ai-explorer/svg/filter1.component';
import { Filter2Component } from './components/ai-explorer/svg/filter2.component';
import { Filter3Component } from './components/ai-explorer/svg/filter3.component';
import { PyramidComponent } from './components/simulation/dashboard-info/svg/pyramid.component';
import { HackettLogoComponent } from './components/simulation/taxonomy/svg/hackett-logo.component';
import { PyramidTextComponent } from './components/simulation/dashboard-info/svg/pyramid-text.component';

import { ImageComponent } from './components/common/image.component';
import { BlockableDivComponent } from './components/common/blockable.component';
import { DragDropModule } from 'primeng/dragdrop';
import { TreeTableModule } from 'primeng/treetable';
import { ConnectIconComponent } from './components/diagram/icons/connect-icon.component';
import { ComplexityIconComponent } from './components/diagram/icons/complexity-icon.component';
import { DesignNexusService } from './components/design-nexus/design-nexus.service';
import {
	DesignNexusInputsDataSourceTableComponent
} from './components/design-nexus/components/inputs-data-source-table/design-nexus-inputs-data-source-table.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
	imports: [
		AppLayoutModule,
		RtpRoutingModule,
		ApolloModule,
		HttpClientModule,
		ToastModule,
		ConfirmDialogModule,
		SplitterModule,
		InputTextModule,
		TreeModule,
		FormsModule,
		DataViewModule,
		CheckboxModule,
		ButtonModule,
		SplitButtonModule,
		NgClass,
		NgIf,
		DropdownModule,
		ContextMenuModule,
		ProgressBarModule,
		ChipModule,
		NgForOf,
		NgOptimizedImage,
		ReactiveFormsModule,
		AccordionModule,
		TabViewModule,
		ChartModule,
		DialogModule,
		InputTextareaModule,
		MultiSelectModule,
		MenubarModule,
		FileUploadModule,
		TreeSelectModule,
		OverlayPanelModule,
		StepsModule,
		MatStepperModule,
		InputNumberModule,
		NgStyle,
		EditorModule,
		SliderModule,
		SkeletonModule,
		NgxSliderModule,
		InputSwitchModule,
		NgTemplateOutlet,
		MatSlideToggleModule,
		ConfirmPopupModule,
		SlideMenuModule,
		AutoCompleteModule,
		BadgeModule,
		PasswordModule,
		TriStateCheckboxModule,
		ToggleButtonModule,
		SelectButtonModule,
		BlockUIModule,
		MatIconModule,
		MenuModule,
		RippleModule,
		AsyncPipe,
		VirtualScrollerModule,
		MatCardModule,
		ScrollPanelModule,
		CardModule,
		PaginatorModule,
		DividerModule,
		TableModule,
		DragDropModule,
		TreeTableModule,
		NgSwitch,
		NgSwitchCase,
		NgSwitchDefault,
		JsonPipe,
		NgxExtendedPdfViewerModule,
	],
	declarations: [
		NotfoundComponent,
		WorkSiteComponent,
		SignInComponent,
		ChangePasswordComponent,
		LoadTokenComponent,
		DiagramComponent,
		AiExplorerComponent,
		MaturityFrameworkComponent,
		MaturityFrameworkAssessmentComponent,
		SimulationComponent,
		SimulationItemComponent,
		TaxonomyComponent,
		UseCaseDetailComponent,
		DesignNexusComponent,
		GenAIComponent,
		PAComponent,
		CAComponent,
		IDCComponent,
		IOComponent,
		TAComponent,
		OTPComponent,
		ImageComponent,
		BlockableDivComponent,
		Filter1Component,
		Filter2Component,
		Filter3Component,
		PyramidComponent,
		PyramidTextComponent,
		HackettLogoComponent,
		EnterpriseContextComponent,
		DashboardInfoComponent,
		PromptStudioComponent,
		UseCaseSimulationItemComponent,
		UseCaseLibraryItemComponent,
		UseCasesComponent,
		UseCaseReadinessComponent,
		CategoryFormReadinessComponent,
		GeneralFormReadinessComponent,
		ResultReadinessComponent,
		BenefitsAssessmentComponent,
		M1PrepComponent,
		DesignNexusModalComponent,
		DesignNexusRequirementsComponent,
		AttachmentModalComponent,
		ConnectIconComponent,
		ComplexityIconComponent,
		DesignNexusInputsDataSourceTableComponent,
	],
	providers: [
		DbService,
		CommonService,
		AuthService,
		DialogService,
		MessageService,
		E2ETaxonomyService,
		E2ETaxonomyV2Service,
		SolutionService,
		UseCaseService,
		GraphqlService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiInterceptor,
			multi: true,
		},
		AiExplorerService,
		UseCasesService,
		ApiService,
		DesignNexusService,
	],
	exports: [UseCasesComponent],
})
export class RtpModule {}
