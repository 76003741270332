<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<div class="card card-content-design-nexus">
	<div class="flex flex-column w-full p-0">
		<div class="data-header">
			<div class="flex flex-wrap justify-content-between pt-3 pb-2 px-4">
				<div class="flex flex-wrap justify-content-start gap-2">
					<p class="mb-1 text-lg md:text-xl lg:text-2xl xl:text-2xl">
						<span class="text-primary mb-0 mt-2 pl-1">Active Use Cases</span>
					</p>
				</div>
				<div
					class="header-buttons w-full md:w-auto lg:w-auto xl:w-auto flex flex-wrap justify-content-between gap-2 ml-auto"
				>
					<!-- <p-button icon="pi pi-file-export" label="Export"></p-button> -->
				</div>
			</div>
		</div>
		<div class="pb-3 pl-2 pr-4">
			<p-treeTable
				[value]="service.data"
				[scrollable]="true"
				[tableStyle]="{ 'min-width': '50rem' }"
				class="hubble-design"
			>
				<ng-template pTemplate="header">
					<tr>
						<th ttSortableColumn="name">
							Use Case
							<!-- <p-treeTableSortIcon field="name" /> -->
						</th>
						<th ttSortableColumn="name">
							Opportunity Source
							<!-- <p-treeTableSortIcon field="name" /> -->
						</th>
						<th ttSortableColumn="name">
							Impact Type
							<!-- <p-treeTableSortIcon field="name" /> -->
						</th>
						<th ttSortableColumn="name">
							Time to Build
							<!-- <p-treeTableSortIcon field="name" /> -->
						</th>
						<th ttSortableColumn="name">
							ICE score
							<!-- <p-treeTableSortIcon field="name" /> -->
						</th>
						<th>Actions</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
					<tr>
						<ng-template [ngIf]="rowData.level1">
							<td colspan="5" class="row-level1">
								{{ rowData?.name }}
							</td>
							<td class="row-level1">
								<button
									class="p-button p-button-icon-only p-button-link toggle-parent-row"
									type="button"
									(click)="openDiagram(rowData.useCaseId, rowData.diagramId)"
								>
									<span class="pi pi-sitemap"></span>
								</button>
								<p-treeTableToggler [rowNode]="rowNode" class="level1"></p-treeTableToggler>
							</td>
						</ng-template>
						<ng-template [ngIf]="rowData.level2">
							<td>
								{{ rowData?.name }}
							</td>
							<td>
								{{ rowData?.opportunitySource }}
							</td>
							<td>
								{{ rowData?.impactType }}
							</td>
							<td>
								<p-chip
									[label]="rowData?.timeToBuild"
									[ngClass]="['chip-table', rowData?.timeToBuild || '']"
								></p-chip>
							</td>
							<td>
								<p-progressBar class="progress-bar-table" [value]="rowData?.iceScore || 0">
								</p-progressBar>
							</td>
							<td>
								<button
									class="p-button p-button-icon-only p-button-link toggle-grandchild-row"
									type="button"
									(click)="openModal(rowData)"
								>
									<span class="pi pi-external-link"></span>
								</button>
								<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
							</td>
						</ng-template>
						<ng-template [ngIf]="rowData.level3">
							<td colspan="6" class="no-padding">
								<table class="w-full level3">
									<thead>
										<tr class="th-row">
											<th class="py-2 text-center" colspan="9">ICE Score</th>
										</tr>
										<tr>
											<th class="py-2 text-center" colspan="3">Impact</th>
											<th class="py-2 text-center" colspan="3">Confidence</th>
											<th class="py-2 text-center" colspan="3">Ease</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td class="text-center" colspan="3">{{ rowData?.impact }}</td>
											<td class="text-center" colspan="3">{{ rowData?.confidence }}</td>
											<td class="text-center" colspan="3">{{ rowData?.ease }}</td>
										</tr>
									</tbody>
								</table>
							</td>
						</ng-template>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<div *ngIf="loading_data">
						<div class="col-12 pt-5 pb-1">
							<div class="grid">
								<div class="col-4">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col-2">
									<p-skeleton></p-skeleton>
								</div>
							</div>
						</div>
						<div class="col-12 pb-1">
							<div class="grid">
								<div class="col-4">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col-2">
									<p-skeleton></p-skeleton>
								</div>
							</div>
						</div>
						<div class="col-12 pb-1">
							<div class="grid">
								<div class="col-4">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col-2">
									<p-skeleton></p-skeleton>
								</div>
							</div>
						</div>
						<div class="col-12 pb-1">
							<div class="grid">
								<div class="col-4">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col-2">
									<p-skeleton></p-skeleton>
								</div>
							</div>
						</div>
						<div class="col-12 pb-1">
							<div class="grid">
								<div class="col-4">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col">
									<p-skeleton></p-skeleton>
								</div>
								<div class="col-2">
									<p-skeleton></p-skeleton>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</p-treeTable>
		</div>
	</div>
</div>
