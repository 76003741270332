import { Injectable } from '@angular/core';
import { createClient, Client } from './graphql';
import { ClientOptions } from './graphql/runtime';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

type ObjectType = 'use_case' | 'poc';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	private _client: Client | undefined;

	constructor(private authService: AuthService, private http: HttpClient) {
		this.authService.updateSource$.subscribe(() => {
			this._client = createClient(this._clientOptions);
		});
	}

	private get _clientOptions(): ClientOptions {
		return {
			url: environment.url + '/graphql',
			headers: localStorage.getItem('auth-token')
				? {
						Authorization: 'JWT ' + localStorage.getItem('auth-token'),
				  }
				: {},
		};
	}

	get client(): Client {
		if (!this._client) {
			this._client = createClient(this._clientOptions);
		}
		return this._client;
	}

	async organizationArgs() {
		return {
			__args: {
				organizationId: await this.authService.getCurrentOrganizationId(),
			},
		};
	}

	queryArgs(query: any) {
		return {
			__args: {
				query,
			},
		};
	}

	idArgs(id: string) {
		return this.queryArgs({ id });
	}

	mergeArgs<T>(...args: { __args: any }[]): T {
		return {
			__args: args.reduce(
				(acc, current) => ({
					...acc,
					...current.__args,
				}),
				{},
			),
		} as any;
	}

	private get_urls_event(key: ObjectType): string {
		const data = {
			use_case: 'use_case/use_case/',
			poc: 'use_case/poc/',
		};
		return data[key];
	}

	async putObject<T extends { id: string }>(obj: T, type: ObjectType, organization: boolean = false) {
		if (organization) {
			(obj as any).organizationId = await this.authService.getCurrentOrganizationId();
		}
		if (!(obj as any).created_at) {
			(obj as any).created_at = new Date().getTime();
		}
		(obj as any).updated_at = new Date().getTime();
		return firstValueFrom(this.http.patch('@api/' + this.get_urls_event(type) + obj.id + '/', obj));
	}

	async putObjects<T extends { id: string }>(objs: T[], type: ObjectType, organization: boolean = false) {
		if (organization) {
			const organizationId = await this.authService.getCurrentOrganizationId();
			objs = objs.map((obj) => {
				(obj as any).organizationId = organizationId;
				if (!(obj as any).created_at) {
					(obj as any).created_at = new Date().getTime();
				}
				(obj as any).updated_at = new Date().getTime();
				return obj;
			});
		}
		return Promise.all(
			objs.map((obj) => firstValueFrom(this.http.patch('@api/' + this.get_urls_event(type) + obj.id + '/', obj))),
		);
	}
}
