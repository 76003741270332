import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import deepEqual from 'deep-equal';
import { plainToInstance, instanceToInstance } from 'class-transformer';
import { DbService } from './db.service';
import { SolutionService } from './solution.service';
import { createdAt } from './services.data/utils.data';
import { Diagram, IAnswerFormData, ICategoryFormData, IUseCase, UseCase } from '../api/use-case.api';
import { E2ETaxonomyService } from './e2e-taxonomy.service';
import { Question } from '../api/solution.api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DiagramBaseService } from './base.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OptionGetUseCases } from './use-case.types';
import { DiagramBase, IDiagramBase, IDiagramDataItem } from '../api/base.api';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataUpdateDiagram } from '../components/diagram/diagram.types';
import { DiagramComponent } from '../components/diagram/diagram.component';
import { firstValueFrom } from 'rxjs';

/**
 * UseCaseService class
 * @extends DiagramBaseService<Diagram>
 * @classdesc A service class for managing use cases.
 * @constructor
 * @param {AuthService} authService - The authentication service.
 * @param {DbService} dbService - The database service.
 * @param {E2ETaxonomyService} e2ETaxonomyService - The E2E taxonomy service.
 * @param {SolutionService} solutionService - The solution service.
 * @param {Router} router - The router service.
 * @param {DialogService} dialogService - The dialog service.
 * @param {HttpClient} http - The HTTP client service.
 */
@Injectable({
	providedIn: 'root',
})
export class UseCaseService {
	constructor(
		public authService: AuthService,
		public dbService: DbService,
		public e2ETaxonomyService: E2ETaxonomyService,
		public solutionService: SolutionService,
		public router: Router,
		public dialogService: DialogService,
		public http: HttpClient,
	) {}

	currentUseCase: UseCase | undefined;
	currentOriginalUseCase: UseCase | undefined;

	/**
	 * Creates a new instance of UseCase with default values.
	 *
	 * @return {UseCase} - A new instance of UseCase with default values.
	 */
	_newUseCase(): UseCase {
		return plainToInstance(UseCase, {
			id: uuid(),
			created_at: createdAt(),
			name: '',
			description: '',
			solution: undefined,
			technologiesIds: [],
			levels2Ids: [],
			formData: {},
			aiValues: {},
			valid: false,
			nexus: {},
			benefitsValues: {},
		});
	}

	_newDiagramBase(xml?: string, capture_url?: string, data?: { [key: string]: IDiagramDataItem }): IDiagramBase {
		return {
			id: uuid(),
			xmlImage: capture_url || '',
			xmlData: xml?.startsWith('http') ? xml : '',
			data: data || {},
		};
	}

	/**
	 * Creates a new instance of the Diagram class.
	 *
	 * @param {IUseCase} useCase - The use case object.
	 * @param {string} xml - The XML string.
	 * @param {string} [capture_url] - The URL for capturing the diagram.
	 * @param {Object} [data] - The additional data for the diagram.
	 * @returns {Diagram} - The newly created Diagram instance.
	 */
	_newDiagram(
		useCase: IUseCase,
		xml: string,
		capture_url?: string,
		data?: { [key: string]: IDiagramDataItem },
	): Diagram {
		return plainToInstance(Diagram, {
			useCase,
			useCaseId: useCase.id || '',
			...this._newDiagramBase(xml, capture_url, data),
		});
	}

	/**
	 * Creates a new empty diagram.
	 *
	 * @param {IUseCase} useCase - The use case object.
	 * @return {Diagram} - The newly created diagram.
	 */
	_newDiagramEmpty(useCase: IUseCase): Diagram {
		return plainToInstance(Diagram, {
			useCase,
			useCaseId: useCase.id || '',
			organizationId: useCase.organizationId || '',
			...this._newDiagramBase(),
		});
	}

	emptyDiagram = `<?xml version="1.0" encoding="UTF-8"?>
<bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn">
  <bpmn2:process id="Process_1" isExecutable="false">
    <bpmn2:startEvent id="StartEvent_1"/>
  </bpmn2:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds height="36.0" width="36.0" x="412.0" y="240.0"/>
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn2:definitions>`;

	/**
	 * Creates a new use case value object.
	 *
	 * @returns {ICategoryFormData} The newly created use case value object.
	 */
	_newUseCaseValue(): ICategoryFormData {
		return {
			value: '',
			effort: '',
			impact: '',
			answers: {},
		};
	}

	/**
	 * Creates a new answer for a use case.
	 *
	 * @returns {IAnswerFormData} The newly created answer.
	 */
	_newUseCaseAnswer(): IAnswerFormData {
		return {
			value: '0',
			note: '',
		};
	}

	/**
	 * Retrieves use cases based on specified options.
	 *
	 * @param {OptionGetUseCases} options - The options for filtering the use cases. (default: {})
	 * @param {UseCase[]} useCases - Optional array of pre-loaded use cases. (default: [])
	 * @returns {Promise<UseCase[]>} - The array of use cases that match the specified options.
	 */
	async getUseCases(options: OptionGetUseCases = {}, useCases?: UseCase[]): Promise<UseCase[]> {
		const organization = await this.authService.getCurrentOrganizationId();
		if (!useCases || (useCases && !useCases.length)) {
			useCases = await this.dbService.data_use_case
				.where('organizationId')
				.equals(organization)
				.reverse()
				.sortBy('created_at');
		} else {
			useCases = await this.dbService.data_use_case
				.where('id')
				.anyOf(useCases.map((u) => u.id as any))
				.reverse()
				.sortBy('created_at');
		}
		const data: UseCase[] = [];
		if (Object.values(options).filter((v) => !!v).length === 0) {
			data.push(...useCases);
		} else {
			for (const useCase of useCases) {
				if (useCase.solutionId) {
					const solution = await this.solutionService.getSolution(useCase.solutionId);
					if (solution) {
						useCase.solution = solution;
					}
				}
				if (options.level2Ids && options.level2Ids.length) {
					if (useCase.levels2Ids.filter((l2) => options.level2Ids?.includes(l2)).length) {
						data.push(useCase);
					}
				} else if (options.level2Id) {
					if (useCase.levels2Ids.includes(options.level2Id)) {
						data.push(useCase);
					}
				} else if (options.level1Ids && options.level1Ids.length) {
					let levels2Ids: string[] = [];
					for (const level1Id of options.level1Ids) {
						levels2Ids.push(
							...(await this.e2ETaxonomyService.getLevels2({ level1Id: level1Id }))
								.map((l2) => l2.id || '')
								.filter((s) => !!s),
						);
					}
					levels2Ids = Array.from(new Set(levels2Ids));
					if (useCase.levels2Ids.filter((l2) => levels2Ids.includes(l2)).length) {
						data.push(useCase);
					}
				} else if (options.level1Id) {
					const levels2Ids = Array.from(
						new Set(
							(await this.e2ETaxonomyService.getLevels2({ level1Id: options.level1Id }))
								.map((l2) => l2.id || '')
								.filter((s) => !!s),
						),
					);
					if (useCase.levels2Ids.filter((l2) => levels2Ids.includes(l2)).length) {
						data.push(useCase);
					}
				} else if (options.functionIds && options.functionIds.length) {
					let levels1Ids: string[] = [];
					for (const functionId of options.functionIds) {
						levels1Ids.push(
							...(await this.e2ETaxonomyService.getLevels1({ functionId }))
								.map((l2) => l2.id || '')
								.filter((s) => !!s),
						);
					}
					levels1Ids = Array.from(new Set(levels1Ids));
					let levels2Ids: string[] = [];
					for (const level1Id of levels1Ids) {
						levels2Ids.push(
							...(await this.e2ETaxonomyService.getLevels2({ level1Id: level1Id }))
								.map((l2) => l2.id || '')
								.filter((s) => !!s),
						);
					}
					levels2Ids = Array.from(new Set(levels2Ids));
					if (useCase.levels2Ids.filter((l2) => levels2Ids.includes(l2)).length) {
						data.push(useCase);
					}
				} else if (options.functionId) {
					const levels1Ids = Array.from(
						new Set(
							(await this.e2ETaxonomyService.getLevels1({ functionId: options.functionId }))
								.map((l2) => l2.id || '')
								.filter((s) => !!s),
						),
					);
					let levels2Ids: string[] = [];
					for (const level1Id of levels1Ids) {
						levels2Ids.push(
							...(await this.e2ETaxonomyService.getLevels2({ level1Id: level1Id }))
								.map((l2) => l2.id || '')
								.filter((s) => !!s),
						);
					}
					levels2Ids = Array.from(new Set(levels2Ids));
					if (useCase.levels2Ids.filter((l2) => levels2Ids.includes(l2)).length) {
						data.push(useCase);
					}
				} else if (options.categoryFunctionIds && options.categoryFunctionIds.length) {
					let functionsIds: string[] = [];
					for (const categoryFunctionId of options.categoryFunctionIds) {
						functionsIds.push(
							...(await this.e2ETaxonomyService.getFunctions({ categoryId: categoryFunctionId }))
								.map((l2) => l2.id || '')
								.filter((s) => !!s),
						);
					}
					functionsIds = Array.from(new Set(functionsIds));
					let levels1Ids: string[] = [];
					for (const functionId of functionsIds) {
						levels1Ids.push(
							...(await this.e2ETaxonomyService.getLevels1({ functionId }))
								.map((l2) => l2.id || '')
								.filter((s) => !!s),
						);
					}
					levels1Ids = Array.from(new Set(levels1Ids));
					let levels2Ids: string[] = [];
					for (const level1Id of levels1Ids) {
						levels2Ids.push(
							...(await this.e2ETaxonomyService.getLevels2({ level1Id: level1Id }))
								.map((l2) => l2.id || '')
								.filter((s) => !!s),
						);
					}
					levels2Ids = Array.from(new Set(levels2Ids));
					if (useCase.levels2Ids.filter((l2) => levels2Ids.includes(l2)).length) {
						data.push(useCase);
					}
				} else if (options.categoryFunctionId) {
					const functionsIds = Array.from(
						new Set(
							(await this.e2ETaxonomyService.getFunctions({ categoryId: options.categoryFunctionId }))
								.map((l2) => l2.id || '')
								.filter((s) => !!s),
						),
					);
					let levels1Ids: string[] = [];
					for (const functionId of functionsIds) {
						levels1Ids.push(
							...(await this.e2ETaxonomyService.getLevels1({ functionId }))
								.map((l2) => l2.id || '')
								.filter((s) => !!s),
						);
					}
					levels1Ids = Array.from(new Set(levels1Ids));
					let levels2Ids: string[] = [];
					for (const level1Id of levels1Ids) {
						levels2Ids.push(
							...(await this.e2ETaxonomyService.getLevels2({ level1Id: level1Id }))
								.map((l2) => l2.id || '')
								.filter((s) => !!s),
						);
					}
					levels2Ids = Array.from(new Set(levels2Ids));
					if (useCase.levels2Ids.filter((l2) => levels2Ids.includes(l2)).length) {
						data.push(useCase);
					}
				}
			}
		}
		for (const d of data) {
			await this.getRelationUseCase(d);
		}
		return data;
	}

	useCaseParseValue(value: number | undefined) {
		if (value === undefined) {
			value = 0;
		}
		if (value >= 7) {
			return '2';
		} else if (value >= 4) {
			return '1';
		} else if (value >= 0) {
			return '0';
		} else {
			return '-1';
		}
	}

	useCaseGetLabel(value_o: string | number): '---' | 'High' | 'Medium' | 'Low' {
		const value = typeof value_o === 'string' ? parseFloat(value_o) : value_o;
		if (value > 2) {
			return 'High';
		} else if (value > 1) {
			return 'Medium';
		} else if (value > 0) {
			return 'Low';
		} else {
			return '---';
		}
	}

	useCaseGetLabelFeasibility(value_o: string | number): '---' | 'Ready' | 'Remediate' | 'Stop' {
		const value = typeof value_o === 'string' ? parseFloat(value_o) : value_o;
		if (value > 2) {
			return 'Ready';
		} else if (value > 1) {
			return 'Remediate';
		} else if (value > 0) {
			return 'Stop';
		} else {
			return '---';
		}
	}

	useCaseGetBackground(value_o: string | number): string {
		const value = typeof value_o === 'string' ? parseFloat(value_o) : value_o;
		const css = [];
		if (value > 2) {
			css.push('background-high');
		} else if (value > 1) {
			css.push('background-medium');
		} else if (value > 0) {
			css.push('background-low');
		} else {
			css.push('background-none');
		}
		return css.join(' ');
	}

	useCaseGetBackgroundColor(value_string: string): string {
		const value = parseFloat(value_string);
		if (value > 2) {
			return '#b7f1ab';
		} else if (value > 1) {
			return '#f8eba6';
		} else if (value > 0) {
			return '#ea9393';
		} else {
			return '#dedeff';
		}
	}

	useCaseGetPercentLabel(value: number): '---' | 'Ready' | 'Remediate' | 'Stop' {
		if (value > 75) {
			return 'Ready';
		} else if (value > 55) {
			return 'Remediate';
		} else if (value > 0) {
			return 'Stop';
		} else {
			return '---';
		}
	}

	useCaseGetPercentBackground(value: number) {
		const css = [];
		if (value > 75) {
			css.push('background-high');
		} else if (value > 55) {
			css.push('background-medium');
		} else if (value > 0) {
			css.push('background-low');
		} else {
			css.push('background-none');
		}
		return css.join(' ');
	}

	useCaseGetPercentBackgroundColor(value: number): string {
		if (value > 75) {
			return '#b7f1ab';
		} else if (value > 55) {
			return '#f8eba6';
		} else if (value > 0) {
			return '#ea9393';
		} else {
			return '#dedeff';
		}
	}

	/**
	 * Retrieves and updates the relation use case.
	 *
	 * @param {UseCase} useCase - The use case to retrieve and update the relation for.
	 * @param {Object} fromParams - The optional parameters used to retrieve the relation.
	 * @param {string} [fromParams.solutionId] - The ID of the solution to retrieve the relation from.
	 * @param {string} [fromParams.level2Id] - The ID of the level2 to retrieve the relation from.
	 *
	 * @return {Promise<void>} - A promise that resolves when the relation use case is retrieved and updated.
	 */
	async getRelationUseCase(
		useCase: UseCase,
		fromParams?: {
			solutionId?: string;
			level2Id?: string;
		},
	): Promise<void> {
		if (useCase) {
			if (useCase.simulationData === undefined || !Object.keys(useCase.simulationData || {}).length) {
				const useCaseSimulation = await this.dbService.data_use_case_simulation.get({
					deployedId: useCase.id as string,
				});
				if (useCaseSimulation) {
					useCase.simulationData = useCaseSimulation;
				}
			}

			if (!(useCase.simulationData === undefined || !Object.keys(useCase.simulationData || {}).length)) {
				if (useCase.simulationData.data === undefined) {
					useCase.simulationData.data = {
						llm_explanation: '',
						data_requirements: '',
						potential_challenges: '',
						process: '',
						subprocess: '',
						valuesDriversScores: {
							revenue_growth: 0,
							customer_experience: 0,
							process_productivity: 0,
							employee_productivity: 0,
							cost_savings: 0,
						},
					};
				}
				if (useCase.simulationData.data.valuesDriversScores === undefined) {
					useCase.simulationData.data.valuesDriversScores = {
						revenue_growth: 0,
						customer_experience: 0,
						process_productivity: 0,
						employee_productivity: 0,
						cost_savings: 0,
					};
				}
				const matrixValue = this.useCaseParseValue(
					useCase.simulationData.data.valuesDriversScores.revenue_growth * 0.3 +
						useCase.simulationData.data.valuesDriversScores.customer_experience * 0.25 +
						useCase.simulationData.data.valuesDriversScores.process_productivity * 0.2 +
						useCase.simulationData.data.valuesDriversScores.employee_productivity * 0.15 +
						useCase.simulationData.data.valuesDriversScores.cost_savings * 0.1,
				);

				useCase.calculatedData = {
					impact: useCase.simulationData.context.impact[0] || '---',
					topBenefits: [],
					matrixValue: {
						value: parseInt(matrixValue),
						color: this.useCaseGetBackgroundColor(matrixValue),
						label_value: this.useCaseGetLabel(matrixValue),
						class_value: this.useCaseGetBackground(matrixValue),
					},
					matrixReadiness: {
						value: 0,
						color: this.useCaseGetPercentBackgroundColor(0),
						label_value: this.useCaseGetPercentLabel(0),
						class_value: this.useCaseGetPercentBackground(0),
					},
					matrixBenefits: useCase.getMatrixBenefit(),
				};
			}

			if (
				(useCase.simulationData === undefined || !Object.keys(useCase.simulationData || {}).length) &&
				useCase.libraryData !== undefined &&
				Object.keys(useCase.libraryData || {}).length
			) {
				const matrixValue = this.useCaseParseValue(
					useCase.libraryData.data.valuesDriversScores.revenue_growth * 0.3 +
						useCase.libraryData.data.valuesDriversScores.customer_experience * 0.25 +
						useCase.libraryData.data.valuesDriversScores.process_productivity * 0.2 +
						useCase.libraryData.data.valuesDriversScores.employee_productivity * 0.15 +
						useCase.libraryData.data.valuesDriversScores.cost_savings * 0.1,
				);

				useCase.calculatedData = {
					impact: useCase.libraryData.data.impact_assessment,
					topBenefits: [],
					matrixValue: {
						value: parseInt(matrixValue),
						color: this.useCaseGetBackgroundColor(matrixValue),
						label_value: this.useCaseGetLabel(matrixValue),
						class_value: this.useCaseGetBackground(matrixValue),
					},
					matrixReadiness: {
						value: 0,
						color: this.useCaseGetPercentBackgroundColor(0),
						label_value: this.useCaseGetPercentLabel(0),
						class_value: this.useCaseGetPercentBackground(0),
					},
					matrixBenefits: useCase.getMatrixBenefit(),
				};
			}

			if (useCase.calculatedData === undefined || !Object.keys(useCase.calculatedData || {}).length) {
				useCase.calculatedData = {
					impact: '---',
					topBenefits: [],
					matrixValue: {
						value: 0,
						color: this.useCaseGetBackgroundColor('0'),
						label_value: this.useCaseGetLabel('0'),
						class_value: this.useCaseGetBackground('0'),
					},
					matrixReadiness: {
						value: 0,
						color: this.useCaseGetPercentBackgroundColor(0),
						label_value: this.useCaseGetPercentLabel(0),
						class_value: this.useCaseGetPercentBackground(0),
					},
					matrixBenefits: useCase.getMatrixBenefit(),
				};
			}

			const matrixReadinessValues: number[] = [];
			const categoriesQuestion = await this.solutionService.getCategoriesQuestion();

			for (const category of categoriesQuestion) {
				const formData = useCase.formData[category.id as string] ?? this._newUseCaseValue();
				const questions = await this.solutionService.getQuestions({
					categoryId: category.id as string,
				});

				let current_value = 0;
				let max_value = 0;

				const valueMap = {
					'1': 1,
					'2': 5,
					'3': 10,
				};

				for (const q of questions) {
					let answer = formData.answers[q.id as any];

					if (!answer) {
						answer = this._newUseCaseAnswer();
					}

					if (answer) {
						if (answer.value !== '0') {
							current_value = current_value + (valueMap[answer.value as '1' | '2' | '3'] || 1);
							max_value = max_value + 10;
						}
					}
				}

				matrixReadinessValues.push(this.getValueCalc(current_value, max_value));
			}

			const pMatrixReadinessValues = matrixReadinessValues.filter((v) => !!v);
			let matrixReadiness = pMatrixReadinessValues.reduce((a, b) => a + b, 0) / pMatrixReadinessValues.length;

			if (isNaN(matrixReadiness)) {
				matrixReadiness = 0;
			}

			useCase.calculatedData.matrixReadiness = {
				value: matrixReadiness,
				color: this.useCaseGetPercentBackgroundColor(matrixReadiness),
				label_value: this.useCaseGetPercentLabel(matrixReadiness),
				class_value: this.useCaseGetPercentBackground(matrixReadiness),
			};

			useCase.calculatedData.topBenefits = useCase.getCategoriesBenefits().map((key) => {
				const dataL = useCase.getBenefits(key);
				if (dataL.length) {
					if (key === 'cost_savings') {
						const data = dataL.find((d) => d.label === 'Cost Reduction');
						if (data) {
							return useCase.getBenefitItem(data, key);
						} else {
							return {
								label: useCase.getCategoryNameBenefit(key),
								label_value: '---',
								class_value: 'background-none',
							};
						}
					}
					if (key === 'working_capital') {
						const workingCapitalDifference = useCase.calculateWorkingCapitalDifference(
							useCase.benefitsValues.working_capital,
							useCase.benefitsValues.revenue_growth,
						);
						return {
							label: 'Working Capital',
							label_value: '$' + useCase.formatWithSuffix(workingCapitalDifference),
							class_value: workingCapitalDifference < 0 ? 'background-low' : 'background-high',
						};
					}
					if (key === 'employee_productivity') {
						const employeeProductivityDifference = useCase.calculateEmployeeCapacity(
							dataL,
							useCase.benefitsValues.cost_savings,
						);
						return {
							label: 'Employee Capacity',
							label_value: useCase.formatWithSuffix(employeeProductivityDifference.total) + ' hrs',
							class_value:
								employeeProductivityDifference.total < 0 ? 'background-low' : 'background-high',
						};
					}
					if (key === 'customer_experience') {
						const customerExperienceDifference = useCase.calculateCustomerDifference(dataL);
						return {
							label: 'Customer Experience',
							label_value: useCase.formatWithSuffix(customerExperienceDifference) + '%',
							class_value: customerExperienceDifference < 0 ? 'background-low' : 'background-high',
						};
					}

					return useCase.getBenefitItem(dataL[0], key);
				} else {
					return {
						label: useCase.getCategoryNameBenefit(key),
						label_value: '---',
						class_value: 'background-none',
					};
				}
			});

			if (!useCase.nexus?.status) {
				useCase.nexus.status = {
					functional_requirements: '0',
					non_functional_req: '0',
					inputs_and_data_sources: '0',
					output_and_reports: '0',
					designDiagram: '0',
					genAICheckList: '0',
				};
			} else {
				if (!useCase.nexus.status.functional_requirements) {
					useCase.nexus.status.functional_requirements = '0';
				}
				if (!useCase.nexus.status.non_functional_req) {
					useCase.nexus.status.non_functional_req = '0';
				}
				if (!useCase.nexus.status.inputs_and_data_sources) {
					useCase.nexus.status.inputs_and_data_sources = '0';
				}
				if (!useCase.nexus.status.output_and_reports) {
					useCase.nexus.status.output_and_reports = '0';
				}
				if (!useCase.nexus.status.designDiagram) {
					useCase.nexus.status.designDiagram = '0';
				}
				if (!useCase.nexus.status.genAICheckList) {
					useCase.nexus.status.genAICheckList = '0';
				}
			}
		}
		if (fromParams) {
			if (fromParams.solutionId) {
				useCase.solutionId = fromParams.solutionId;
			} else if (fromParams.level2Id) {
				const level2 = await this.e2ETaxonomyService.getLevel2(fromParams.level2Id);
				if (level2) {
					useCase.levels2Ids = [fromParams.level2Id];
					useCase.levels2 = [level2];
					const diagram = await this.e2ETaxonomyService.getDiagramTemplate({
						level2Id: fromParams.level2Id,
					});
					if (diagram) {
						useCase.diagram = this._newDiagram(useCase, diagram.xmlData, diagram.xmlImage, diagram.data);
					}
				}
			}
		}
		if (useCase.solutionId) {
			const solution = await this.solutionService.getSolution(useCase.solutionId);
			if (solution) {
				useCase.solution = solution;
				useCase.solutionId = solution.id || '';

				const diagram = await this.dbService.data_diagram.get({ useCaseId: useCase.id });
				if (diagram) {
					useCase.diagram = diagram;
				} else {
					const flow = await this.solutionService.getFlow({
						solutionId: useCase.solutionId,
					});
					if (flow) {
						useCase.diagram = this._newDiagram(useCase, flow.xmlData, flow.xmlImage, flow.data);
					}
				}

				useCase.technologiesIds = solution.getTechnologiesIds();
				useCase.levels2Ids = solution.levels2Ids;
			}
		}

		if (useCase.organizationId) {
			const organization = await this.authService.getOrganization(useCase.organizationId);
			if (organization) {
				useCase.organization = organization;
			}
		} else {
			const organization = await this.authService.getCurrentOrganization();
			if (organization) {
				useCase.organization = organization;
				useCase.organizationId = useCase.organization.id || '';
			}
		}

		if (useCase && useCase.id && !useCase.diagram && !fromParams) {
			try {
				const diagram = await this.dbService.data_diagram.get({ useCaseId: useCase.id });
				if (diagram) {
					useCase.diagram = diagram;
				} else {
					useCase.diagram = this._newDiagram(useCase, this.emptyDiagram);
				}
			} catch (e) {
				useCase.diagram = this._newDiagram(useCase, this.emptyDiagram);
			}
		}

		if (useCase.diagram) {
			useCase.diagram.organizationId = useCase.organizationId;
			useCase.diagram.organization = useCase.organization;
		}

		if (useCase.getTechnologiesIds() && useCase.getTechnologiesIds().length) {
			useCase.technologies = await this.e2ETaxonomyService.getTechnologies({ ids: useCase.technologiesIds });
		} else {
			useCase.technologiesIds = [];
			useCase.technologies = [];
		}

		if (!useCase.levels2 || !useCase.levels2.length) {
			if (useCase.levels2Ids && useCase.levels2Ids.length) {
				this.e2ETaxonomyService
					.getLevels2({
						ids: useCase.levels2Ids,
						recursiveRelation: true,
					})
					.then((levels2) => {
						useCase.levels2 = levels2;
					});
			} else {
				useCase.levels2Ids = [];
				useCase.levels2 = [];
			}
		}
	}

	currentDiagram: Diagram | undefined;

	/**
	 * Sets the current use case.
	 *
	 * @param {string} [id] - The ID of the use case to set as current. If provided, it will fetch the use case from the server.
	 * @param {object} [fromParams] - Optional parameters for fetching the use case from the server.
	 * @param {string} [fromParams.solutionId] - The ID of the solution to which the use case belongs.
	 * @param {string} [fromParams.level2Id] - The ID of the level 2 object to which the use case belongs.
	 *
	 * @return {Promise<void>} - A Promise that resolves once the current use case has been set.
	 */
	async setCurrentUseCase(
		id?: string,
		fromParams?: {
			solutionId?: string;
			level2Id?: string;
		},
	): Promise<void> {
		if (id) {
			const useCase = await this.getUseCase(id);
			if (useCase) {
				this.currentUseCase = useCase;
			}
		} else {
			this.currentUseCase = this._newUseCase();
		}
		if (this.currentUseCase) {
			await this.getRelationUseCase(this.currentUseCase, fromParams);
			if (!fromParams) {
				this.currentDiagram = this.currentUseCase.diagram;
			}
		}
		this.currentOriginalUseCase = this.currentUseCase ? instanceToInstance(this.currentUseCase) : undefined;
	}

	/**
	 * Saves the current use case.
	 * @returns {Promise<string>} A Promise that resolves to a string indicating the result of the save operation.
	 */
	async saveCurrentUseCase(): Promise<string> {
		if (this.currentUseCase) {
			if (!this.currentUseCase.name) {
				return 'no-name';
			}
			if (!this.currentUseCase.description) {
				return 'no-description';
			}
			/*if (!this.currentUseCase.levels2Ids.length) {
				return 'no-levels2';
			}*/
			const updated = !deepEqual(this.currentOriginalUseCase, this.currentUseCase);
			if (updated) {
				await this.saveUseCase(this.currentUseCase);
				this.currentOriginalUseCase = instanceToInstance(this.currentUseCase);
				return '';
			} else {
				return 'no-updated';
			}
		}
		return 'no-data';
	}

	/**
	 * Retrieves a use case from the database based on the given ID.
	 *
	 * @param {string} id - The ID of the use case to retrieve.
	 * @returns {Promise<UseCase | undefined>} A Promise that resolves to the retrieved use case if found, otherwise resolves to undefined.
	 */
	async getUseCase(id: string): Promise<UseCase | undefined> {
		return this.dbService.data_use_case.get(id);
	}

	/**
	 * Saves the provided use case to the database.
	 *
	 * @param {UseCase} useCase - The use case to be saved.
	 * @returns {Promise<UseCase>} - A promise that resolves with the saved use case.
	 */
	async saveUseCase(useCase: UseCase): Promise<UseCase> {
		const original_useCase = await this.dbService.data_use_case.get(useCase.id || '');
		const data = plainToInstance(UseCase, {
			...(original_useCase || {}),
			...useCase,
		});
		if (!data.id) {
			data.id = uuid();
		}
		await this.dbService.data_use_case.put(data);
		return data;
	}

	/**
	 * Generates a form group for the technologies question based on the provided form data and list of questions.
	 *
	 * @param {ICategoryFormData} formData - The form data containing the answers to the questions.
	 * @param {Question[]} questions - The list of questions to generate form controls for.
	 *
	 * @returns {Promise<FormGroup<{ [p: string]: FormControl<string | number | null> }>>} A promise that resolves to a form group containing the generated form controls.
	 */
	async getFormTechnologiesQuestion(
		formData: ICategoryFormData,
		questions: Question[],
	): Promise<
		FormGroup<{
			[p: string]: FormControl<string | number | null>;
		}>
	> {
		const form_controls: { [key: string]: FormControl<string | number | null> } = {};

		questions.forEach((question) => {
			const answer = formData.answers[question.id as any];
			let value = 0;
			if (answer) {
				value = parseInt(answer.value);
			}
			form_controls[(question.id || '') + '-value'] = new FormControl<number>(value, [Validators.required]);
			form_controls[(question.id || '') + '-note'] = new FormControl<string>(answer?.note || '');
		});

		return new FormGroup(form_controls);
	}

	/**
	 * Calculates the percentage value, based on the current value and the max value.
	 *
	 * @param {number} current_value - The current value.
	 * @param {number} max_value - The maximum value.
	 * @return {number} The calculated percentage value.
	 */
	getValueCalc(current_value: number, max_value: number): number {
		return current_value && max_value ? parseFloat(((current_value / max_value) * 100).toFixed(0)) : 0;
	}

	/**
	 * Calculate the value, current value, and max value for a given category question.
	 *
	 * @param {FormGroup} form - The form group containing the question values.
	 * @param {string} categoryId - The ID of the category.
	 * @param {ICategoryFormData} formData - The form data for the category.
	 * @param {Question[]} questions - The list of questions for the category.
	 * @return {Promise<{ value: number; current_value: number; max_value: number }>} The calculated values for the category question.
	 */
	async calcValueCategoryQuestion(
		form: FormGroup,
		categoryId: string,
		formData: ICategoryFormData,
		questions: Question[],
	): Promise<{ value: number; current_value: number; max_value: number }> {
		let current_value = 0;
		let max_value = 0;

		const valueMap = {
			'1': 1,
			'2': 5,
			'3': 10,
		};

		for (const q of questions) {
			let answer = formData.answers[q.id as any];

			if (!answer) {
				answer = this._newUseCaseAnswer();
			}

			if (answer) {
				answer.value = form.value[(q.id || '') + '-value'].toString();
				if (answer.value !== '0') {
					current_value = current_value + (valueMap[answer.value as '1' | '2' | '3'] || 1);
					max_value = max_value + 10;
				}

				formData.answers[q.id as any] = answer;
			}
		}

		const value = this.getValueCalc(current_value, max_value);

		if (parseFloat(formData.value) !== parseFloat(value.toString())) {
			formData.value = value.toString();

			if (this.currentUseCase) {
				this.currentUseCase.formData[categoryId] = formData;
			}
		}

		return { value, current_value, max_value };
	}

	/**
	 * Saves an answer for a specific question in the form.
	 *
	 * @param {FormGroup} form - The form group containing the question and answer fields.
	 * @param {string} categoryId - The ID of the category the question belongs to.
	 * @param {ICategoryFormData} formData - The form data object for the category.
	 * @param {string} questionId - The ID of the question being answered.
	 * @param {IAnswerFormData} answer - The answer data for the question.
	 * @param {Question[]} questions - The list of questions.
	 * @returns {Promise<void>} - A promise that resolves once the answer is saved.
	 */
	async saveAnswerQuestion(
		form: FormGroup,
		categoryId: string,
		formData: ICategoryFormData,
		questionId: string,
		answer: IAnswerFormData,
		questions: Question[],
	): Promise<void> {
		questions.forEach((q) => {
			const note = form.value[(q.id || '') + '-note'].toString();
			if (questionId === q.id) {
				answer.note = note;
			}
		});

		if (!answer.value) {
			answer.value = '0';
		}

		if (this.currentUseCase) {
			formData.answers[categoryId] = answer;
			this.currentUseCase.formData[categoryId] = formData;
		}
	}

	/**
	 * Updates the 'valid' status of a Use Case.
	 *
	 * @param {string} id - The ID of the Use Case to be updated.
	 * @param {boolean} isValid - The new value for the 'valid' status.
	 * @return {Promise<void>} - A Promise that resolves when the Use Case has been successfully updated.
	 */
	async updateUseCaseValidStatus(id: string, isValid: boolean): Promise<void> {
		const useCase = await this.getUseCase(id);
		if (useCase) {
			useCase.hubble = isValid;
			await this.saveUseCase(useCase);
		}
	}

	/**
	 * Updates the 'benefits' status of a Use Case.
	 *
	 * @param {string} id - The ID of the Use Case to be updated.
	 * @param {boolean} benefits - The new value for the 'benefits' status.
	 * @return {Promise<void>} - A Promise that resolves when the Use Case has been successfully updated.
	 */
	async updateUseCaseBenefitsStatus(id: string, benefits: boolean): Promise<void> {
		const useCase = await this.getUseCase(id);
		if (useCase) {
			useCase.benefits = benefits;
			await this.saveUseCase(useCase);
		}
	}

	/**
	 * Deletes a Use Case from the database
	 * @param {string} id - The ID of the Use Case to be deleted
	 * @return {Promise<void>} - A Promise that resolves when the Use Case has been successfully deleted
	 */
	async deleteUseCase(id: string): Promise<void> {
		await this.dbService.data_use_case.delete(id);
	}

	/**
	 * Clears the query for the use case.
	 * Navigates to the `/use-cases` route.
	 *
	 * @returns {Promise<void>} A promise that resolves with no value.
	 */
	async clearQueryUseCase(): Promise<void> {
		await this.router.navigateByUrl(`/use-cases`);
	}

	/**
	 * Navigates to a specific use case page with optional query parameters.
	 *
	 * @param {string} id - The ID of the use case to navigate to.
	 * @param {boolean} [assessments] - Optional. If true, includes the assessments query parameter in the navigation URL.
	 * @param {boolean} [goBack] - Optional. If true, includes the back query parameter in the navigation URL.
	 *
	 * @returns {Promise<void>} - A Promise that resolves when the navigation is completed.
	 *
	 * @example
	 *  await goToUseCase('123');
	 *  await goToUseCase('456', true);
	 *  await goToUseCase('789', false, true);
	 */
	async goToUseCase(id: string, assessments?: boolean, goBack?: boolean): Promise<void> {
		let query = `id=${id}`;
		if (assessments) {
			query = `${query}&assessments=1`;
		}
		if (goBack) {
			query = `${query}&back=1`;
		}
		await this.router.navigateByUrl(`/use-cases?${query}`);
	}

	/**
	 * Navigate to the "My Use Cases" page.
	 *
	 * @returns A promise that resolves to void once navigation is complete.
	 */
	async goToUseCases(): Promise<void> {
		await this.router.navigateByUrl(`/use-cases`);
	}

	async goToSimulationHome() {
		await this.router.navigate(['/'], { queryParams: { NavTab: 2 } });
	}

	async getDiagram(query: string | { level2Id: string }): Promise<Diagram | undefined> {
		return this.dbService.data_diagram.get(query as any);
	}

	async saveDiagram(diagram: DiagramBase): Promise<DiagramBase> {
		const original_diagram = await this.getDiagram(diagram.id || '');
		const data = plainToInstance(Diagram, {
			...(original_diagram || {}),
			...diagram,
		});
		if (!data.id) {
			data.id = uuid();
		}
		await this.dbService.data_diagram.put(data);
		return data;
	}

	async saveFilesDiagram(diagram: Diagram | undefined, xml?: string, svg?: string): Promise<string> {
		if (diagram) {
			const headers = new HttpHeaders({
				enctype: 'multipart/form-data',
			});
			const formData = new FormData();
			if (xml) {
				formData.append('xmlData', new File([xml], 'diagram.xml', { type: 'text/xml' }));
			}
			if (svg) {
				formData.append('xmlImage', new File([svg], 'diagram.svg', { type: 'image/svg+xml;charset=utf-8' }));
			}
			formData.append('data', JSON.stringify(diagram.data));
			if (!diagram.xmlData) {
				const obj = await firstValueFrom(
					this.http.get('@api/' + this.dbService.get_urls_hook('diagram') + diagram.id, {
						headers: headers,
					}),
				).catch(() => undefined);
				if (!(obj as any)?.id) {
					await firstValueFrom(
						this.http.post('@api/' + this.dbService.get_urls_hook('diagram'), diagram, {
							headers: headers,
						}),
					);
				}
			}
			const request = this.http.patch(
				'@api/' + this.dbService.get_urls_hook('diagram') + diagram.id + '/',
				formData,
				{
					headers: headers,
				},
			);
			return await firstValueFrom(request)
				.then((response) => {
					if (diagram) {
						if ((response as IDiagramBase).xmlData) {
							diagram.xmlData = (response as IDiagramBase).xmlData;
						}
						if ((response as IDiagramBase).xmlImage) {
							diagram.xmlImage = (response as IDiagramBase).xmlImage;
						}
						return '';
					} else {
						return 'error';
					}
				})
				.catch((err) => {
					return 'error';
				});
		}
		return 'no-data';
	}
}
