import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AttachmentModalComponent } from '../attachment-modal/design-nexus-attachment-modal.component';
import { files } from 'src/dataTables/editor/core/api';

@Component({
	selector: 'app-design-nexus-requirements',
	templateUrl: './design-nexus-requirements.component.html',
	styleUrls: ['./design-nexus-requirements.component.scss'],
	providers: [
		MessageService,
	],
})
export class DesignNexusRequirementsComponent {
	@Input() form!: FormGroup;
	canEditTitle = false;
	required = true;

	@Output() removeSection = new EventEmitter<void>();

	constructor(private formBuilder: FormBuilder,
		private dialogService: DialogService
	) {
	}

	private dialogRef!: DynamicDialogRef;

	openAttachmentModal() {
        this.dialogRef = this.dialogService.open(AttachmentModalComponent, {
            header: 'Add Attachment',
			data: {
				showOnlyFileInput: true,
				files: this.form.get('attachments')?.value || [],	
			},
            width: '50%',
        },);
		this.dialogRef.onClose.subscribe(attachmentData => {
            if (attachmentData) {
				this.form.patchValue({
					attachments: attachmentData,
				})
            }
        });
    }

	handleRemoveSection() {
		this.removeSection.emit();
	}

	get title() {
		return this.form.get('title')?.value || '-';
	}

	get items(): FormArray {
		return this.form.get('items') as FormArray;
	}

	toggleEditTitle() {
		this.canEditTitle = !this.canEditTitle;
	}

	private createItem(): FormGroup {
		return this.formBuilder.group({
			title: '',
			required: false,
			text: ['', Validators.required],
			disabled: [false],
		});
	}

	addItem() {
		this.items.push(this.createItem());
	}

	removeItem(itemIndex: number) {
		this.items.removeAt(itemIndex);
	}
}
