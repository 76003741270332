import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UseCase } from '../../../../api/use-case.api';
import { MessageService } from 'primeng/api';
import { Options as OptionsSlider } from 'ngx-slider-v2/options';
import { combineLatest, firstValueFrom, of, startWith } from 'rxjs';
import { PocInput, AIOpportunity } from '../../types';
import { DesignNexusService } from '../../design-nexus.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from '../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { pdfBased64 } from '../../utils';
import { SimulationService } from '../../../simulation/simulation.service';

@Component({
	selector: 'app-design-nexus-modal',
	templateUrl: './design-nexus-modal.component.html',
	styleUrls: ['./design-nexus-modal.component.scss'],
	providers: [MessageService],
})
export class DesignNexusModalComponent implements OnInit, AfterViewInit {
	@ViewChild('tableInput') tableInput: ElementRef<HTMLTableElement>;

	pdfBased64: string = pdfBased64;

	constructor(
		public config: DynamicDialogConfig<{ pocInput: PocInput; opportunity: AIOpportunity }>,
		public ref: DynamicDialogRef,
		private formBuilder: FormBuilder,
		private messageService: MessageService,
		private simulationService: SimulationService,
		public service: DesignNexusService,
		private http: HttpClient,
	) {}

	pocInput: PocInput;
	opportunity: AIOpportunity;
	@Input() resetStepperEvent!: EventEmitter<void>;
	@Input() current_use_case: UseCase | undefined;
	@Input() can_edit: boolean = false;
	@Output() closeModal = new EventEmitter<void>();

	form: FormGroup = this.formBuilder.group({
		title: [''],
		description: [''],
		generalInformation: this.formBuilder.group({
			typeImpact: [''],
			complexity: [''],
			opportunitySource: [''],
			source: this.formBuilder.group({
				'ui-ux': [0],
				impact: [0],
				confidence: [0],
				ease: [0],
				model: [0],
				integrations: [0],
				dataSources: [0],
			}),
		}),
		functionalRequirements: this.formBuilder.array([]),
		noFunctionalRequirements: this.formBuilder.array([]),
		pocRequirements: this.formBuilder.array([]),
		integratedRequirements: this.formBuilder.array([]),
	});

	functionalities: { type: string; description: string }[] = [
		// { type: '', description: '' }
	];

	// functionalRequirementsSections: {
	// 	title: string;
	// 	required: boolean;
	// 	fields: { title: string; text: string; disabled: boolean }[];
	// 	isEditingTitle: boolean;
	// }[] = [
	// 	{ title: 'User Stories', required: true, fields: [
	// 			{ title: '', text: 'The solution will allow users to identify customer programs that do not adhere to customer profile assumptions in customer/site level WRAs based on actual transactional activity.', disabled: false },
	// 			{ title: '', text: 'The solution will provide transparency to percent level of adherence for 5 key profile metrics at each customer program at a specific site - inbound cases, inbound pallets, occupied pallet positions, turns and % case pick.', disabled: false },
	// 		], isEditingTitle: false },
	// 	{
	// 		title: 'UX/UI Requirements',
	// 		required: true,
	// 		fields: [
	// 			{ title: '', text: 'The solution will support three different static views of profile adherence with different levels of summary.', disabled: false },
	// 			{ title: '', text: 'Each view should include header information summarizing the customer program, including Customer name, program #, site, program start and end dates, key Americold and customer contact information, and number of months remaining in the program.', disabled: false },
	// 		],
	// 		isEditingTitle: false,
	// 	},
	// 	{ title: 'Business Rules', required: true, fields: [{ title: '', text: '', disabled: false }], isEditingTitle: false },
	// 	{
	// 		title: 'Outputs and Reports',
	// 		required: true,
	// 		fields: [{ title: '', text: '', disabled: false }],
	// 		isEditingTitle: false,
	// 	},
	// ];
	//
	// nonFunctionalRequirementsSections: {
	// 	title: string;
	// 	required: boolean;
	// 	fields: { title: string; text: string; disabled: boolean }[];
	// 	isEditingTitle: boolean;
	// }[] = [
	// 	{
	// 		title: 'Current Technology Stack',
	// 		required: true,
	// 		fields: [
	// 			{ title: 'Cloud Provider', text: 'AWS Cloud - Real-time Inventory Analytics with AWS Lambda and DynamoDB integration', disabled: false },
	// 			{ title: 'Current Tech Stack', text: 'Python, FastAPI, and PostgreSQL for backend processing, with a Vue.js frontend for real-time analytics dashboards', disabled: false },
	// 			{ title: 'Existing AI Platform', text: 'Custom AI models for anomaly detection, deployed using TensorFlow and monitored with MLflow, integrated within the inventory system for live analysis', disabled: false },
	// 		],
	// 		isEditingTitle: false,
	// 	},
	// 	{ title: 'Data Security', required: true, fields: [
	// 			{ title: '', text: 'AES-256 encryption for all data at rest and in transit, ensuring compliance with GDPR and CCPA.', disabled: false },
	// 			{ title: '', text: 'Multi-factor authentication with single sign-on (SSO) via Okta, enforced for all production planners.', disabled: false },
	// 		], isEditingTitle: false },
	// 	{
	// 		title: 'Network and Infrastructure',
	// 		required: true,
	// 		fields: [
	// 			{ title: '', text: 'Firewalled and isolated VPC (Virtual Private Cloud) with SSL encryption and restricted IP access.', disabled: false },
	// 		],
	// 		isEditingTitle: false,
	// 	},
	// 	{
	// 		title: 'Usage Requirements',
	// 		required: true,
	// 		fields: [{ title: '', text: 'System scales to handle up to 1 million transactions per day with minimal degradation in response times.', disabled: false }],
	// 		isEditingTitle: false,
	// 	},
	// ];
	//
	// pocRequirementsSections: {
	// 	title: string;
	// 	required: boolean;
	// 	fields: { title: string; text: string; disabled: boolean }[];
	// 	isEditingTitle: boolean;
	// }[] = [
	// 	{ title: 'POC Scope Of Work', required: true, fields: [{ title: '', text: 'The objective of the Proof of Concept (POC) is to develop an Order Completeness Validator agent that cross-checks order details for completeness and accuracy, ensuring no information is missing or incorrect. The scope of work includes gathering and documenting order detail requirements, creating a validation framework, implementing the validator agent with specified criteria, performing unit and integration testing, and establishing a mechanism for user feedback and iteration.', disabled: false }], isEditingTitle: false },
	// 	{
	// 		title: 'PoC Data Requirements',
	// 		required: true,
	// 		fields: [{ title: '', text: 'Assumes 80%+ of order data is structured. A small subset (100 orders) will be verified manually to ensure results can be validated against ground truth.', disabled: false }],
	// 		isEditingTitle: false,
	// 	},
	// 	{ title: 'Deliverables', required: true, fields: [{ title: '', text: 'The key deliverables will consist of a comprehensive requirements document outlining order detail specifications, a design document detailing the validation process, a functional Order Completeness Validator tool, documented test cases and results from testing phases, and a user feedback report that captures insights and suggestions from initial testing.', disabled: false }], isEditingTitle: false },
	// 	{
	// 		title: 'Acceptance Criteria',
	// 		required: true,
	// 		fields: [{ title: '', text: 'The PoC must deliver a working prototype with accurate validation checks, a comprehensive user guide, and a report addressing user feedback, ensuring 95% validation accuracy and incorporating key user-requested improvements.', disabled: false }],
	// 		isEditingTitle: false,
	// 	},
	// ];
	//
	// integratedRequirementsSections: {
	// 	title: string;
	// 	required: boolean;
	// 	fields: { title: string; text: string; disabled: boolean }[];
	// 	isEditingTitle: boolean;
	// }[] = [
	// 	{ title: 'Statement of Work', required: true, fields: [{ title: '', text: 'The PoC aims to develop an Order Completeness Validator agent to check order details for completeness and accuracy, ensuring no critical information is missing. This includes gathering order requirements, building a validation framework, performing unit and integration testing, and establishing a feedback loop for iterative improvements.', disabled: false }], isEditingTitle: false },
	// 	{ title: 'Deliverables', required: true, fields: [{ title: '', text: 'Key deliverables include a requirements document, a design blueprint, a functional Order Completeness Validator prototype, documented test cases with results, and a user feedback report summarizing initial insights and suggestions for improvement.', disabled: false }], isEditingTitle: false },
	// 	{
	// 		title: 'Acceptance Criteria',
	// 		required: true,
	// 		fields: [{ title: '', text: 'The PoC will be deemed complete with a working prototype, a user guide, a list of validation criteria, and a summary of user feedback along with proposed enhancements based on initial testing.', disabled: false }],
	// 		isEditingTitle: false,
	// 	},
	// ];
	toggleSectionFields(
		sections: {
			title: string;
			required: boolean;
			fields: { text: string; disabled: boolean }[];
			isEditingTitle: boolean;
		}[],
		sectionIndex: number,
	) {
		const section = sections[sectionIndex];
		section.fields.forEach((field) => {
			field.disabled = !section.required;
		});
	}

	editSectionTitle(
		sections: {
			title: string;
			required: boolean;
			fields: { text: string; disabled: boolean }[];
			isEditingTitle: boolean;
		}[],
		index: number,
	) {
		sections[index].isEditingTitle = true;
	}

	addFunctionality() {
		this.functionalities.push({ type: '', description: '' });
	}

	removeFunctionality(index: number) {
		if (this.functionalities.length > 0) {
			this.functionalities.splice(index, 1);
		}
	}

	iceScoreChartData: any;
	iceScoreChartOptions: any;

	activeTabIndex: number = 0;
	tabHeaders: string[] = [
		'General Information',
		'Functional Requirements',
		'Non-Functional Requirements',
		'Inputs and Data Sources',
		'PoC Requirements',
		'Integrated and Scaled Requirements',
		'Deployment Handshake',
		'Effort & Cost Estimation',
	];

	activeStep: number = 0;
	loadingDesignNexusContent: boolean = false;

	getActiveClass(i: number) {
		let cls = this.activeTabIndex === i ? 'active' : '';
		if (this.loadingDesignNexusContent) {
			cls = [cls, 'cursor-wait'].join(' ');
		} else {
			cls = [cls, 'hoverable'].join(' ');
		}
		return cls;
	}

	completedTabs: boolean[] = Array(this.tabHeaders.length).fill(false);

	toggleCompleted(tabIndex: number, event: any): void {
		this.completedTabs[tabIndex] = event.checked;
	}

	async changeActiveStep(i: number) {
		if (!this.loadingDesignNexusContent) {
			if (i < 0) {
				i = 0;
			}
			if (i > this.tabHeaders.length - 1) {
				i = this.tabHeaders.length - 1;
			}
			if (this.activeStep !== i) {
				this.activeStep = i;
				this.activeTabIndex = i;
			}
		}
	}

	impactOptions = [
		{ label: 'Revenue Growth Analysis', value: 'Revenue Growth Analysis' },
		{ label: 'Customer Experience', value: 'Customer Experience' },
		{ label: 'Process Productivity', value: 'Process Productivity' },
		{ label: 'Employee Productivity', value: 'Employee Productivity' },
		{ label: 'Cost Savings', value: 'Cost Savings' },
	];

	complexityOptions = [
		{ label: 'Low', value: 'Low' },
		{ label: 'Medium', value: 'Medium' },
		{ label: 'High', value: 'High' },
	];

	sourceOptions = [
		{ label: 'ZBrain', value: 'ZBrain' },
		{ label: 'Custom', value: 'Custom' },
		{ label: 'Embedded', value: 'Embedded' },
	];

	vendorOptions = [
		{ label: 'Microsoft Power BI', value: 'Microsoft Power BI' },
		{ label: 'Tableau', value: 'Tableau' },
		{ label: 'Google Data Studio', value: 'Google Data Studio' },
		{ label: 'QlikView', value: 'QlikView' },
		{ label: 'SAP Analytics Cloud', value: 'SAP Analytics Cloud' },
	];

	selectedSource: string = '';

	getFormSliderOptions(): OptionsSlider {
		return {
			floor: 0,
			ceil: 10,
			step: 1,
			showTicks: true,
			showTicksValues: true,
			getPointerColor: (value: number): string => {
				if (value === 0) {
					return '#CC3333';
				} else if (value === 1) {
					return '#D1493D';
				} else if (value === 2) {
					return '#D86047';
				} else if (value === 3) {
					return '#DE7751';
				} else if (value === 4) {
					return '#E58D5B';
				} else if (value === 5) {
					return '#EBA366';
				} else if (value === 6) {
					return '#F2B971';
				} else if (value === 7) {
					return '#F8CF7B';
				} else if (value === 8) {
					return '#D4D88D';
				} else if (value === 9) {
					return '#A6C867';
				} else if (value === 10) {
					return '#6B9E00';
				} else {
					return '#C1C6C8';
				}
			},
			translate: (value: number): string => {
				return '';
			},
			getLegend: (value: number): string => {
				return `<span class="text-class">${value}</span>`;
			},
		};
	}

	resetStepper(): void {
		this.activeTabIndex = 0;
		this.activeStep = 0;
	}

	ngOnInit(): void {
		if (this.resetStepperEvent) {
			this.resetStepperEvent.subscribe(() => {
				this.resetStepper();
			});
		}
		this.setComponentData();
		this.setComponentEvents();
		this.initializeIceScoreChart();
		this.initializeForm();
	}

	displaySlider: boolean = true;

	ngAfterViewInit() {
		this.displaySlider = false;
		setTimeout(() => {
			this.displaySlider = true;
		}, 200);
	}

	previousTab(): void {
		if (this.activeTabIndex > 0) {
			this.activeTabIndex--;
		}
		this.savePoc();
	}

	nextTab(): void {
		if (this.activeTabIndex < this.tabHeaders.length - 1) {
			this.activeTabIndex++;
		}
		this.savePoc();
	}

	selectTab(index: number): void {
		this.activeTabIndex = index;
		this.activeStep = index;
	}

	saveUseCase(): void {
		this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Use Case saved successfully!' });
	}

	private calculatePercentage(...values: number[]): number {
		const sum = values.reduce((acc, value) => acc + value, 0);
		const maxSum = values.length * 10;
		return Math.round((sum / maxSum) * 100);
	}

	private updateIceChart(...values: number[]) {
		const totalICEScorePercentage = this.calculatePercentage(...values);

		this.iceScoreChartData = {
			labels: ['', ''],
			datasets: [
				{
					label: '',
					data: [totalICEScorePercentage, 100 - totalICEScorePercentage],
					backgroundColor: ['#7FAA3A', '#C1C6C8'],
					borderColor: ['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],
					borderWidth: 5,
				},
			],
		};
	}

	async savePoc() {
		const formValue = this.form.value;
		const generalInformationFormValue = formValue.generalInformation;
		const sourceFormValue = generalInformationFormValue.source;
		const pocInput: PocInput = {
			...this.pocInput,
			...formValue,
			generalInformation: {
				...generalInformationFormValue,
				source: {
					...sourceFormValue,
					'ice-score': this.calculatePercentage(
						sourceFormValue.impact,
						sourceFormValue.confidence,
						sourceFormValue.ease,
					),
				},
			},
		} as any;
		await this.service.savePoc(pocInput);
	}

	private setComponentData() {
		this.pocInput = this.config?.data?.pocInput as PocInput;
		this.opportunity = this.config?.data?.opportunity as AIOpportunity;
		if (!Array.isArray(this.pocInput.inputsDataSource)) {
			this.pocInput.inputsDataSource = [];
		}

		console.log('DesignNexusModalComponent.setComponentData', this.pocInput);
	}

	private setComponentEvents() {
		if (this.pocInput) {
			this.ref.onClose.pipe().subscribe(() => this.savePoc());
		}
	}

	private initializeIceScoreChart() {
		this.iceScoreChartData = {
			labels: ['', ''],
			datasets: [
				{
					label: '',
					data: [0, 100],
					backgroundColor: ['#7FAA3A', '#C1C6C8'],
					borderColor: ['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],
					borderWidth: 5,
				},
			],
		};

		this.iceScoreChartOptions = {
			cutout: '60%',
			rotation: 270,
			circumference: 180,
			responsive: true,
			maintainAspectRatio: true,
			aspectRatio: 2,
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
				},
				datalabels: {
					display: false,
				},
			},
		};
	}

	private getControlValueChanges(controlName: string, parentControl?: AbstractControl<any, any> | null) {
		if (!parentControl) {
			parentControl = this.form;
		}

		const control = parentControl.get(controlName);
		if (!control) {
			console.warn(`Control ${controlName} is not defined in the form.`);
			return of(null);
		}
		return control.valueChanges.pipe(startWith(control.value));
	}

	private initializeForm(): void {
		const { description, title } = this.pocInput;
		const { typeImpact, complexity, opportunitySource } = this.pocInput.generalInformation;
		const {
			'ui-ux': uxUI,
			impact,
			confidence,
			ease,
			model,
			integrations,
			dataSources,
		} = this.pocInput.generalInformation.source;

		if (!this.pocInput?.functionalRequirements?.length) {
			this.pocInput.functionalRequirements = [
				{
					title: 'User Stories',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: 'The solution will allow users to identify customer programs that do not adhere to customer profile assumptions in customer/site level WRAs based on actual transactional activity.',
							disabled: false,
						},
						{
							title: '',
							text: 'The solution will provide transparency to percent level of adherence for 5 key profile metrics at each customer program at a specific site - inbound cases, inbound pallets, occupied pallet positions, turns and % case pick.',
							disabled: false,
						},
					],
				},
				{
					title: 'UX/UI Requirements',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: 'The solution will support three different static views of profile adherence with different levels of summary.',
							disabled: false,
						},
						{
							title: '',
							text: 'Each view should include header information summarizing the customer program, including Customer name, program #, site, program start and end dates, key Americold and customer contact information, and number of months remaining in the program.',
							disabled: false,
						},
					],
				},
				{
					title: 'Business Rules',
					required: true,
					editable: false,
					items: [{ title: '', text: '', disabled: false }],
				},
				{
					title: 'Outputs and Reports',
					required: true,
					editable: false,
					items: [{ title: '', text: '', disabled: false }],
				},
			];
		}

		if (!this.pocInput?.noFunctionalRequirements?.length) {
			this.pocInput.noFunctionalRequirements = [
				{
					title: 'Current Technology Stack',
					required: true,
					editable: false,
					items: [
						{
							title: 'Cloud Provider',
							text: 'AWS Cloud - Real-time Inventory Analytics with AWS Lambda and DynamoDB integration',
							disabled: false,
						},
						{
							title: 'Current Tech Stack',
							text: 'Python, FastAPI, and PostgreSQL for backend processing, with a Vue.js frontend for real-time analytics dashboards',
							disabled: false,
						},
						{
							title: 'Existing AI Platform',
							text: 'Custom AI models for anomaly detection, deployed using TensorFlow and monitored with MLflow, integrated within the inventory system for live analysis',
							disabled: false,
						},
					],
				},
				{
					title: 'Data Security',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: 'AES-256 encryption for all data at rest and in transit, ensuring compliance with GDPR and CCPA.',
							disabled: false,
						},
						{
							title: '',
							text: 'Multi-factor authentication with single sign-on (SSO) via Okta, enforced for all production planners.',
							disabled: false,
						},
					],
				},
				{
					title: 'Network and Infrastructure',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: 'Firewalled and isolated VPC (Virtual Private Cloud) with SSL encryption and restricted IP access.',
							disabled: false,
						},
					],
				},
				{
					title: 'Usage Requirements',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: 'System scales to handle up to 1 million transactions per day with minimal degradation in response times.',
							disabled: false,
						},
					],
				},
			];
		}

		if (!this.pocInput?.pocRequirements?.length) {
			this.pocInput.pocRequirements = [
				{
					title: 'POC Scope Of Work',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: 'The objective of the Proof of Concept (POC) is to develop an Order Completeness Validator agent that cross-checks order details for completeness and accuracy, ensuring no information is missing or incorrect. The scope of work includes gathering and documenting order detail requirements, creating a validation framework, implementing the validator agent with specified criteria, performing unit and integration testing, and establishing a mechanism for user feedback and iteration.',
							disabled: false,
						},
					],
				},
				{
					title: 'PoC Data Requirements',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: 'Assumes 80%+ of order data is structured. A small subset (100 orders) will be verified manually to ensure results can be validated against ground truth.',
							disabled: false,
						},
					],
				},
				{
					title: 'Deliverables',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: 'The key deliverables will consist of a comprehensive requirements document outlining order detail specifications, a design document detailing the validation process, a functional Order Completeness Validator tool, documented test cases and results from testing phases, and a user feedback report that captures insights and suggestions from initial testing.',
							disabled: false,
						},
					],
				},
				{
					title: 'Acceptance Criteria',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: 'The PoC must deliver a working prototype with accurate validation checks, a comprehensive user guide, and a report addressing user feedback, ensuring 95% validation accuracy and incorporating key user-requested improvements.',
							disabled: false,
						},
					],
				},
			];
		}

		if (!this.pocInput?.integratedRequirements?.length) {
			this.pocInput.integratedRequirements = [
				{
					title: 'Statement of Work',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: 'The PoC aims to develop an Order Completeness Validator agent to check order details for completeness and accuracy, ensuring no critical information is missing. This includes gathering order requirements, building a validation framework, performing unit and integration testing, and establishing a feedback loop for iterative improvements.',
							disabled: false,
						},
					],
				},
				{
					title: 'Deliverables',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: 'Key deliverables include a requirements document, a design blueprint, a functional Order Completeness Validator prototype, documented test cases with results, and a user feedback report summarizing initial insights and suggestions for improvement.',
							disabled: false,
						},
					],
				},
				{
					title: 'Acceptance Criteria',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: 'The PoC will be deemed complete with a working prototype, a user guide, a list of validation criteria, and a summary of user feedback along with proposed enhancements based on initial testing.',
							disabled: false,
						},
					],
				},
			];
		}

		const functionalRequirementsArray = this.form.get('functionalRequirements') as FormArray;
		this.pocInput.functionalRequirements.forEach((item: any) => {
			functionalRequirementsArray.push(this.createSection(item.items));
		});

		const noFunctionalRequirementsArray = this.form.get('noFunctionalRequirements') as FormArray;
		this.pocInput.noFunctionalRequirements.forEach((item: any) => {
			noFunctionalRequirementsArray.push(this.createSection(item.items));
		});

		const pocRequirementsArray = this.form.get('pocRequirements') as FormArray;
		this.pocInput.pocRequirements.forEach((item: any) => {
			pocRequirementsArray.push(this.createSection(item.items));
		});

		const integratedRequirementsArray = this.form.get('integratedRequirements') as FormArray;
		this.pocInput.integratedRequirements.forEach((item: any) => {
			integratedRequirementsArray.push(this.createSection(item.items));
		});

		this.form.patchValue({
			...this.pocInput,
			title,
			description,
			generalInformation: {
				typeImpact,
				complexity,
				opportunitySource,
				source: {
					'ui-ux': uxUI,
					impact,
					confidence,
					ease,
					model,
					integrations,
					dataSources,
				},
			},
		});

		const impact$ = this.getControlValueChanges('impact', this.form.get('generalInformation')?.get('source'));
		const confidence$ = this.getControlValueChanges(
			'confidence',
			this.form.get('generalInformation')?.get('source'),
		);
		const ease$ = this.getControlValueChanges('ease', this.form.get('generalInformation')?.get('source'));
		const opportunitySource$ = this.getControlValueChanges(
			'opportunitySource',
			this.form.get('generalInformation'),
		);

		combineLatest([impact$, confidence$, ease$]).subscribe(([impactValue, confidenceValue, easeValue]) => {
			this.updateIceChart(impactValue, confidenceValue, easeValue);
		});

		opportunitySource$.subscribe((opportunitySource) => {
			console.log(opportunitySource);
			this.selectedSource = opportunitySource;
		});
	}

	private createSection(items: string[] = []): FormGroup {
		const itemsFormArray = this.formBuilder.array(
			items.length ? items.map(() => this.createItem()) : [this.createItem()],
		);

		return this.formBuilder.group({
			attachments: [],
			title: ['new title', Validators.required],
			required: [false],
			items: itemsFormArray,
		});
	}

	private createItem(): FormGroup {
		return this.formBuilder.group({
			title: '',
			text: ['', Validators.required],
			disabled: [false],
		});
	}

	getFormArray(arrayName: string): FormArray<FormGroup> {
		return this.form.get(arrayName) as FormArray<FormGroup>;
	}

	addSection(arrayName: string) {
		this.getFormArray(arrayName).push(this.createSection());
	}

	removeSection(arrayName: string, sectionIndex: number) {
		this.getFormArray(arrayName).removeAt(sectionIndex);
	}

	async generatePdf(): Promise<void> {
		try {
			const response = await firstValueFrom(
				this.http.get<{ pdf_base64: string }>(environment.url + '/api/hubble-design/pdf/', {}),
			);

			this.pdfBased64 = response!.pdf_base64;
		} catch (e) {
			console.log(e);
		}
	}

	contentEffortAndCostEstimation: any = undefined;
	loadingEffort: boolean = false;

	async sendPocData() {
		await this.simulationService.getEnterpriseContext();

		const data = {
			poc: this.pocInput,
			enterpriseContext: this.simulationService.enterpriseContext?.enterpriseContext,
		};

		this.loadingEffort = true;

		this.http
			.post<{ callback: any }>('https://app.zbrain.ai:8080/api/v1/webhooks/aJhsHz21rG5PgiZU296YH/sync', data, {
				headers: {
					Authorization:
						'Bearer za-U2FsdGVkX1+3BvfuEnJ9qqQxfacnsO7q7d8D/Wk+U8eik9+NY4JEIB6xCmh6EqGh+8oNYjdU+mC1JD/rov9E5GU1xFLDhtLzykHtwAEWBiVh5Cn7UKjph++Wlah0Sg93',
				},
			})
			.subscribe((res) => {
				this.contentEffortAndCostEstimation = res;
				this.loadingEffort = false;
			});
	}
}
