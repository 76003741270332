export default {
    "scalars": [
        4,
        5,
        8,
        9,
        10,
        14,
        15,
        17,
        19,
        21,
        36,
        41,
        86,
        89,
        92
    ],
    "types": {
        "Query": {
            "common": [
                1
            ],
            "e2eTaxonomy": [
                11
            ],
            "solution": [
                47
            ],
            "useCase": [
                68
            ],
            "maturity": [
                72
            ],
            "simulation": [
                81
            ],
            "__typename": [
                4
            ]
        },
        "CommonQuery": {
            "commonData": [
                2
            ],
            "organization": [
                6
            ],
            "__typename": [
                4
            ]
        },
        "CommonDataQuery": {
            "all": [
                3
            ],
            "__typename": [
                4
            ]
        },
        "CommonDataObjectType": {
            "key": [
                4
            ],
            "data": [
                5
            ],
            "__typename": [
                4
            ]
        },
        "String": {},
        "GenericScalar": {},
        "OrganizationModelQuery": {
            "fetch": [
                7,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "count": [
                10,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "get": [
                7,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "all": [
                7,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "OrganizationModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "summary": [
                4
            ],
            "taxonomy": [
                5
            ],
            "__typename": [
                4
            ]
        },
        "UUID": {},
        "BigInt": {},
        "Int": {},
        "E2ETaxonomyQuery": {
            "categoryFunction": [
                12
            ],
            "diagramE2e": [
                22
            ],
            "diagramProcess": [
                24
            ],
            "diagramTemplate": [
                26
            ],
            "function": [
                28
            ],
            "level1": [
                30
            ],
            "level2": [
                32
            ],
            "technology": [
                34
            ],
            "digitalWorker": [
                37
            ],
            "technologyByLevel2": [
                39
            ],
            "aiOpportunity": [
                42
            ],
            "__typename": [
                4
            ]
        },
        "CategoryFunctionModelQuery": {
            "fetch": [
                13,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "count": [
                10,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "get": [
                13,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "all": [
                13,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "CategoryFunctionModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "short": [
                4
            ],
            "sort": [
                10
            ],
            "type": [
                14
            ],
            "office": [
                15
            ],
            "functions": [
                16,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "functionsIds": [
                4,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "E2ETaxonomyCategoryFunctionTypeChoices": {},
        "E2ETaxonomyCategoryFunctionOfficeChoices": {},
        "FunctionModelChildObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "short": [
                4
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "sort": [
                10
            ],
            "type": [
                17
            ],
            "overall": [
                10
            ],
            "levels1": [
                18,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "levels1Ids": [
                4,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "E2ETaxonomyFunctionTypeChoices": {},
        "Level1ModelChildObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "spend": [
                10
            ],
            "sme": [
                10
            ],
            "overall": [
                10
            ],
            "type": [
                19
            ],
            "levels2": [
                20,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "levels2Ids": [
                4,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "E2ETaxonomyLevel1TypeChoices": {},
        "Level2ModelChildObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "values": [
                5
            ],
            "type": [
                21
            ],
            "__typename": [
                4
            ]
        },
        "E2ETaxonomyLevel2TypeChoices": {},
        "DiagramE2EModelQuery": {
            "all": [
                23,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "DiagramE2EModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "data": [
                5
            ],
            "xmlData": [
                4
            ],
            "xmlImage": [
                4
            ],
            "functionId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramProcessModelQuery": {
            "all": [
                25,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "DiagramProcessModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "data": [
                5
            ],
            "xmlData": [
                4
            ],
            "xmlImage": [
                4
            ],
            "level1Id": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramTemplateModelQuery": {
            "all": [
                27,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "DiagramTemplateModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "data": [
                5
            ],
            "xmlData": [
                4
            ],
            "xmlImage": [
                4
            ],
            "level2Id": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "FunctionModelQuery": {
            "fetch": [
                29,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "count": [
                10,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "get": [
                29,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "all": [
                29,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "FunctionModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "short": [
                4
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "sort": [
                10
            ],
            "type": [
                17
            ],
            "overall": [
                10
            ],
            "category": [
                13
            ],
            "categoryId": [
                4
            ],
            "organization": [
                7
            ],
            "organizationId": [
                4
            ],
            "levels1": [
                18,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "levels1Ids": [
                4,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "Level1ModelQuery": {
            "fetch": [
                31,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "count": [
                10,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "get": [
                31,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "all": [
                31,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "Level1ModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "spend": [
                10
            ],
            "sme": [
                10
            ],
            "overall": [
                10
            ],
            "type": [
                19
            ],
            "function": [
                29
            ],
            "functionId": [
                4
            ],
            "organization": [
                7
            ],
            "organizationId": [
                4
            ],
            "levels2": [
                20,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "levels2Ids": [
                4,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "Level2ModelQuery": {
            "fetch": [
                33,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "count": [
                10,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "get": [
                33,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "all": [
                33,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "Level2ModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "values": [
                5
            ],
            "type": [
                21
            ],
            "level1": [
                31
            ],
            "level1Id": [
                4
            ],
            "organization": [
                7
            ],
            "organizationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "TechnologyModelQuery": {
            "fetch": [
                35,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "count": [
                10,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "get": [
                35,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "all": [
                35,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "TechnologyModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "short": [
                4
            ],
            "neto": [
                36
            ],
            "svg": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "Boolean": {},
        "DigitalWorkerModelQuery": {
            "all": [
                38,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "DigitalWorkerModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "short": [
                4
            ],
            "description": [
                4
            ],
            "technologyId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "TechnologyByLevel2ModelQuery": {
            "all": [
                40,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "TechnologyByLevel2ModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "value": [
                41
            ],
            "technologyId": [
                4
            ],
            "level2Id": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "Decimal": {},
        "AIOpportunityModelQuery": {
            "fetch": [
                43,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "count": [
                10,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "get": [
                43,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "all": [
                43,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "AIOpportunityModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "shapeId": [
                4
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "checked": [
                36
            ],
            "valid": [
                36
            ],
            "data": [
                5
            ],
            "poc": [
                44
            ],
            "pocId": [
                4
            ],
            "technologies": [
                35,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "technologiesIds": [
                4,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "PocModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "title": [
                4
            ],
            "description": [
                4
            ],
            "generalInformation": [
                5
            ],
            "functionalRequirements": [
                5
            ],
            "noFunctionalRequirements": [
                5
            ],
            "inputsDataSource": [
                5
            ],
            "genAIChecklist": [
                5
            ],
            "deploymentHandshake": [
                5
            ],
            "organization": [
                7
            ],
            "organizationId": [
                4
            ],
            "useCase": [
                45
            ],
            "useCaseId": [
                4
            ],
            "aiEnablers": [
                35,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "aiEnablersIds": [
                4,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "generalData": [
                5
            ],
            "formData": [
                5
            ],
            "workPlanData": [
                5
            ],
            "aiValues": [
                5
            ],
            "hubble": [
                36
            ],
            "benefits": [
                36
            ],
            "benefitsValues": [
                5
            ],
            "benefitsOrder": [
                5
            ],
            "simulationData": [
                5
            ],
            "libraryData": [
                5
            ],
            "nexus": [
                5
            ],
            "organization": [
                7
            ],
            "organizationId": [
                4
            ],
            "diagram": [
                46
            ],
            "diagramId": [
                4
            ],
            "levels2": [
                33,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "levels2Ids": [
                4,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "opportunities": [
                43,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "opportunitiesIds": [
                4,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "technologies": [
                35,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "technologiesIds": [
                4,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "pocs": [
                44,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "pocsIds": [
                4,
                {
                    "query": [
                        5
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "DiagramModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "data": [
                5
            ],
            "xmlData": [
                4
            ],
            "xmlImage": [
                4
            ],
            "organization": [
                7
            ],
            "organizationId": [
                4
            ],
            "useCase": [
                45
            ],
            "useCaseId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SolutionQuery": {
            "categoryQuestion": [
                48
            ],
            "subCategoryQuestion": [
                50
            ],
            "flow": [
                52
            ],
            "functionFilter": [
                54
            ],
            "skill": [
                56
            ],
            "aiLever": [
                58
            ],
            "solution": [
                60
            ],
            "question": [
                63
            ],
            "tool": [
                65
            ],
            "icon": [
                67
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionModelQuery": {
            "all": [
                49,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "text": [
                4
            ],
            "sort": [
                10
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionModelQuery": {
            "all": [
                51,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "text": [
                4
            ],
            "sort": [
                10
            ],
            "categoryId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "FlowModelQuery": {
            "all": [
                53,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "FlowModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "data": [
                5
            ],
            "xmlData": [
                4
            ],
            "xmlImage": [
                4
            ],
            "solutionId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "FunctionFilterModelQuery": {
            "all": [
                55,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "FunctionFilterModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "short": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SkillModelQuery": {
            "all": [
                57,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SkillModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "short": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "AiLeverModelQuery": {
            "all": [
                59,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "AiLeverModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "short": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SolutionModelQuery": {
            "all": [
                61,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SolutionModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "summary": [
                4
            ],
            "keyFeatures": [
                5
            ],
            "data": [
                5
            ],
            "taxonomy": [
                5
            ],
            "certified": [
                36
            ],
            "industries": [
                5
            ],
            "aiDrivers": [
                5
            ],
            "icon": [
                4
            ],
            "iconDefault": [
                62
            ],
            "iconDefaultId": [
                4
            ],
            "levels2Ids": [
                4
            ],
            "functionsIds": [
                4
            ],
            "technologiesIds": [
                4
            ],
            "toolsIds": [
                4
            ],
            "skillsIds": [
                4
            ],
            "aiLeversIds": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "IconModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "file": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "QuestionModelQuery": {
            "all": [
                64,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "QuestionModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "text": [
                4
            ],
            "weight": [
                41
            ],
            "sort": [
                10
            ],
            "answers": [
                5
            ],
            "weightJson": [
                5
            ],
            "tag": [
                4
            ],
            "categoryId": [
                4
            ],
            "subcategoryId": [
                4
            ],
            "technologyId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "ToolModelQuery": {
            "all": [
                66,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "ToolModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "short": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "IconModelQuery": {
            "all": [
                62,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseQuery": {
            "diagram": [
                69
            ],
            "useCase": [
                70
            ],
            "poc": [
                71
            ],
            "__typename": [
                4
            ]
        },
        "DiagramModelQuery": {
            "fetch": [
                46,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "count": [
                10,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "get": [
                46,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "all": [
                46,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseModelQuery": {
            "fetch": [
                45,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "count": [
                10,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "get": [
                45,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "all": [
                45,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "PocModelQuery": {
            "fetch": [
                44,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "count": [
                10,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "get": [
                44,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        5
                    ]
                }
            ],
            "all": [
                44,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "MaturityQuery": {
            "assessment": [
                73
            ],
            "question": [
                75
            ],
            "subCategoryQuestion": [
                77
            ],
            "categoryQuestion": [
                79
            ],
            "__typename": [
                4
            ]
        },
        "AssessmentMaturityModelQuery": {
            "all": [
                74,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "AssessmentMaturityModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "content": [
                4
            ],
            "formData": [
                5
            ],
            "commentData": [
                5
            ],
            "recommendations": [
                5
            ],
            "organizationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "QuestionMaturityModelQuery": {
            "all": [
                76,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "QuestionMaturityModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "text": [
                4
            ],
            "sort": [
                10
            ],
            "categoryId": [
                4
            ],
            "subcategoryId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionMaturityModelQuery": {
            "all": [
                78,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionMaturityModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "sort": [
                10
            ],
            "categoryId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionMaturityModelQuery": {
            "all": [
                80,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionMaturityModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "sort": [
                10
            ],
            "__typename": [
                4
            ]
        },
        "SimulationQuery": {
            "context": [
                82
            ],
            "simulation": [
                84
            ],
            "useCaseSimulation": [
                87
            ],
            "useCaseLibrary": [
                90
            ],
            "m1Prep": [
                93
            ],
            "__typename": [
                4
            ]
        },
        "EnterpriseContextModelQuery": {
            "all": [
                83,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "EnterpriseContextModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "enterpriseContext": [
                5
            ],
            "organizationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SimulationModelQuery": {
            "all": [
                85,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SimulationModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "notes": [
                4
            ],
            "search": [
                36
            ],
            "generate": [
                36
            ],
            "taxonomyId": [
                8
            ],
            "taxonomyRel": [
                86
            ],
            "enterpriseContext": [
                5
            ],
            "simulationContext": [
                5
            ],
            "organizationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SimulationSimulationTaxonomyRelChoices": {},
        "UseCaseSimulationModelQuery": {
            "all": [
                88,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseSimulationModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "deployedId": [
                8
            ],
            "keyFeatures": [
                5
            ],
            "context": [
                5
            ],
            "data": [
                5
            ],
            "valid": [
                36
            ],
            "type": [
                89
            ],
            "simulationId": [
                4
            ],
            "organizationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SimulationUseCaseSimulationTypeChoices": {},
        "UseCaseLibraryModelQuery": {
            "all": [
                91,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseLibraryModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "data": [
                5
            ],
            "sourceId": [
                8
            ],
            "sourceRel": [
                92
            ],
            "__typename": [
                4
            ]
        },
        "SimulationUseCaseLibrarySourceRelChoices": {},
        "M1PrepModelQuery": {
            "all": [
                94,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "M1PrepModelObjectType": {
            "id": [
                8
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "context": [
                5
            ],
            "companyAnalysis": [
                5
            ],
            "products": [
                5
            ],
            "competitiveLandscape": [
                5
            ],
            "conversation": [
                5
            ],
            "salesforce": [
                5
            ],
            "organizationId": [
                4
            ],
            "__typename": [
                4
            ]
        }
    }
}