<!-- Tabs Menu -->
<div class="stepper">
	<ng-template ngFor let-phase let-i="index" [ngForOf]="tabHeaders">
		<div class="arrow" [ngClass]="getActiveClass(i)" (click)="changeActiveStep(i)">
			<!-- <i *ngIf="completedTabs[i]" class="pi pi-check-circle pr-2 text-green-400"></i> -->
			<i class="pi pi-check-circle pr-2 text-green-400"></i>
			<!-- <i *ngIf="!completedTabs[i]" class="pi pi-circle pr-2"></i> -->
			{{ phase }}
		</div>
	</ng-template>
</div>
<!-- Tabs Content -->
<div *ngIf="activeTabIndex === 0">
	<!-- General Information Tab -->
	<div class="flex justify-content-between align-items-center gap-3 my-2">
		<span class="m-0 font-bold text-lg">General Information</span>
		<div class="flex align-items-center gap-2"></div>
	</div>
	<!-- Grid layout with three equal columns -->
	<div class="flex align-items-start w-full" [formGroup]="form">
		<!-- First column with form content -->
		<div class="col-6 pl-0">
			AI Opportunity
			<!-- AI Opportunity Name field -->
			<div class="p-field pt-3">
				<label>
					<span>Name</span>
				</label>
				<div class="pt-2">
					<input
						id="aiOpportunityName"
						pInputText
						class="w-full"
						type="text"
						placeholder="Enter AI Opportunity Name"
						formControlName="title"
					/>
				</div>
			</div>
			<ng-container *ngIf="opportunity?.technologies && opportunity.technologies.length > 0">
				<div class="p-field pt-3">
					<p-chip *ngFor="let tech of opportunity.technologies" [label]="tech?.name ?? 'N/A'" class="m-1">
					</p-chip>
				</div>
			</ng-container>
			<!-- Description field -->
			<div class="p-field pt-3">
				<label>Description</label>
				<div class="pt-2">
					<textarea
						class="w-full"
						pInputTextarea
						rows="6"
						placeholder="Enter description"
						formControlName="description"
					></textarea>
				</div>
			</div>
			<div class="p-field pt-3">
				<div class="flex w-full" formGroupName="generalInformation">
					<!-- Type of Impact dropdown -->
					<div class="col-4 p-field pt-3 pl-0">
						<label>Type of Impact</label>
						<div class="pt-2">
							<input
								pInputText
								id="typeOfImpact"
								class="w-full"
								type="text"
								placeholder="Enter Type of Impact"
								formControlName="typeImpact"
							/>
						</div>
					</div>
					<!-- Complexity dropdown -->
					<div class="col-4 p-field pt-3 px-0">
						<label>Complexity</label>
						<div class="pt-2">
							<p-dropdown
								id="complexity"
								[options]="complexityOptions"
								styleClass="w-full"
								placeholder="Select Complexity"
								formControlName="complexity"
							></p-dropdown>
						</div>
					</div>
					<!-- AI Opportunity Source dropdown -->
					<div class="col-4 p-field pt-3 pr-0">
						<label for="aiOpportunitySource">AI Opportunity Source</label>
						<div class="pt-2">
							<p-dropdown
								id="aiOpportunitySource"
								styleClass="w-full"
								[options]="sourceOptions"
								placeholder="Select Source"
								formControlName="opportunitySource"
							></p-dropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-container formGroupName="generalInformation">
			<ng-container formGroupName="source">
				<!-- Second Column -->
				<div class="col-6">
					<div class="p-field flex">
						<!-- Column for ICE Score text -->
						<div class="col-6 pl-0">
							<label>
								<span>ICE Score</span>
							</label>
						</div>
						<!-- Column for the chart -->
						<div class="col-6">
							<div class="chart-container">
								<p-chart
									type="doughnut"
									[data]="iceScoreChartData"
									[options]="iceScoreChartOptions"
								></p-chart>
								<p class="percent">{{ iceScoreChartData.datasets[0].data[0] }}%</p>
							</div>
						</div>
					</div>
					<div class="p-field">
						<label>
							<span>Impact</span>
						</label>
						<div *ngIf="displaySlider">
							<ngx-slider formControlName="impact" [options]="getFormSliderOptions()"></ngx-slider>
						</div>
					</div>
					<p-divider />
					<div class="p-field">
						<label>
							<span>Confidence</span>
						</label>
						<div *ngIf="displaySlider">
							<ngx-slider formControlName="confidence" [options]="getFormSliderOptions()"></ngx-slider>
						</div>
					</div>
					<p-divider />
					<div class="p-field">
						<label>
							<span>Ease</span>
						</label>
						<div *ngIf="displaySlider">
							<ngx-slider formControlName="ease" [options]="getFormSliderOptions()"></ngx-slider>
						</div>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</div>
	<div *ngIf="selectedSource !== 'ZBrain' && selectedSource !== ''">
		<div class="mt-3">
			<span class="m-0 font-bold text-lg">Embedded Opportunity Details</span>
		</div>
		<!-- Type of Vendor dropdown -->
		<div class="p-field pt-3">
			<label>Vendor</label>
			<div class="pt-2">
				<p-dropdown id="typeOfVendor" [options]="impactOptions" styleClass="w-full" placeholder="Select Vendor">
				</p-dropdown>
			</div>
		</div>
		<!-- Functionalities -->
		<div class="p-field pt-3 pb-2">
			<label>Functionalities</label>
		</div>
		<div *ngFor="let functionality of functionalities; let i = index" class="p-field">
			<div class="data-container">
				<div class="pt-2">
					<label for="functionalityType">Functionality Type</label>
				</div>
				<div class="pt-2 flex items-center w-full">
					<input
						id="functionalityType"
						pInputText
						class="flex-grow w-full"
						placeholder="Enter Functionality Type"
					/>
					<button
						pButton
						icon="pi pi-trash"
						class="p-button p-button-outlined ml-2"
						(click)="removeFunctionality(i)"
					></button>
				</div>
				<div class="pt-2">
					<label for="description">Description</label>
				</div>
				<div class="pt-2">
					<textarea
						id="description"
						pInputTextarea
						class="w-full"
						placeholder="Enter Description"
						style="padding-top: 0.5rem"
					></textarea>
				</div>
			</div>
			<p-divider />
		</div>
		<div class="pt-2">
			<div class="add-data-container" style="flex-direction: column">
				<button
					pButton
					icon="pi pi-plus-circle"
					label="Add New Functionality"
					class="p-button-outlined"
					(click)="addFunctionality()"
				></button>
			</div>
		</div>
	</div>
</div>
<div *ngIf="activeTabIndex === 1">
	<!-- Functional Requirements Tab -->
	<div class="flex justify-content-between align-items-center gap-3 my-2">
		<span class="m-0 font-bold text-lg">Functional Requirements</span>
	</div>
	<ng-container [formGroup]="form">
		<ng-container formArrayName="functionalRequirements">
			<ng-container
				*ngFor="let section of getFormArray('functionalRequirements').controls; let i = index"
				[formGroupName]="i"
			>
				<app-design-nexus-requirements
					[form]="section"
					(removeSection)="removeSection('functionalRequirements', i)"
				></app-design-nexus-requirements>
			</ng-container>
		</ng-container>
	</ng-container>
	<div class="add-data-container">
		<button
			pButton
			icon="pi pi-plus-circle"
			label="Add New Section"
			class="p-button-outlined"
			(click)="addSection('functionalRequirements')"
		></button>
	</div>
</div>
<div *ngIf="activeTabIndex === 2">
	<!-- Non-Functional Requirements Tab -->
	<div class="flex justify-content-between align-items-center gap-3 my-2">
		<span class="m-0 font-bold text-lg">Non Functional Requirements</span>
	</div>
	<ng-container [formGroup]="form">
		<ng-container formArrayName="noFunctionalRequirements">
			<ng-container
				*ngFor="let section of getFormArray('noFunctionalRequirements').controls; let i = index"
				[formGroupName]="i"
			>
				<app-design-nexus-requirements
					[form]="section"
					(removeSection)="removeSection('noFunctionalRequirements', i)"
				></app-design-nexus-requirements>
			</ng-container>
		</ng-container>
	</ng-container>
	<div class="add-data-container">
		<button
			pButton
			icon="pi pi-plus-circle"
			label="Add New Section"
			class="p-button-outlined"
			(click)="addSection('noFunctionalRequirements')"
		></button>
	</div>
</div>
<div *ngIf="activeTabIndex === 3">
	<!-- Inputs and Data Sources Tab -->
	<app-design-nexus-inputs-data-source-table
		[(rows)]="pocInput.inputsDataSource"
	></app-design-nexus-inputs-data-source-table>
</div>
<div *ngIf="activeTabIndex === 4">
	<!--	 PoC Requirements Tab -->
	<div class="flex justify-content-between align-items-center gap-3 my-2">
		<span class="m-0 font-bold text-lg">PoC Requirements</span>
	</div>
	<ng-container [formGroup]="form">
		<ng-container formArrayName="pocRequirements">
			<ng-container
				*ngFor="let section of getFormArray('pocRequirements').controls; let i = index"
				[formGroupName]="i"
			>
				<app-design-nexus-requirements
					[form]="section"
					(removeSection)="removeSection('pocRequirements', i)"
				></app-design-nexus-requirements>
			</ng-container>
		</ng-container>
	</ng-container>
	<div class="add-data-container">
		<button
			pButton
			icon="pi pi-plus-circle"
			label="Add New Section"
			class="p-button-outlined"
			(click)="addSection('pocRequirements')"
		></button>
	</div>
</div>
<div *ngIf="activeTabIndex === 5">
	<!-- Integrated and Scaled Requirements Tab -->
	<div class="flex justify-content-between align-items-center gap-3 my-2">
		<span class="m-0 font-bold text-lg">Integrated and Scaled Requirements</span>
	</div>
	<!-- Section Container -->
	<ng-container [formGroup]="form">
		<ng-container formArrayName="integratedRequirements">
			<ng-container
				*ngFor="let section of getFormArray('integratedRequirements').controls; let i = index"
				[formGroupName]="i"
			>
				<app-design-nexus-requirements
					[form]="section"
					(removeSection)="removeSection('integratedRequirements', i)"
				></app-design-nexus-requirements>
			</ng-container>
		</ng-container>
	</ng-container>
	<div class="add-data-container">
		<button
			pButton
			icon="pi pi-plus-circle"
			label="Add New Section"
			class="p-button-outlined"
			(click)="addSection('integratedRequirements')"
		></button>
	</div>
</div>
<div *ngIf="activeTabIndex === 6">
	<!-- Deployment Handshake Tab -->
	<div class="flex justify-content-between align-items-center gap-3 my-2">
		<span class="m-0 font-bold text-lg">Deployment Handshake</span>
		<!--        <div class="flex align-items-center gap-2">-->
		<!--            <button-->
		<!--                pButton-->
		<!--                label="Generate Design Document"-->
		<!--                icon="pi pi-file"-->
		<!--        ></button>-->
	</div>

	<ng-template [ngIf]="pdfBased64">
		<ngx-extended-pdf-viewer [showToolbar]="false" [src]="pdfBased64"></ngx-extended-pdf-viewer>
	</ng-template>
</div>

<div *ngIf="activeTabIndex === 7">
	<!-- Effort and Estimation Tab -->
	<div class="flex justify-content-between align-items-center gap-3 my-2">
		<span class="m-0 font-bold text-lg">Effort & Cost Estimation</span>
		<div class="flex align-items-center gap-2">
			<p-button
				label="Send PoC Data"
				icon="pi pi-send"
				(onClick)="sendPocData()"
				[loading]="loadingEffort"
			></p-button>
		</div>
	</div>
	<pre *ngIf="contentEffortAndCostEstimation">
		{{ contentEffortAndCostEstimation | json }}
	</pre
	>
</div>
<div class="modal-navigation">
	<button
		pButton
		label="Back"
		class="mr-3 p-button-outlined"
		icon="pi pi-arrow-left"
		(click)="previousTab()"
		[disabled]="activeTabIndex === 0"
		*ngIf="activeTabIndex > 0"
	></button>
	<button
		pButton
		label="Next"
		icon="pi pi-arrow-right"
		(click)="nextTab()"
		[disabled]="activeTabIndex === tabHeaders.length - 1"
		[disabled]="activeTabIndex > 7"
		*ngIf="activeTabIndex < 7"
	></button>
	<button
		pButton
		label="Save"
		icon="pi pi-save"
		(click)="nextTab()"
		[disabled]="activeTabIndex === tabHeaders.length - 1"
		[disabled]="activeTabIndex < 7"
		*ngIf="activeTabIndex === 7"
	></button>
</div>
