import {
	ChangeDetectorRef,
	Component,
	ComponentRef,
	ElementRef,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ConfirmationService, ConfirmEventType, MenuItem, Message, MessageService, TreeNode } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import type { Element } from 'bpmn-js/lib/model/Types';
import Modeler from 'bpmn-js/lib/Modeler';
import Canvas from 'diagram-js/lib/core/Canvas';
import { Table } from 'dexie';
import { ClassConstructor } from 'class-transformer/types/interfaces';

import { environment } from '../../../../environments/environment';

// @ts-ignore
import CustomModeler from '../../service/modeler';
import './quill.addon';
import { ShapeColors } from './shapeColors';
import {
	DiagramBase,
	DiagramParent,
	EmbeddedAI,
	IDiagramBase,
	IDiagramDataItem,
	IOpportunity,
} from '../../api/base.api';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { AuthService } from '../../service/auth.service';
import { E2ETaxonomyService } from '../../service/e2e-taxonomy.service';
import { UseCaseService } from '../../service/use-case.service';
import { DiagramE2E, DiagramProcess, DiagramTemplate, Technology } from '../../api/e2e-taxonomy.api';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Diagram } from '../../api/use-case.api';
import { Button } from 'primeng/button';
import { CommonService } from '../../service/common.service';
import tippy, { Instance as InstanceTippy } from 'tippy.js';
import type EventBus from 'diagram-js/lib/core/EventBus';
import type ElementRegistry from 'diagram-js/lib/core/ElementRegistry';
import { DbService } from '../../service/db.service';
import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';
import deepEqual from 'deep-equal';
import { DataUpdateDiagram, EventShapeSelection, EventShapeElement } from './diagram.types';
import { UseCaseLibrary } from '../../api/simulation.api';
import { SimulationService } from '../simulation/simulation.service';

@Component({
	selector: 'app-diagram',
	templateUrl: './diagram.component.html',
	styleUrls: ['./diagram.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class DiagramComponent<T extends DiagramBase, G extends DiagramParent<T>> implements OnInit, OnDestroy {
	ready: boolean = false;
	noName: boolean = false;

	shapeColor: ShapeColors;

	updatedDiagram = false;
	updatedTypeDiagram = false;

	observer: MutationObserver;

	controller: AbortController;

	@ViewChild('canvas') bpmnContainerElt: ElementRef<HTMLDivElement>;
	@ViewChild('digitalWorker') digitalWorkerDiv: ElementRef<HTMLDivElement>;

	@ViewChild('actionSubprocess', { read: ViewContainerRef }) actionSubprocess: ViewContainerRef;

	constructor(
		public config: DynamicDialogConfig<DataUpdateDiagram>,
		public ref: DynamicDialogRef,
		public messageService: MessageService,
		public confirmationService: ConfirmationService,
		public renderer: Renderer2,
		public sanitizer: DomSanitizer,
		private cdr: ChangeDetectorRef,
		public http: HttpClient,
		private dbService: DbService,
		public commonService: CommonService,
		public authService: AuthService,
		public e2ETaxonomyService: E2ETaxonomyService,
		public useCaseService: UseCaseService,
		public simulationService: SimulationService,
	) {
		this.shapeColor = new ShapeColors();
	}

	async ngOnInit() {
		if (this.dbService.queryData4SyncSuccessful) {
			await this.onLoad();
		} else {
			const subs = this.dbService.queryData4SyncObservable.subscribe({
				next: (successful) => {
					if (successful) {
						this.onLoad().then(() => {
							subs.unsubscribe();
						});
					}
				},
			});
		}
	}

	async onLoad() {
		this.blockedDiagram = true;

		await this.clearCurrentDiagram();

		this.back = !!this.config?.data?.back;

		if (this.config?.data?.diagramE2EId) {
			this.diagramId = this.config?.data?.diagramE2EId;
			this.diagramTable = this.dbService.data_diagram_e2e as any;
			this.diagramParent = this.e2ETaxonomyService.currentFunction as any;
			this.diagramParentKey = 'function';
			this.diagramClass = DiagramE2E as any;
			this.diagramUrl = this.dbService.get_urls_hook('diagram_e2e');
			this.textPath = this.e2ETaxonomyService.currentFunction?.category.name || 'E2E';
			this.diagramType = 'E2E';
			await this.webSocket(!!this.authService.permissions?.can_edit_taxonomy);
			await firstValueFrom(
				this.http.get<{
					opportunities: { [key: string]: IOpportunity[] };
				}>('@api/e2e_taxonomy/ai_opportunity/?by_levels1=1&&function=' + this.diagramParent.id),
			).then((data) => {
				this.aiOpportunitiesParent = data.opportunities;
			});
		} else if (this.config?.data?.diagramProcessId) {
			this.diagramId = this.config?.data?.diagramProcessId;
			this.diagramTable = this.dbService.data_diagram_process as any;
			this.diagramParent = this.e2ETaxonomyService.currentLevel1 as any;
			this.diagramParentKey = 'level1';
			this.diagramClass = DiagramProcess as any;
			this.diagramUrl = this.dbService.get_urls_hook('diagram_process');
			this.textPath = this.e2ETaxonomyService.currentLevel1?.function.path || 'Process';
			this.diagramType = 'Process';
			await this.webSocket(!!this.authService.permissions?.can_edit_taxonomy);
			await firstValueFrom(
				this.http.get<{
					opportunities: { [key: string]: IOpportunity[] };
				}>('@api/e2e_taxonomy/ai_opportunity/?by_levels2=1&&level1=' + this.diagramParent.id),
			).then((data) => {
				this.aiOpportunitiesParent = data.opportunities;
			});
		} else if (this.config?.data?.diagramTemplateId) {
			this.diagramId = this.config?.data?.diagramTemplateId;
			this.diagramTable = this.dbService.data_diagram_template as any;
			this.diagramParent = this.e2ETaxonomyService.currentLevel2 as any;
			this.diagramParentKey = 'level2';
			this.diagramClass = DiagramTemplate as any;
			this.diagramUrl = this.dbService.get_urls_hook('diagram_template');
			this.textPath = this.e2ETaxonomyService.currentLevel2?.level1.path || 'SubProcess';
			// this.hasNewUseCase = !!this.authService.currentOrganization;
			this.diagramType = 'SubProcess';
			this.diagramEditOpportunity = (this.diagramParent as any).type === '2';
			await this.webSocket(!!this.authService.permissions?.can_edit_taxonomy);
			await firstValueFrom(
				this.http.get<{
					opportunities: IOpportunity[];
				}>('@api/e2e_taxonomy/ai_opportunity/?diagram=' + this.diagramId),
			).then((data) => {
				this.aiOpportunities = data.opportunities;
			});
			if (this.e2ETaxonomyService.currentLevel2 && !this.e2ETaxonomyService.currentLevel2?.values) {
				this.e2ETaxonomyService.currentLevel2.values = {
					technologies: {},
					aiExamples: 0,
					steps: 0,
				};
			}
			this.technologiesSubProcess = this.e2ETaxonomyService.currentLevel2?.values.technologies || {};
		} else if (this.config?.data?.diagramId) {
			this.diagramId = this.config?.data?.diagramId;
			this.diagramTable = this.dbService.data_diagram as any;
			this.diagramParent = this.useCaseService.currentUseCase as any;
			this.diagramParentKey = 'useCase';
			this.diagramClass = Diagram as any;
			this.diagramUrl = this.dbService.get_urls_hook('diagram');
			this.textPath = 'My Use Case';
			this.showAssessments = true;
			this.diagramType = 'UseCase';
			this.diagramEditOpportunity = true;
			await this.webSocket(true);
			await firstValueFrom(
				this.http.get<{
					opportunities: IOpportunity[];
				}>('@api/e2e_taxonomy/ai_opportunity/?useCase=' + this.diagramParent.id),
			).then((data) => {
				this.aiOpportunities = data.opportunities;
			});
		}

		this.aiOpportunities = this.aiOpportunities
			.map((op) => {
				if (op.data?.knowledgeData && typeof (op.data.knowledgeData as any) === 'string') {
					op.data.knowledgeData = this.getKnowledgeData(op.data.knowledgeData as any);
				}
				return op;
			})
			.sort((a, b) => (a.valid ? -1 : b.valid ? 1 : 0));

		if (['E2E', 'Process', 'SubProcess'].includes(this.diagramType)) {
			this.useCasesEnabled = true;
		}

		this.diagramMetaData = {
			name: this.diagramParent.name,
			description: this.diagramParent.description,
		};

		if (this.hasNewUseCase) {
			this.action3Class = 'extend-1';
		} else if (this.showAssessments) {
			this.action3Class = 'extend-2';
		} else {
			this.action3Class = 'extend-3';
		}

		if (this.can_edit) {
			this.action3Class = this.action3Class + ' save';
			this.menuBarItems.push({
				label: 'Import',
				icon: 'pi pi-fw pi-file-import',
				items: [
					{
						label: 'From text',
						icon: 'pi pi-fw pi-code',
						command: () => this.importByTextModal(),
					},
					{
						label: 'From XML',
						icon: 'pi pi-fw pi-file',
						command: () => this.importByXmlModal(),
					},
					{
						label: 'From Zip',
						icon: 'pi pi-fw pi-file',
						command: () => this.importByZipModal(),
					},
				],
			});
		}

		this.menuBarItems.push({
			label: 'Export',
			icon: 'pi pi-fw pi-file-export',
			items: [
				{
					label: 'To Zip',
					icon: 'pi pi-fw pi-file',
					command: () => this.exportToZip(),
				},
				{
					label: 'To XML',
					icon: 'pi pi-fw pi-code',
					command: () => this.exportToXML(),
				},
				{
					label: 'To SVG',
					icon: 'pi pi-fw pi-code',
					command: () => this.exportToSVG(),
				},
				{
					label: 'To PNG',
					icon: 'pi pi-fw pi-image',
					command: () => this.exportToPNG(),
				},
			],
		});

		await this.setCurrentDiagram();
		if (!this.currentDiagram) {
			this.ref.close();
		}

		this.ready = true;

		this.setCurrentModeler();

		this.setComponentEvents();

		await this.commonService.getCommonData<string[]>('technologies_sort').then((technologies_sort) => {
			if (technologies_sort && technologies_sort.data.length) {
				this.technologySort = technologies_sort.data;
			}
		});

		this.e2ETaxonomyService
			.getCategoriesFunction(
				{
					extend: 'function.level1.level2',
				},
				true,
			)
			.then((d) => {
				this.library = d;
				for (const categoryFunctionTag of this.library) {
					if (categoryFunctionTag.children?.length) {
						categoryFunctionTag.selectable = false;
						for (const functionTag of categoryFunctionTag.children) {
							functionTag.selectable = false;
							if (functionTag.children?.length) {
								for (const level1Tag of functionTag.children) {
									level1Tag.selectable = false;
								}
							}
						}
					}
				}
			});

		const navDiagram = () =>
			setTimeout(() => {
				const breadcrumbs = document.querySelector<HTMLUListElement>('.bjs-breadcrumbs');

				if (breadcrumbs && breadcrumbs.children.length <= 1) {
					this.renderer.setStyle(breadcrumbs, 'display', 'none');
				} else {
					navDiagram();
				}
			}, 100);

		navDiagram();

		this.observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
					const breadcrumbs = document.querySelector<HTMLUListElement>('.bjs-breadcrumbs');
					const breadcrumbsA = document.querySelector<HTMLAnchorElement>('.bjs-breadcrumbs li:first-child a');
					const breadcrumbsB = document.querySelector<HTMLAnchorElement>('.bjs-breadcrumbs li:last-child a');
					this.isSubprocess = breadcrumbsA !== breadcrumbsB;
					if (breadcrumbs && breadcrumbsA && this.isSubprocess) {
						this.renderer.removeStyle(breadcrumbs, 'display');
						breadcrumbsA.textContent = 'Main process';
						if (!this.subprocessButton) {
							this.subprocessButton = this.actionSubprocess.createComponent(Button);
							this.subprocessButton.instance.icon = 'pi pi-file-import';
							this.subprocessButton.instance.styleClass =
								'subprocess-button p-button-rounded p-button-text';
							this.subprocessButton.instance.style = {
								left: (breadcrumbs.offsetWidth + 7).toString() + 'px',
							};
							this.renderer.listen(this.subprocessButton.location.nativeElement, 'click', () => {
								this.subprocessModalVisible = true;
								this.librarySelect = undefined;
							});
							breadcrumbs.parentNode?.insertBefore(
								this.subprocessButton.location.nativeElement,
								breadcrumbs.nextSibling,
							);
						}

						const buttonElem = this.subprocessButton.location.nativeElement.querySelector('button');
						this.renderer.setStyle(buttonElem, 'left', (breadcrumbs.offsetWidth + 7).toString() + 'px');
						this.renderer.setStyle(buttonElem, 'display', 'flex');
					} else if (this.subprocessButton) {
						const buttonElem = this.subprocessButton.location.nativeElement.querySelector('button');
						this.renderer.setStyle(buttonElem, 'display', 'none');

						if (breadcrumbs && breadcrumbs.children.length <= 1) {
							this.renderer.setStyle(breadcrumbs, 'display', 'none');
							this.subprocessButton = undefined as any;
						}
					}
				} else if (mutation.type === 'childList' && mutation.removedNodes.length > 0 && this.subprocessButton) {
					const buttonElem = this.subprocessButton.location.nativeElement.querySelector('button');
					this.renderer.setStyle(buttonElem, 'display', 'none');
				}
			});
		});
	}

	ngOnDestroy() {
		this.destroyDiagramComponent();
	}

	destroyDiagramComponent() {
		if (this.observer) {
			this.observer.disconnect();
		}
		if (this.socket) {
			this.socket.close();
		}
		this.messages = [];
	}

	webSocket(canEdit: boolean): Promise<void> {
		if (this.diagramId) {
			const url = environment.url.replace('http', 'ws');
			if (this.diagramType === 'UseCase') {
				this.socket = new WebSocket(
					url + '/user-case/' + this.diagramParent.id + '/' + '?token=' + localStorage.getItem('auth-token'),
				);
			} else {
				this.socket = new WebSocket(
					url + '/diagram/' + this.diagramId + '/' + '?token=' + localStorage.getItem('auth-token'),
				);
			}

			return new Promise<void>((resolve) => {
				this.socket.addEventListener('message', (event) => {
					const data: { id: string; username: string } = JSON.parse(event.data);
					if (data.id) {
						this.can_edit = false;
						this.messageSocket = {
							severity: 'warn',
							summary: this.diagramType === 'UseCase' ? 'Use Case in use' : 'Diagram in use',
							detail:
								this.diagramType === 'UseCase'
									? `The user "${data.username}" is using this use case at this moment, so you cannot save any changes while that user is connected.`
									: `The user "${data.username}" is using this diagram at this moment, so you cannot save any changes while that user is connected.`,
							life: 5000,
						};
						this.messages = [{ ...this.messageSocket, life: 8000 }];
					} else {
						this.can_edit = canEdit;
					}
					resolve();
				});
				this.socket.onerror = () => {
					this.webSocket(canEdit).then(() => {
						resolve();
					});
				};
			});
		} else {
			return new Promise<void>((resolve) => {
				this.messageSocket = {
					severity: 'warn',
					summary: 'Unknown error',
					detail: 'There was a problem with the connection, this diagram cannot be saved at the moment, please close and reopen, or contact technical support.',
					life: 5000,
				};
				this.messages = [{ ...this.messageSocket, life: 8000 }];
				resolve();
			});
		}
	}

	safeHTML(html: string) {
		const parser = new DOMParser();
		const document = parser.parseFromString(html, 'text/html');
		return this.sanitizer.bypassSecurityTrustHtml(document.body.outerHTML);
	}

	diagramType: 'E2E' | 'Process' | 'SubProcess' | 'UseCase';

	diagramId: string;

	diagramParent: G;

	diagramParentKey: string;

	/**
	 * Represents the URL of a diagram.
	 *
	 * @typedef {string} diagramUrl
	 */
	diagramUrl: string;

	/**
	 * Represents a diagram table.
	 * @template T - The type of the table.
	 */
	diagramTable: Table<T, string>;

	/**
	 * Represents a diagram class.
	 * @template T - The type of the class to be constructed.
	 * @constructor
	 * @param {Function} ClassConstructor - The constructor function for the class.
	 * @returns {ClassConstructor<T>} - The constructed diagram class.
	 */
	diagramClass: ClassConstructor<T>;

	/**
	 * Represents the current modeler.
	 * @type {Modeler | undefined}
	 */
	currentModeler: Modeler | undefined;

	/**
	 * Represents the current diagram.
	 *
	 * @type {T | undefined}
	 */
	currentDiagram: T | undefined;
	/**
	 * Represents the current original diagram.
	 *
	 * @type {T | undefined}
	 */
	currentOriginalDiagram: T | undefined;

	socket: WebSocket;

	messageSocket: Message;

	messages: Message[];

	textPath: string = '';

	action3Class = '';

	can_edit: boolean = false;

	diagramEditOpportunity: boolean = false;

	showAssessments: boolean = false;

	hasNewUseCase: boolean = false;

	back: boolean = false;

	blockedDiagram = false;

	blockedSaved = false;

	menuBarItems: MenuItem[] = [];

	library: TreeNode[] = [];

	isSubprocess = true;

	subprocessButton: ComponentRef<Button>;

	subprocessModalVisible: boolean = false;

	aiOpportunities: IOpportunity[] = [];
	aiOpportunitiesParent: { [key: string]: IOpportunity[] } = {};
	useCases: UseCaseLibrary[] = [];

	technologySort: string[] = [];

	/**
	 * Represents the current diagram source.
	 *
	 * @type {Subject<string>}
	 */
	public currentDiagramSource: Subject<string> = new Subject<string>();
	/**
	 * The variable `currentDiagramSource$` represents the observable stream of the
	 * current diagram source. It is used to observe changes to the current diagram source
	 * and react accordingly.
	 *
	 * @type {Observable<string>}
	 */
	currentDiagramSource$: Observable<string> = this.currentDiagramSource.asObservable();

	quillOptions = {
		'check-box-list': true,
	};

	/**
	 * Represents an empty BPMN diagram.
	 *
	 * @type {string}
	 */
	emptyDiagram: string = `<?xml version="1.0" encoding="UTF-8"?>
<bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn">
  <bpmn2:process id="Process_1" isExecutable="false">
    <bpmn2:startEvent id="StartEvent_1"/>
  </bpmn2:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds height="36.0" width="36.0" x="412.0" y="240.0"/>
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn2:definitions>`;

	/**
	 * Creates a new diagram base object.
	 * @param {string} xml - The XML data of the diagram.
	 * @param {string} capture_url - The URL to the screenshot/image of the diagram.
	 * @param {Object} data - Additional diagram data items.
	 * @returns {Object} The newly created diagram base object.
	 */
	_newDiagramBase(xml?: string, capture_url?: string, data?: { [key: string]: IDiagramDataItem }): IDiagramBase {
		return {
			id: uuid(),
			xmlImage: capture_url || '',
			xmlData: xml?.startsWith('http') ? xml : '',
			data: data || {},
		};
	}

	newDiagram(): T {
		const data: { [key: string]: any } = {
			...this._newDiagramBase(),
		};
		data[this.diagramParentKey] = this.diagramParent;
		data[this.diagramParentKey + 'Id'] = this.diagramParent.id || '';
		return plainToInstance(this.diagramClass, data);
	}

	setCurrentModeler(): void {
		const handler_modeler = () => {
			if (!this.currentModeler) {
				this._setCurrentModeler();
				this.importXmlCurrent().then(() => {
					this.blockedDiagram = false;
					this.setColors();
					this.setDiagramEvents();
				});
			} else {
				timer_modeler();
			}
		};
		const timer_modeler = () => setTimeout(handler_modeler, 100);
		timer_modeler();
	}

	/**
	 * Initializes the current modeler with the specified configuration options.
	 *
	 * @returns {CustomModeler} The current modeler instance.
	 */
	_setCurrentModeler(): CustomModeler {
		this.currentModeler = new CustomModeler({
			container: '#canvas',
			width: '100%',
			height: '95vh',
			keyboard: {
				bindTo: document,
			},
			enabledExpanded: ['E2E', 'Process'].includes(this.diagramType),
		});
		return this.currentModeler;
	}

	/**
	 * Clears the current modeler.
	 * Detaches the current modeler, clears its content, destroys it and sets the current modeler to undefined.
	 *
	 * @function
	 * @name clearCurrentModeler
	 *
	 * @returns {void}
	 */
	clearCurrentModeler(): void {
		this.currentModeler?.detach();
		this.currentModeler?.clear();
		this.currentModeler?.destroy();
		this.currentModeler = undefined;
	}

	/**
	 * Imports the XML data for the current diagram and updates the current modeler.
	 * If the XML data is not available for the current diagram, it uses a default empty diagram.
	 *
	 * @returns {Promise<void>} A promise that resolves when the XML import is complete.
	 */
	async importXmlCurrent(): Promise<void> {
		if (this.currentDiagram && this.currentModeler) {
			if (this.currentDiagram.xmlData) {
				const bpmnXML = await firstValueFrom(
					this.http.get(this.currentDiagram.xmlData, { responseType: 'text' }),
				).catch(async () => {
					if (this.currentDiagram) {
						await firstValueFrom(this.http.get<T>('@api/' + this.diagramUrl + this.currentDiagram.id)).then(
							async (diagram) => {
								await this.diagramTable.put(diagram).then(() => {
									this.currentDiagram = diagram;
								});
							},
						);
						return firstValueFrom(this.http.get(this.currentDiagram.xmlData, { responseType: 'text' }));
					} else {
						return this.emptyDiagram;
					}
				});
				this.currentModeler.importXML(bpmnXML).then(() => {
					if (this.currentModeler) {
						const canvas = this.currentModeler.get<Canvas>('canvas');
						const scale = canvas.zoom('fit-viewport', 'auto' as any);
						canvas.zoom(Math.max(scale - 0.2, 0.2), 'auto' as any);
					}
				});
			} else {
				this.currentModeler.importXML(this.emptyDiagram).then(() => {});
			}
		}
	}

	/**
	 * This method retrieves the XML representation of the current modeler.
	 *
	 * @returns A Promise that resolves to an object containing the XML string or an error message.
	 *          - If the XML is successfully retrieved, the object will have a 'xml' property with the XML string.
	 *          - If there is an error retrieving the XML, the object will have an 'error' property with the error message.
	 *          - If there is no current modeler, the object will have an 'error' property with the value 'no-data'.
	 *
	 */
	async getXml(): Promise<{ xml?: string; error?: string }> {
		if (this.currentModeler) {
			try {
				const { xml, error } = await this.currentModeler.saveXML({ format: true });
				if (xml) {
					return { xml } as { xml: string };
				}
				if (error) {
					return {
						error: (error as Error).message,
					};
				}
				return { error: 'no-data' };
			} catch (e) {
				return {
					error: (e as Error).message,
				};
			}
		}
		return { error: 'no-data' };
	}

	/**
	 * Retrieves the SVG representation of the current modeler.
	 *
	 * @returns {Promise<{ svg?: string; error?: string }>}
	 */
	async getSVG(): Promise<{ svg?: string; error?: string }> {
		if (this.currentModeler) {
			try {
				const { svg, error } = await (this.currentModeler as any).saveSVG({ format: true });
				if (svg) {
					return { svg } as { svg: string };
				}
				if (error) {
					return {
						error: (error as Error).message,
					};
				}
				return { error: 'no-data' };
			} catch (e) {
				return {
					error: (e as Error).message,
				};
			}
		}
		return { error: 'no-data' };
	}

	/**
	 * Converts a Blob to a Base64 string asynchronously.
	 *
	 * @param {Blob} blob - The Blob to convert.
	 * @return {Promise<string>} - A Promise that resolves to the Base64 string representation of the Blob.
	 */
	async blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
		return new Promise((resolve, _) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.readAsDataURL(blob);
		});
	}

	/**
	 * Retrieves the URL of an SVG file asynchronously.
	 *
	 * @returns {Promise<{ url?: string; error?: string }>} - A promise that resolves to an object containing either the URL or an error message.
	 */
	async getSVGUrl(): Promise<{ url?: string; error?: string }> {
		const d = await this.getSVG();
		if (d.svg) {
			const svgBlob = new Blob([d.svg], {
				type: 'image/svg+xml;charset=utf-8',
			});

			return {
				url: (await this.blobToBase64(svgBlob)) as any,
			};
		} else if (d.error) {
			return { error: d.error };
		}
		return { error: 'no-data' };
	}

	/**
	 * Retrieves the diagram from the diagram table based on the given query.
	 *
	 * @param {string | { level2Id: string }} query - The query to search for the diagram.
	 * @return {Promise<T | undefined>} - A promise that resolves to the diagram object if found, or undefined if not found.
	 */
	async getDiagram(query: string | { level2Id: string }): Promise<T | undefined> {
		return this.diagramTable.get(query as any);
	}

	/**
	 * Saves a diagram to the database.
	 *
	 * @param {DiagramBase} diagram - The diagram to be saved.
	 *
	 * @return {Promise<DiagramBase>} - A promise that resolves with the saved diagram object.
	 */
	async saveDiagramData(diagram: DiagramBase): Promise<DiagramBase> {
		const original_diagram = await this.getDiagram(diagram.id || '');
		const data = plainToInstance(this.diagramClass, {
			...(original_diagram || {}),
			...diagram,
		});
		if (!data.id) {
			data.id = uuid();
		}
		await this.diagramTable.put(data);
		return data;
	}

	/**
	 * Sets the current diagram to the provided diagram.
	 *
	 * @return {Promise<void>} - A promise that resolves once the current diagram has been set.
	 */
	async setCurrentDiagram(): Promise<void> {
		this.currentDiagram = await this.getDiagram(this.diagramId);
		if (!this.currentDiagram) {
			this.currentDiagram = this.newDiagram();
		}
		if (this.diagramType === 'UseCase' && !(this.currentDiagram as any).organization) {
			(this.currentDiagram as any).organization = await this.authService.getCurrentOrganization();
			if ((this.currentDiagram as any).organization?.id) {
				(this.currentDiagram as any).organizationId = (this.currentDiagram as any).organization.id;
			}
		}
		this.currentOriginalDiagram = this.currentDiagram ? instanceToInstance(this.currentDiagram) : undefined;
	}

	/**
	 * Clears the current diagram.
	 *
	 * @returns {Promise} A promise that resolves when the current diagram is cleared.
	 */
	async clearCurrentDiagram(): Promise<any> {
		this.currentDiagram = undefined;
		this.currentOriginalDiagram = this.currentDiagram ? instanceToInstance(this.currentDiagram) : undefined;
	}

	/**
	 * Saves the files related to a diagram.
	 *
	 * @param {T | undefined} diagram - The diagram object.
	 * @param {string} [xml] - The XML data to be saved.
	 * @param {string} [svg] - The SVG data to be saved.
	 *
	 * @returns {Promise<string>} - A promise that resolves to an empty string if the files are saved successfully,
	 *                             'error' if there is an error during the saving process, or 'no-data' if the
	 *                             diagram object is undefined.
	 */
	async saveFilesDiagram(diagram: T | undefined, xml?: string, svg?: string): Promise<string> {
		if (diagram) {
			const headers = new HttpHeaders({
				enctype: 'multipart/form-data',
			});
			const formData = new FormData();
			if (xml) {
				formData.append('xmlData', new File([xml], 'diagram.xml', { type: 'text/xml' }));
			}
			if (svg) {
				formData.append('xmlImage', new File([svg], 'diagram.svg', { type: 'image/svg+xml;charset=utf-8' }));
			}
			formData.append('data', JSON.stringify(diagram.data));
			if (!diagram.xmlData) {
				const obj = await firstValueFrom(
					this.http.get('@api/' + this.diagramUrl + diagram.id, {
						headers: headers,
					}),
				).catch(() => undefined);
				if (!(obj as any)?.id) {
					await firstValueFrom(
						this.http.post('@api/' + this.diagramUrl, diagram, {
							headers: headers,
						}),
					);
				}
			}
			const request = this.http.patch('@api/' + this.diagramUrl + diagram.id + '/', formData, {
				headers: headers,
			});
			return await firstValueFrom(request)
				.then((response) => {
					if (diagram) {
						if ((response as IDiagramBase).xmlData) {
							diagram.xmlData = (response as IDiagramBase).xmlData;
						}
						if ((response as IDiagramBase).xmlImage) {
							diagram.xmlImage = (response as IDiagramBase).xmlImage;
						}
						return '';
					} else {
						return 'error';
					}
				})
				.catch(() => {
					return 'error';
				});
		}
		return 'no-data';
	}

	/**
	 * Saves the current diagram with optional XML and SVG.
	 *
	 * @param {string} xml - The XML data to save.
	 * @param {string} svg - The SVG data to save.
	 * @returns {Promise<string>} - A promise that resolves with an error message if there is an error, otherwise it resolves with an empty string.
	 */
	async saveCurrentDiagram(xml?: string, svg?: string): Promise<string> {
		const error = await this.saveFilesDiagram(this.currentDiagram, xml, svg);
		if (this.currentDiagram && !error) {
			this.currentDiagramSource.next(uuid());
			await this.saveDiagramData(this.currentDiagram);
			this.currentOriginalDiagram = instanceToInstance(this.currentDiagram);
		}
		return error;
	}

	action4Class = '';
	activeIndexHubble = 0;
	shapeDataId: string = '';
	shapeData: IDiagramDataItem | undefined;
	shapeDataNameUpdate: boolean = false;
	shapeDataTechnologies: Technology[] = [];
	shapeAIOpportunity: boolean = false;
	shapeEditAIOpportunity: boolean = false;

	selectionShape = false;

	setShapeName(element: any) {
		const businessObject = getBusinessObject(element);
		if (this.currentModeler && businessObject && businessObject.name && businessObject.name.length > 40) {
			const modeling: any = this.currentModeler.get('modeling');
			element.businessObject.name = businessObject.name.slice(0, 40) + '...';
			try {
				modeling.updateProperties(element, { name: element.businessObject.name });
			} catch (_) {}
		}
	}

	/**
	 * Sets up event listeners for the diagram.
	 *
	 * @param {boolean} [init=true] - Indicates if it is an initial setup or not.
	 *
	 * @returns {void}
	 */
	setDiagramEvents(init: boolean = true): void {
		if (!this.shapeDataTechnologies.length) {
			this.e2ETaxonomyService
				.getTechnologies({})
				.then((shapeDataTechnologies) => {
					this.shapeDataTechnologies = shapeDataTechnologies;
					this.updateTaxonomyRelData().then(() => {});
				})
				.catch(() => {
					this.shapeDataTechnologies = [];
				});
		}
		if (this.currentModeler) {
			if (init) {
				const eventBus = this.currentModeler.get<EventBus>('eventBus');

				eventBus.on('import.done', () => {
					this.observer.observe(document.querySelector('.bjs-breadcrumbs') as Node, { childList: true });
					if (this.currentDiagram && this.currentModeler) {
						const elementRegistry = this.currentModeler.get<ElementRegistry>('elementRegistry');
						for (const key in this.currentDiagram.data) {
							if (typeof this.currentDiagram.data[key] === 'object') {
								const element = elementRegistry.get(key);
								this.setShapeName(element);
							}
						}
					}
				});

				eventBus.on('drag.move', () => {
					if (this.showAllTippy) {
						this.toggleAllTippy();
					}
				});

				eventBus.on('canvas.viewbox.changed', () => {
					if (this.showAllTippy) {
						this.toggleAllTippy();
					}
				});

				eventBus.on('palette.element.click', () => {
					this.selectionShape = false;
					if (this.shapeDataId) {
						this.clearDiagramSelection();
					}
				});

				eventBus.on('selection.changed', (e: EventShapeSelection) => {
					if (!this.selectionShape) {
						this.selectionShape = true;
						this.diagramEventSelection(e).then(() => {
							this.selectionShape = false;
						});
						return;
					} else {
						return false;
					}
				});

				eventBus.on('commandStack.shape.delete.postExecuted', (e: EventShapeElement) =>
					this.diagramEventDelete(e),
				);
				eventBus.on('commandStack.shape.replace.postExecuted', () => {
					this.updatedDiagram = true;
					this.updatedTypeDiagram = true;
				});
				eventBus.on('commandStack.shape.create.postExecuted', (e: EventShapeElement) =>
					this.diagramEventCreate(e),
				);

				eventBus.on('custom.taxonomy-rel', (e: EventShapeElement) => {
					this.checkTaxonomyRel(e.context.shape.id);
				});

				eventBus.on('custom.taxonomy-rel-go', (e: EventShapeElement) => {
					this.goTaxonomyRel(e.context.shape.id);
				});
			}

			const setPopovers = () =>
				setTimeout(() => {
					if (this.bpmnContainerElt && this.currentDiagram?.data) {
						if (this.bpmnContainerElt) {
							const ids: string[] = [];

							for (const shapeId in this.currentDiagram.data) {
								const shapeData: IDiagramDataItem = this.currentDiagram.data[shapeId];
								if (shapeData && shapeData.technologiesIds && shapeData.technologiesIds.length) {
									ids.push(shapeId);
								}
							}

							if (ids.length) {
								this.diagramEventAddPopover(ids);
							}
						}
					} else {
						setPopovers();
					}
				}, 100);

			setPopovers();

			const setBadges = () =>
				setTimeout(() => {
					if (this.bpmnContainerElt && this.currentDiagram?.data) {
						if (this.bpmnContainerElt) {
							for (const shapeId in this.currentDiagram.data) {
								const shapeData: IDiagramDataItem = this.currentDiagram.data[shapeId];
								const valid = this.diagramAddBadge(shapeId, shapeData);
								if (!valid) {
									delete this.currentDiagram.data[shapeId];
								}
							}
						}
					} else {
						setBadges();
					}
				}, 100);

			setBadges();

			if (init) {
				const eventBus = this.currentModeler.get<EventBus>('eventBus');

				if (this.can_edit) {
					const events = [
						'create.end',
						'create.out',
						'create.move',
						'create.hover',
						'create.contextmenu',
						'shape.move.start',
						'shape.move.move',
						'shape.move.end',
						'shape.move.reject',
						'shape.move.cancel',
						'shape.resize.move',
						'shape.resize.start',
						'shape.resize.end',
						'connect.hover',
						'connect.out',
						'connect.start',
						'connect.move',
						'connect.end',
						'connect.cancel',
						'connect.contextmenu',
						'global.connect.start',
						'global.connect.hover',
						'global.connect.drag',
						'global.connect.end',
						'global.connect.cancel',
					];

					eventBus.on(events, 5000, () => {
						this.updatedDiagram = true;
					});
				} else {
					const contextPad: any = this.currentModeler.get('contextPad');

					contextPad.open = function (element: any, forced: any) {
						return false;
					};

					const palette: any = this.currentModeler.get('palette');
					palette._container.style.display = 'none';

					const events = [
						'element.dblclick',
						'element.mousedown',
						'element.mouseup',
						'element.hover',
						'element.out',
						'element.contextmenu',
						'create.end',
						'create.out',
						'create.move',
						'create.hover',
						'create.contextmenu',
						'shape.move.start',
						'shape.move.move',
						'shape.move.end',
						'shape.move.reject',
						'shape.move.cancel',
						'shape.resize.move',
						'shape.resize.start',
						'shape.resize.end',
						'connect.hover',
						'connect.out',
						'connect.start',
						'connect.move',
						'connect.end',
						'connect.cancel',
						'connect.contextmenu',
						'global.connect.start',
						'global.connect.hover',
						'global.connect.drag',
						'global.connect.end',
						'global.connect.cancel',
					];

					eventBus.on(events, 5000, function () {
						return false;
					});
				}
			}
		}
	}

	elements_badges: string[] = [];

	diagramAddBadge(shapeId: string, shapeData: IDiagramDataItem) {
		if (this.currentModeler && shapeData && shapeData.technologiesIds?.length && this.aiOpportunities.length) {
			const elementRegistry = this.currentModeler.get<ElementRegistry>('elementRegistry');
			const element = elementRegistry.get(shapeId);
			const count = this.aiOpportunities.filter((d) => d.shape_id === shapeId).filter((d) => d.valid).length;
			if (count && element) {
				const svg = document.querySelector(`.djs-shape[data-element-id="${shapeId}"]`);
				if (svg) {
					svg.innerHTML += `
						<circle class="badge-svg" fill="#CC3333" r="10" cy="0" cx="100"></circle>
						<text class="badge-svg" x="100" y="4" font-size="12px" text-anchor="middle" fill="white">${count}</text>
					`;
				}
				this.elements_badges.push(shapeId);
			}
			return !!element;
		}
		return true;
	}

	diagramRemoveBadge(shapeId: string) {
		if (this.currentModeler) {
			const i = this.elements_badges.findIndex((d) => d === shapeId);
			if (i >= 0) {
				const badge = document.querySelectorAll(`.djs-shape[data-element-id="${shapeId}"] .badge-svg`);
				badge.forEach((element) => {
					if (element.parentNode) {
						element.parentNode.removeChild(element);
					}
				});
				this.elements_badges = this.elements_badges.filter((d) => d !== shapeId);
			}
		}
	}

	elements_popover: string[] = [];
	elements_popover_instance: InstanceTippy[] = [];

	diagramEventAddPopover(ids: string[], shapeData?: IDiagramDataItem) {
		const ids_add: string[] = [];
		for (const id of ids) {
			if (!this.elements_popover.includes(id)) {
				this.elements_popover.push(id);
				ids_add.push(id);
			}
		}

		if (!!ids_add.length && !!this.bpmnContainerElt && !!this.currentModeler) {
			const elementRegistry = this.currentModeler.get<ElementRegistry>('elementRegistry');

			const getBpmnElementInfoAsHtml = (reference: HTMLElement) => {
				let htmlTemplate = '';

				if (this.currentDiagram?.data) {
					const shapeId = reference.getAttribute('data-element-id') || '';
					const shapeData: IDiagramDataItem | undefined =
						shapeId === this.shapeDataId ? this.shapeData : this.currentDiagram.data[shapeId];
					if (shapeData) {
						shapeData.technologiesIds.forEach((technoId) => {
							const technology = this.getTechnology(technoId);
							if (technology) {
								htmlTemplate += `
								<div class="flex gap-2 align-items-center m-1" style="font-size:10px">
									<i class="icon-tooltip active">${technology.svg}</i>
									<span>${technology.name}</span>
								</div>
								`;
							}
						});
					}
				}

				return htmlTemplate;
			};

			ids_add.forEach((id) => {
				const htmlElement = elementRegistry.getGraphics(id);

				const onShow = (instance: InstanceTippy) => {
					const content = getBpmnElementInfoAsHtml(instance.reference as any);
					instance.setContent(content);
					if (!content) {
						return false;
					}
					return;
				};

				if (this.bpmnContainerElt && htmlElement) {
					const instance = tippy(htmlElement, {
						content: 'Loading...',
						allowHTML: true,
						appendTo:
							this.bpmnContainerElt.nativeElement.parentElement || this.bpmnContainerElt.nativeElement,
						arrow: false,
						offset: [0, -5],
						placement: 'bottom',
						onShow,
						zIndex: 100,
						duration: 10,
					});

					this.elements_popover_instance.push(instance);
				}
			});
		}
	}

	showAllTippy = false;

	toggleAllTippy() {
		this.showAllTippy = !this.showAllTippy;
		this.elements_popover_instance.forEach((t) => {
			if (this.showAllTippy) {
				t.show();
				t.setProps({
					onHide(instance: InstanceTippy): void | false {
						return false;
					},
				});
			} else {
				t.setProps({
					onHide(instance: InstanceTippy): void | false {},
				});
				t.hide();
			}
		});
	}

	clearDiagramSelection() {
		this.shapeDataId = '';
		this.shapeData = undefined;
		this.shapeAIOpportunity = false;
		this.shapeEditAIOpportunity = false;
		this.action4Class = '';
		this.embeddedAILoading = false;
		this.action8Class = '';
		this.action5Class = '';
		this.shapeDataNameUpdate = false;
		this.generateAIOpportunitiesLoading = false;
	}

	originalData: any;

	async diagramEventSelection(e: EventShapeSelection) {
		if (this.diagramMetaDataEditable) {
			this.saveDiagramMetaData();
		}
		if (e && e.oldSelection && e.oldSelection.length) {
			const shapeElement: Element | undefined = e.oldSelection[0];
			if (
				shapeElement &&
				((shapeElement.type.startsWith('bpmn:') && shapeElement.type.endsWith('Task')) ||
					shapeElement.type === 'bpmn:CallActivity')
			) {
				await this.closeStepInfo();
			}
		}
		if (this.controller) {
			this.controller.abort();
		}
		if (e && e.newSelection && e.newSelection.length) {
			const shapeElement: Element | undefined = e.newSelection[0];
			if (
				shapeElement &&
				((shapeElement.type.startsWith('bpmn:') && shapeElement.type.endsWith('Task')) ||
					shapeElement.type === 'bpmn:CallActivity')
			) {
				if (this.currentDiagram) {
					if (!this.currentDiagram.data) {
						this.currentDiagram.data = {};
					}
					if (!this.originalData) {
						this.originalData = { ...this.currentDiagram.data };
					}
					this.shapeDataId = shapeElement.id;
					let shapeData: IDiagramDataItem = this.currentDiagram.data[shapeElement.id];
					let resetShapeData = false;
					if (!shapeData) {
						shapeData = this.originalData[shapeElement.id];
						if (shapeData) {
							resetShapeData = true;
						}
					}
					if (!shapeData) {
						shapeData = {
							name: 'New Step',
							type: shapeElement.type,
							description: '',
							technologiesIds: [],
							aiTechnologies: {},
						};
						this.currentDiagram.data[shapeElement.id] = shapeData;
						if (this.currentModeler) {
							const modeling: any = this.currentModeler.get('modeling');
							const elementRegistry = this.currentModeler.get<ElementRegistry>('elementRegistry');
							if (modeling && elementRegistry) {
								const element = elementRegistry.get(this.shapeDataId);
								const businessObject = getBusinessObject(element);
								if (businessObject && businessObject.name) {
									shapeData.name = businessObject.name;
								}
							}
						}
					} else {
						if (!shapeData.name) {
							shapeData.name = 'New Step';
						}
						if (!shapeData.type) {
							shapeData.type = shapeElement.type;
						}
						// Por incompatibilidad en actualización
						if (this.currentOriginalDiagram?.data && this.currentOriginalDiagram.data[shapeElement.id]) {
							this.currentOriginalDiagram.data[shapeElement.id].type = shapeElement.type;
						}
						if (!shapeData.aiTechnologies) {
							shapeData.aiTechnologies = {};
						}
					}
					this.shapeData = { ...shapeData };
					if (resetShapeData) {
						await this.saveDataItem();
						this.diagramEventAddPopover([this.shapeDataId]);
					}
					if (!this.shapeData.technologiesIds?.length) {
						// this.actionHubbleInitLoading = true;
					}
					// AI Opportunity
					if (
						this.diagramType === 'SubProcess' ||
						this.diagramType === 'UseCase' ||
						((this.diagramType === 'E2E' || this.diagramType === 'Process') &&
							shapeData.taxonomyRel &&
							this.aiOpportunitiesParent[shapeData.taxonomyRel]?.length)
					) {
						this.shapeAIOpportunity = true;
					}
					if (this.diagramType === 'SubProcess' || this.diagramType === 'UseCase') {
						this.shapeEditAIOpportunity = this.can_edit;
					} else {
						this.shapeEditAIOpportunity = false;
					}
					// AI Opportunities
					if (this.diagramType === 'SubProcess' || this.diagramType === 'UseCase') {
						this.currentAIOpportunities = this.aiOpportunities.filter(
							(d) => d.shape_id === this.shapeDataId,
						);
					} else {
						this.currentAIOpportunities = (
							this.aiOpportunitiesParent[this.shapeData.taxonomyRel as string] || []
						).filter((d) => d.valid);
					}
					const timer = setTimeout(() => {
						this.action4Class = 'active';
						this.activeIndexHubble = 0;
						clearTimeout(timer);
						if (this.action8Class) {
							this.hideAction8();
						}
					}, 200);
				}
			}
		}
	}

	shapeTempId: string = '';

	async diagramEventDelete(e: EventShapeElement) {
		this.clearDiagramSelection();
		if (!this.shapeTempId && this.currentDiagram?.data) {
			delete this.currentDiagram.data[e.context.shape.id];
			this.elements_popover = this.elements_popover.filter((d) => e.context.shape.id !== d);
		}
		this.shapeTempId = '';
	}

	async diagramEventCreate(e: EventShapeElement) {
		this.shapeTempId = e.context.shape.id;
	}

	save_data_item_running = false;

	async closeStepInfo() {
		if (this.controller) {
			this.controller.abort();
		}
		if (this.shapeDataId && this.shapeData) {
			await this.saveDataItem();
			if (this.currentModeler && this.currentDiagram?.data) {
				this.diagramRemoveBadge(this.shapeDataId);
				let shapeData: IDiagramDataItem = this.currentDiagram.data[this.shapeDataId];
				this.diagramAddBadge(this.shapeDataId, shapeData);
			}
		}
		if (!this.selectionShape) {
			this.clearDiagramSelection();
		}
	}

	async saveDataItem() {
		if (this.save_data_item_running || !this.can_edit) {
			return;
		}
		this.save_data_item_running = true;
		if (this.can_edit && this.currentDiagram && this.shapeDataId && this.shapeData) {
			let statusUpdatedDiagram = this.updatedDiagram;
			if (!this.currentDiagram.data) {
				this.currentDiagram.data = {};
			}
			if (this.currentModeler) {
				const modeling: any = this.currentModeler.get('modeling');
				const elementRegistry = this.currentModeler.get<ElementRegistry>('elementRegistry');
				if (modeling && elementRegistry) {
					const element = elementRegistry.get(this.shapeDataId);
					const businessObject = getBusinessObject(element);
					if (businessObject && businessObject.name !== this.shapeData.name) {
						if (this.shapeDataNameUpdate) {
							businessObject.name = this.shapeData.name;
						} else {
							this.shapeData.name = businessObject.name;
						}
					}
					try {
						modeling.updateProperties(element, { name: this.shapeData.name });
					} catch (_) {}
					this.setShapeName(element);
				}
				this.diagramRemoveBadge(this.shapeDataId);
				this.diagramAddBadge(this.shapeDataId, this.shapeData);
			}
			this.updatedDiagram = statusUpdatedDiagram || this.updatedTypeDiagram;
			if (!deepEqual(this.originalData[this.shapeDataId], this.shapeData)) {
				this.currentDiagram.data[this.shapeDataId] = this.shapeData;
				this.originalData[this.shapeDataId] = this.shapeData;
				this.updatedDiagram = true;
			}
			if (this.shapeData && this.shapeData.technologiesIds && this.shapeData.technologiesIds.length) {
				this.diagramEventAddPopover([this.shapeDataId]);
			}
		}
		this.save_data_item_running = false;
	}

	setComponentEvents() {
		this.ref.onClose.pipe().subscribe(async () => {
			await this.clearCurrentDiagram();
		});

		this.ref.onDestroy.pipe().subscribe(async () => {
			await this.clearCurrentDiagram();
		});
	}

	setColors() {
		if (this.currentModeler) {
			const modeling: any = this.currentModeler.get('modeling');
			const elementRegistry = this.currentModeler.get<ElementRegistry>('elementRegistry');

			const handler = () => {
				if (elementRegistry.getAll().length) {
					for (const type in this.shapeColor.getColorDiagramShape) {
						const color = this.shapeColor.getColorDiagramShape[type];

						const elements = elementRegistry.filter((element: any) => element.type === type);
						if (elements.length) {
							modeling.setColor(elements, {
								stroke: color[0],
								fill: color[1],
							});
						}
					}
				} else {
					timer();
				}
			};
			const timer = () => setTimeout(handler, 1);
			timer();

			const eventBus = this.currentModeler.get<EventBus>('eventBus');

			eventBus.on(
				'commandStack.shape.create.postExecuted',
				(e: {
					context?: {
						shape?: {
							id?: string;
						};
					};
				}) => {
					if (e?.context?.shape?.id) {
						const element = elementRegistry.get(e.context.shape.id);
						if (element) {
							const color = this.shapeColor.getColorDiagramShape[(element as Element).type];

							if (color && color.length) {
								modeling.setColor([element], {
									stroke: color[0],
									fill: color[1],
								});
							}
						}
					}
				},
			);
		}

		for (let i = 0; i < this.shapeColor.getColorPaletteShape.length; i++) {
			const shape = this.shapeColor.getColorPaletteShape[i];
			let elements = document.getElementsByClassName(shape['class-name']);
			for (let i = 0; i < elements.length; i++) {
				let element = elements[i];
				if (element instanceof HTMLElement) {
					element.style.color = shape['color'];
				}
			}
		}
	}

	async checkCloseDiagram() {
		if (this.showAllTippy) {
			this.toggleAllTippy();
		}
		if (this.can_edit) {
			if (this.updatedDiagram) {
				this.confirmationService.confirm({
					header: 'Your diagram has changed',
					message: 'Do you want to save changes on ' + this.diagramMetaData?.name + '?',
					icon: 'pi pi-exclamation-triangle',
					acceptLabel: 'Save Changes',
					acceptIcon: 'pi pi-save',
					accept: () => {
						this.saveDiagram().then(() => {
							this.closeDiagram();
						});
					},
					rejectLabel: "Don't Save",
					reject: (type: ConfirmEventType) => {
						switch (type) {
							case ConfirmEventType.REJECT:
								this.closeDiagram();
								break;
							case ConfirmEventType.CANCEL:
								break;
						}
					},
				});
			} else {
				this.closeDiagram();
			}
		} else {
			this.closeDiagram();
		}
	}

	closeDiagram() {
		this.noName = false;
		this.ref.close();
	}

	async saveDiagram() {
		if (this.can_edit) {
			if (this.diagramMetaDataEditable) {
				this.saveDiagramMetaData();
			}
			if (this.shapeDataId && this.shapeData) {
				await this.saveDataItem();
			}
			if (this.diagramType === 'SubProcess') {
				if (this.e2ETaxonomyService.currentLevel2) {
					let technologiesIds: string[] = [];
					if (this.currentDiagram?.data) {
						for (const key in this.currentDiagram.data) {
							const data = this.currentDiagram.data[key];
							if (typeof data === 'object') {
								if (data.technologiesIds) {
									technologiesIds.push(...data.technologiesIds);
								}
							}
						}
					}
					technologiesIds = Array.from(new Set(technologiesIds));
					this.technologySort.map((t) => {
						this.technologiesSubProcess[t] = technologiesIds.includes(t);
					});
					this.e2ETaxonomyService.currentLevel2.values.technologies = this.technologiesSubProcess;
				}
				await this.e2ETaxonomyService.saveCurrentLevel2();
				if (this.e2ETaxonomyService.currentLevel2) {
					this.e2ETaxonomyService.diagramTemplateSubject.next(
						this.e2ETaxonomyService.currentLevel2.id as string,
					);
				}
			}
			const d = await this.getXml();
			const s = await this.getSVG();
			if (d.xml && s.svg) {
				if (this.currentDiagram) {
					this.currentDiagram.data = this.currentDiagram.data ? { ...this.currentDiagram.data } : {};
					this.blockedSaved = true;
					if (this.diagramType === 'SubProcess' || this.diagramType === 'UseCase') {
						const data: { [key: string]: any } = {
							opportunities: this.aiOpportunities.filter((d) =>
								this.updatedAIOpportunities.includes(d.id),
							),
							deleted: this.deletedAIOpportunities,
						};
						if (this.diagramType === 'UseCase') {
							data['useCase'] = this.diagramParent.id;
						} else {
							data['diagram'] = this.diagramId;
						}
						await this.saveCurrentDiagram(d.xml, s.svg).then((error) => {
							firstValueFrom(this.http.post('@api/e2e_taxonomy/ai_opportunity/', data)).then(() => {
								this.updatedAIOpportunities = [];
								this.deletedAIOpportunities = [];
								this.messageSaveDiagram(error);
								this.blockedSaved = false;
							});
						});
					} else {
						this.updatedAIOpportunities = [];
						this.deletedAIOpportunities = [];
						this.saveCurrentDiagram(d.xml, s.svg).then((error) => {
							this.messageSaveDiagram(error);
							this.blockedSaved = false;
						});
					}
				}
			} else if (d.error) {
				this.messageSaveDiagram(d.error);
			}
		}
		this.updatedDiagram = false;
		this.updatedTypeDiagram = false;
	}

	messageSaveDiagram(error?: string) {
		if (error) {
			if (error === 'no-name') {
				this.noName = true;
				this.messageService.add({
					severity: 'warn',
					summary: 'No name',
					detail: 'Provide a name for the new diagram',
				});
			} else if (error === 'no-updated') {
				this.messageService.add({ severity: 'warn', summary: 'No changes', detail: 'Nothing to save' });
			} else {
				this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
			}
		} else {
			this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Diagram saved' });
		}
	}

	action5Class = '';
	diagramMetaData: { name: string; description: string } | undefined;
	diagramMetaDataEditable: { name: string; description: string } | undefined;

	openDiagramMetaDataModal() {
		this.diagramMetaDataEditable = this.diagramMetaData ? { ...this.diagramMetaData } : undefined;
		this.clearDiagramSelection();
		this.action5Class = 'active';
	}

	saveDiagramMetaData() {
		this.diagramMetaData = this.diagramMetaDataEditable ? { ...this.diagramMetaDataEditable } : undefined;
		this.diagramMetaDataEditable = undefined;
		this.action5Class = '';
		if (this.can_edit) {
			if (this.diagramParent && this.diagramMetaData) {
				this.diagramParent.name = this.diagramMetaData.name;
				this.diagramParent.description = this.diagramMetaData.description;
				if (this.diagramType === 'E2E') {
					this.e2ETaxonomyService.currentFunction = this.diagramParent as any;
					this.e2ETaxonomyService.saveCurrentFunction();
				} else if (this.diagramType === 'Process') {
					this.e2ETaxonomyService.currentLevel1 = this.diagramParent as any;
					this.e2ETaxonomyService.saveCurrentLevel1();
				} else if (this.diagramType === 'SubProcess') {
					this.e2ETaxonomyService.currentLevel2 = this.diagramParent as any;
					this.e2ETaxonomyService.saveCurrentLevel2();
				} else if (this.diagramType === 'UseCase') {
					this.useCaseService.currentUseCase = this.diagramParent as any;
					this.useCaseService.saveCurrentUseCase();
				}
			}
			this.updatedDiagram = true;
		}
	}

	async clearCurrentDiagramModeler() {
		await this.clearCurrentDiagram();
		this.clearCurrentModeler();
	}

	visible_import_by_text: boolean = false;
	importable_text: string = '';

	importByTextModal() {
		if (this.showAllTippy) {
			this.toggleAllTippy();
		}
		this.importable_text = '';
		this.visible_import_by_text = true;
	}

	async importByText() {
		this.visible_import_by_text = false;
		this.visible_import_by_xml = false;
		if (this.importable_text && this.currentModeler) {
			await this.currentModeler.importXML(this.importable_text);
		}
	}

	visible_import_by_xml: boolean = false;

	importByXmlModal() {
		if (this.showAllTippy) {
			this.toggleAllTippy();
		}
		this.importable_text = '';
		this.visible_import_by_xml = true;
	}

	onUploadXml(evt: { files: File[] }) {
		if (evt.files.length) {
			evt.files[0].text().then((s) => {
				this.importable_text = s;
			});
		}
	}

	visible_import_by_zip: boolean = false;
	importable_file: File | undefined = undefined;

	importByZipModal() {
		if (this.showAllTippy) {
			this.toggleAllTippy();
		}
		this.visible_import_by_zip = true;
	}

	onUploadZip(evt: { files: File[] }) {
		if (evt.files.length) {
			this.importable_file = evt.files[0];
		}
	}

	async importByZip() {
		if (this.importable_file) {
			const reader = new FileReader();
			reader.onload = () => {
				JSZip.loadAsync(reader.result as ArrayBuffer).then((zip) => {
					zip.file('data.xml')
						?.async('text')
						.then(async (xmlContent) => {
							if (xmlContent && this.currentModeler) {
								await this.currentModeler.importXML(xmlContent);

								zip.file('data.json')
									?.async('text')
									.then((jsonContent) => {
										if (this.currentDiagram) {
											this.currentDiagram.data = JSON.parse(jsonContent);
											this.setDiagramEvents(false);
										}
									});
							}
						});

					this.importable_file = undefined;
					this.visible_import_by_zip = false;
				});
			};
			reader.readAsArrayBuffer(this.importable_file);
		}
	}

	async exportToZip() {
		const zip = new JSZip();

		const d = await this.getXml();

		if (d.xml && this.currentDiagram?.data) {
			zip.file('data.xml', d.xml);

			zip.file('data.json', JSON.stringify(this.currentDiagram.data));

			zip.generateAsync({ type: 'blob' }).then((content) => {
				saveAs(content, (this.diagramMetaData?.name || 'Unknown') + '.zip');
			});
		}
	}

	async exportToXML() {
		const d = await this.getXml();
		if (d.xml) {
			const xmlBlob = new Blob([d.xml], {
				type: 'text/xml',
			});

			const fileName = (this.diagramMetaData?.name || 'Unknown') + '.xml';

			const downloadLink = document.createElement('a');
			downloadLink.download = fileName;
			downloadLink.innerHTML = 'Get BPMN XML';
			downloadLink.href = window.URL.createObjectURL(xmlBlob);
			downloadLink.onclick = function (event: any) {
				document.body.removeChild(event.target);
			};
			downloadLink.style.visibility = 'hidden';
			document.body.appendChild(downloadLink);
			downloadLink.click();
		} else if (d.error) {
			this.messageSaveDiagram(d.error);
		}
	}

	async exportToSVG() {
		const d = await this.getSVG();
		if (d.svg) {
			const svgBlob = new Blob([d.svg], {
				type: 'image/svg+xml',
			});

			const fileName = (this.diagramMetaData?.name || 'Unknown') + '.svg';

			const downloadLink = document.createElement('a');
			downloadLink.download = fileName;
			downloadLink.innerHTML = 'Get BPMN SVG';
			downloadLink.href = window.URL.createObjectURL(svgBlob);
			downloadLink.onclick = function (event: any) {
				document.body.removeChild(event.target);
			};
			downloadLink.style.visibility = 'hidden';
			document.body.appendChild(downloadLink);
			downloadLink.click();
		} else if (d.error) {
			this.messageSaveDiagram(d.error);
		}
	}

	async exportToPNG() {
		const d = await this.getSVG();
		if (d.svg) {
			const domUrl = window.URL || window.webkitURL || window;
			let match = d.svg.match(/height="(\d+)/m);
			const height = match && match[1] ? parseInt(match[1], 10) : 200;
			match = d.svg.match(/width="(\d+)/m);
			const width = match && match[1] ? parseInt(match[1], 10) : 200;
			const margin = 0; // set margin
			const fill = '#ffffff'; // set fill color

			if (!d.svg.match(/xmlns="/im)) {
				d.svg = d.svg.replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ');
			}

			let canvas = document.createElement('canvas');
			canvas.width = width + margin * 2;
			canvas.height = height + margin * 2;
			const ctx = canvas.getContext('2d');

			const svgBlob = new Blob([d.svg], {
				type: 'image/svg+xml;charset=utf-8',
			});
			const url = domUrl.createObjectURL(svgBlob);
			const img = new Image();
			const fileName = (this.diagramMetaData?.name || 'Unknown') + '.svg';

			img.onload = function () {
				if (ctx) {
					ctx.drawImage(img, margin, margin);
					if (fill) {
						const styled = document.createElement('canvas');
						styled.width = canvas.width;
						styled.height = canvas.height;
						const styledCtx = styled.getContext('2d');
						if (styledCtx) {
							styledCtx.save();
							styledCtx.fillStyle = fill;
							styledCtx.fillRect(0, 0, canvas.width, canvas.height);
							styledCtx.strokeRect(0, 0, canvas.width, canvas.height);
							styledCtx.restore();
							styledCtx.drawImage(canvas, 0, 0);
							canvas = styled;
						}
					}
					domUrl.revokeObjectURL(url);
					const downloadLink = document.createElement('a');
					downloadLink.download = fileName;
					downloadLink.innerHTML = 'Get BPMN PNG';
					downloadLink.href = canvas.toDataURL();
					downloadLink.onclick = function (event: any) {
						document.body.removeChild(event.target);
					};
					downloadLink.style.visibility = 'hidden';
					document.body.appendChild(downloadLink);
					downloadLink.click();
				}
			};
			img.src = url;
		} else if (d.error) {
			this.messageSaveDiagram(d.error);
		}
	}

	visible_use_case_dialog: boolean = false;

	newUseCase() {
		if (this.showAllTippy) {
			this.toggleAllTippy();
		}
		if (this.can_edit && this.updatedDiagram) {
			this.confirmationService.confirm({
				header: 'Your diagram has changed',
				message: 'Do you want to save changes on ' + this.diagramMetaData?.name + '?',
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: 'Save Changes and continue',
				acceptIcon: 'pi pi-save',
				accept: () => {
					this.saveDiagram().then(() => {
						this._NewUseCase();
					});
				},
				rejectLabel: "Don't Save and continue",
				reject: (type: ConfirmEventType) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							this._NewUseCase();
							break;
						case ConfirmEventType.CANCEL:
							break;
					}
				},
			});
		} else {
			this._NewUseCase();
		}
	}

	_NewUseCase() {
		if (this.hasNewUseCase) {
			this.newUseCaseFromLevel2();
		}
	}

	async goToAssessments() {
		if (this.can_edit && this.updatedDiagram) {
			this.confirmationService.confirm({
				header: 'Your diagram has changed',
				message: 'Do you want to save changes on ' + this.diagramMetaData?.name + '?',
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: 'Save Changes and continue',
				acceptIcon: 'pi pi-save',
				accept: () => {
					this.saveDiagram().then(() => {
						this._goToAssessments().then(() => {});
					});
				},
				rejectLabel: "Don't Save and continue",
				reject: (type: ConfirmEventType) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							this._goToAssessments().then(() => {});
							break;
						case ConfirmEventType.CANCEL:
							break;
					}
				},
			});
		} else {
			await this._goToAssessments();
		}
	}

	async _goToAssessments() {
		if (this.useCaseService.currentUseCase?.id) {
			this.destroyDiagramComponent();
			this.ref.close();
			if (!this.back) {
				await this.useCaseService.goToUseCase(this.useCaseService.currentUseCase.id, true);
			}
		}
	}

	newUseCaseFromLevel2() {
		if (this.e2ETaxonomyService.currentLevel2) {
			this.useCaseService
				.setCurrentUseCase('', { level2Id: this.e2ETaxonomyService.currentLevel2.id })
				.then(() => {
					if (this.useCaseService.currentUseCase && this.e2ETaxonomyService.currentLevel2) {
						this.useCaseService.currentUseCase.name = this.e2ETaxonomyService.currentLevel2.name;
						this.useCaseService.currentUseCase.description =
							this.e2ETaxonomyService.currentLevel2.description;
					}
					this.visible_use_case_dialog = true;
				});
		}
	}

	blockedAddUseCase = false;
	blockedAddUseCaseGo = false;

	addUseCase(toGo?: boolean) {
		if (toGo) {
			this.blockedAddUseCaseGo = true;
			this.blockedAddUseCase = false;
		} else {
			this.blockedAddUseCase = true;
			this.blockedAddUseCase = true;
			this.blockedAddUseCaseGo = false;
		}
		this.useCaseService.saveCurrentUseCase().then(async (error) => {
			if (error) {
				if (toGo) {
					this.blockedAddUseCaseGo = false;
				} else {
					this.blockedAddUseCase = false;
				}
				this.messageSaveUseCase(error);
			} else {
				if (this.useCaseService.currentUseCase?.diagram) {
					const d = await this.getXml();
					const s = await this.getSVG();
					if (d.xml && s.svg) {
						this.useCaseService.currentUseCase.diagram.xmlData = '';
						this.useCaseService.currentUseCase.diagram.xmlImage = '';
						this.useCaseService
							.saveFilesDiagram(this.useCaseService.currentUseCase.diagram, d.xml, s.svg)
							.then(async (error) => {
								if (toGo) {
									this.blockedAddUseCaseGo = false;
								} else {
									this.blockedAddUseCase = false;
								}
								this.visible_use_case_dialog = false;
								if (error) {
									this.messageSaveUseCase(error);
								} else {
									if (this.useCaseService.currentUseCase?.diagram) {
										this.useCaseService
											.saveDiagram(this.useCaseService.currentUseCase.diagram)
											.then(async () => {
												this.messageSaveUseCase();
												if (toGo && this.useCaseService.currentUseCase?.id) {
													this.ref.close();
													await this.useCaseService.goToUseCase(
														this.useCaseService.currentUseCase.id,
													);
												}
											});
									}
								}
							});
					}
				}
			}
		});
	}

	messageSaveUseCase(error?: string) {
		if (error) {
			if (error === 'no-name') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No name',
					detail: 'Provide a name for the new Use Case',
				});
			} else if (error === 'no-description') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No description',
					detail: 'Provide a description for the new Use Case',
				});
			} else if (error === 'no-updated') {
				this.messageService.add({ severity: 'warn', summary: 'No changes', detail: 'Nothing to save' });
			} else {
				this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
			}
		} else {
			this.messageService.add({
				severity: 'success',
				summary: 'Confirmed',
				detail: 'Use Case saved on My Use Cases',
			});
		}
	}

	technologiesSubProcess: { [key: string]: boolean } = {};

	selectTechnology(evt: any, hideActions: boolean = true) {
		this.updatedDiagram = true;

		if (this.showAllTippy) {
			this.toggleAllTippy();
			this.toggleAllTippy();
		}

		this.updateAIOpportunity();
	}

	action8Class = '';

	updateAIOpportunity() {
		if (this.shapeData && this.shapeDataId) {
			this.diagramRemoveBadge(this.shapeDataId);
			this.diagramAddBadge(this.shapeDataId, this.shapeData);
			this.updatedDiagram = true;
		}
	}

	createOpportunity() {
		if (this.shapeDataId) {
			const opportunity: IOpportunity = {
				id: uuid(),
				name: 'New AI Opportunity',
				description: '',
				valid: false,
				data: {},
				shape_id: this.shapeDataId,
				technologies: [],
			};
			this.currentAIOpportunities = [opportunity, ...this.currentAIOpportunities];
			this.aiOpportunities = [opportunity, ...this.aiOpportunities];
			this.editOpportunity(opportunity);
		}
	}

	visible_edit_ai_opportunity: boolean = false;
	currentEditOpportunity: IOpportunity | undefined = undefined;
	originalEditOpportunity: IOpportunity | undefined = undefined;

	editOpportunity(opportunity: IOpportunity) {
		this.currentEditOpportunity = opportunity;
		this.originalEditOpportunity = JSON.parse(JSON.stringify(opportunity));
		this.visible_edit_ai_opportunity = true;
	}

	editedOpportunity(opportunity?: IOpportunity) {
		const callback = (o: IOpportunity) => {
			if (o.data?.knowledgeData?.length) {
				o.data.knowledgeData = o.data.knowledgeData.filter((d) => !!d);
			}

			this.updatedAIOpportunities.push(o.id);
			this.updatedAIOpportunities = Array.from(new Set(this.updatedAIOpportunities));
		};
		if (
			this.currentEditOpportunity &&
			this.originalEditOpportunity &&
			!deepEqual(this.originalEditOpportunity, this.currentEditOpportunity)
		) {
			callback(this.currentEditOpportunity);
		}
		if (opportunity) {
			callback(opportunity);
		}
		this.updateAIOpportunity();
		this.currentEditOpportunity = undefined;
		this.originalEditOpportunity = undefined;
	}

	deleteOpportunity(opportunity: IOpportunity) {
		this.confirmationService.confirm({
			header: 'Delete AI opportunities?',
			accept: () => {
				this._deleteOpportunity(opportunity);
			},
		});
	}

	_deleteOpportunity(opportunity: IOpportunity) {
		this.currentAIOpportunities = this.currentAIOpportunities.filter((d) => d.id !== opportunity.id);
		this.aiOpportunities = this.aiOpportunities.filter((d) => d.id !== opportunity.id);
		this.deletedAIOpportunities.push(opportunity.id);
		this.deletedAIOpportunities = Array.from(new Set(this.deletedAIOpportunities));
		this.updatedAIOpportunities = this.updatedAIOpportunities.filter((d) => d !== opportunity.id);
		this.updateAIOpportunity();
	}

	currentAIOpportunities: IOpportunity[] = [];
	updatedAIOpportunities: string[] = [];
	deletedAIOpportunities: string[] = [];

	embeddedAIOpportunity: IOpportunity | undefined = undefined;
	embeddedAIOpportunityId: string = '';
	embeddedAILoading: boolean = false;

	embeddedAIData: EmbeddedAI[] = [];

	async toggleEmbeddedAI(opportunity: IOpportunity, regenerate: boolean = false) {
		if (this.embeddedAILoading) {
			return;
		}
		if (this.shapeData) {
			this.action8Class = '';
			this.embeddedAILoading = true;

			this.embeddedAIOpportunity = opportunity;
			this.embeddedAIOpportunityId = opportunity.id;
			this.embeddedAIData = [];

			const organization = await this.authService.getCurrentOrganization();

			if (!opportunity.data.embeddedAI || Array.isArray(opportunity.data.embeddedAI)) {
				opportunity.data.embeddedAI = {};
			}

			if (organization && opportunity.data.embeddedAI[organization.id as string] && !regenerate) {
				this.embeddedAIData = opportunity.data.embeddedAI[organization.id as string];
				this.action8Class = 'active';
				this.embeddedAILoading = false;
			} else if (organization && this.can_edit) {
				if (this.simulationService.enterpriseContext === undefined) {
					await this.simulationService.getEnterpriseContext();
				}

				const input = {
					name: 'Hewlett Packard Enterprise',
					description: '',
					additionalNotes: 'Additional Notes',
					scope: ['1'],
					search: false,
					generate: true,
					enterpriseContext: {
						/**
						 * companyName: organization?.name || '',
						 * 					industry: this.simulationService.enterpriseContext?.enterpriseContext.industry || [],
						 * 					revenue: this.simulationService.enterpriseContext?.enterpriseContext.revenue || '',
						 * 					information: this.simulationService.enterpriseContext?.enterpriseContext.information || [],
						 * 					employee: this.simulationService.enterpriseContext?.enterpriseContext.employee || '',
						 * 					data_landscape: this.simulationService.enterpriseContext?.enterpriseContext.data_landscape?.map(
						 * 						(d) => {
						 * 							const dn: { [key: string]: any } = {};
						 * 							dn[d.name] = {
						 * 								applicable: d.applicable,
						 * 								tech_vendor: d.tech_vendor,
						 * 								description: d.description,
						 * 							};
						 * 							return dn;
						 * 						},
						 * 					),
						 * */
						companyName: '',
						industry: [''],
						revenue: '',
						information: [],
						employee: '',
						data_landscape: this.simulationService.enterpriseContext?.enterpriseContext.data_landscape?.map(
							(d) => {
								const dn: { [key: string]: any } = {};
								dn[d.name] = {
									applicable: d.applicable,
									tech_vendor: d.tech_vendor,
									description: d.description,
								};
								return dn;
							},
						),
					},
					usesCasesContext: {
						aiEnablers: [],
						technologies: [],
						benefits: [],
						impact: {},
						taxonomy: [],
					},
					opportunities: [
						{
							Name: opportunity.name,
							description: opportunity.description,
							ai_enablers: (
								await this.e2ETaxonomyService.getTechnologies({ ids: opportunity.technologies })
							)
								.map((t) => t.name)
								.join(', '),
							step_name: this.shapeData?.name,
							step_description: this.shapeData?.description,
							subprocess_name: this.diagramMetaData?.name || '',
							subprocess_description: this.diagramMetaData?.description || '',
						},
					],
				};

				console.log('Aqui');
				console.log(input);

				this.http
					.post<{ ai_opportunities: any[] }>(
						environment.url + '/api/ai/opportunity_embedded/',
						JSON.stringify(input),
					)
					.subscribe({
						error: (err) => {
							console.log(err);
							this.action8Class = '';
							this.embeddedAILoading = false;
						},
						next: (data) => {
							console.log(data);

							if (
								data?.ai_opportunities &&
								data?.ai_opportunities[0] &&
								data?.ai_opportunities[0].platforms
							) {
								this.embeddedAIData = data?.ai_opportunities[0].platforms || [];
							}

							if (!opportunity.data.embeddedAI) {
								opportunity.data.embeddedAI = {};
							}

							opportunity.data.embeddedAI[organization.id as string] = this.embeddedAIData;
							this.editedOpportunity(opportunity);

							this.action8Class = 'active';
							this.embeddedAILoading = false;
						},
					});
			} else {
				this.action8Class = 'active';
				this.embeddedAILoading = false;
			}
		}
	}

	toggleToHubble(opportunity: IOpportunity) {
		if (!opportunity.valid) {
			opportunity.valid = true;
			this.editedOpportunity(opportunity);
		} else {
			this.confirmationService.confirm({
				header: 'Remove from Hubble?',
				accept: () => {
					opportunity.valid = false;
					this.editedOpportunity(opportunity);
				},
			});
		}
	}

	async hideAction8() {
		this.action8Class = '';
		this.embeddedAIOpportunityId = '';
	}

	getTechnology(technoId: string) {
		return this.shapeDataTechnologies.find((u) => u.id === technoId);
	}

	librarySelect: TreeNode | undefined;

	actionImportSubprocess() {
		this.confirmationService.confirm({
			header: 'Are you certain you want to include this diagram as a subprocess?',
			message:
				'This action will import the selected diagram into the current process as a new subprocess. If there are any existing elements inside the subprocess, they will be removed.',
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: 'Confirm',
			acceptIcon: 'pi pi-check',
			accept: () => {
				this.importSubprocess().then(() => {
					this.subprocessModalVisible = false;
				});
			},
			rejectLabel: 'Cancel',
			reject: () => {
				this.subprocessModalVisible = false;
			},
		});
	}

	async importSubprocess() {
		const svg = document.querySelector('#canvas .djs-container > svg');
		if (svg && this.currentModeler && this.librarySelect?.key) {
			const subprocessPlaneId = svg.getAttribute('data-element-id');
			if (subprocessPlaneId) {
				const diagram = await this.e2ETaxonomyService.getDiagramTemplate({ level2Id: this.librarySelect.key });
				const data = await this.getXml();
				if (data.xml && diagram?.xmlData) {
					const parser = new DOMParser();
					const mainDoc = parser.parseFromString(data.xml, 'application/xml');

					const subprocessId = subprocessPlaneId.split('_plane')[0];

					const subprocess = mainDoc.getElementById(subprocessId);
					const mainPlane = Array.from(
						mainDoc.getElementsByTagNameNS('http://www.omg.org/spec/BPMN/20100524/DI', 'BPMNPlane') || [],
					).find((plane) => plane.getAttribute('bpmnElement') === 'Activity_0aobv62');
					if (!subprocess) {
						this.messageService.add({
							severity: 'warn',
							summary: 'Error',
							detail: 'Unknown error',
						});
						return;
					}
					if (subprocess && mainPlane) {
						const subDoc = parser.parseFromString(diagram.xmlData, 'application/xml');

						const subProcess = subDoc.getElementsByTagNameNS(
							'http://www.omg.org/spec/BPMN/20100524/MODEL',
							'process',
						)[0];

						if (subProcess) {
							while (subprocess.firstChild) {
								subprocess.removeChild(subprocess.firstChild);
							}
							Array.from(subProcess.childNodes).forEach((childNode) => {
								const importedNode = mainDoc.importNode(childNode, true);
								subprocess.appendChild(importedNode);
							});
						}

						const subPlane = Array.from(
							subDoc.getElementsByTagNameNS('http://www.omg.org/spec/BPMN/20100524/DI', 'BPMNPlane') ||
								[],
						)[0];
						if (subPlane) {
							Array.from(subPlane.childNodes).forEach((childNode) => {
								const importedNode = mainDoc.importNode(childNode, true);
								mainPlane.appendChild(importedNode);
							});
						}

						// Serializa el mainDoc a XML
						const serializer = new XMLSerializer();
						const updatedXml = serializer.serializeToString(mainDoc);

						await this.currentModeler.importXML(updatedXml);

						this.messageService.add({
							severity: 'success',
							summary: 'Confirmed',
							detail: 'Subprocess updated',
						});

						const button = document.querySelector<HTMLButtonElement>(
							`#canvas .djs-overlays[data-container-id="${subprocessId}"] button.bjs-drilldown`,
						);

						if (button) {
							button.click();
						}
					}
				} else {
					this.messageService.add({
						severity: 'error',
						summary: 'No diagram',
						detail: 'Provide a Taxonomy item with diagram for the subprocess',
					});
				}
			}
		}
	}

	showTaxonomyChildModal: boolean = false;
	taxonomyChildElements: TreeNode[] = [];
	taxonomyChildElementSelection: TreeNode | undefined = undefined;
	taxonomyChildShapeId: string = '';
	taxonomyChildShapeData: IDiagramDataItem | undefined = undefined;

	checkTaxonomyRel(shapeId: string) {
		this.taxonomyChildElements = [];
		this.taxonomyChildElementSelection = undefined;
		if (
			shapeId &&
			['E2E', 'Process'].includes(this.diagramType) &&
			this.diagramParent &&
			this.currentDiagram?.data
		) {
			this.taxonomyChildShapeData = this.currentDiagram.data[shapeId];
			this.taxonomyChildShapeId = shapeId;
			this.taxonomyChildElements = (this.diagramParent as any).getChildren().map((p: DiagramParent<T>) => {
				return {
					label: p.name,
					key: p.id as string,
				};
			});
			this.taxonomyChildElementSelection = this.taxonomyChildElements.find(
				(t) => t.key === this.taxonomyChildShapeData?.taxonomyRel,
			);
			this.showTaxonomyChildModal = true;
		} else {
			this.messageService.add({
				severity: 'warn',
				summary: 'No available',
				detail: 'This type of diagram does not have taxonomy children available',
			});
		}
	}

	updateTaxonomyRel() {
		if (
			this.currentDiagram?.data &&
			this.taxonomyChildShapeId &&
			this.taxonomyChildShapeData &&
			this.currentModeler
		) {
			if (this.taxonomyChildElementSelection) {
				const p: DiagramParent<T> = (this.diagramParent as any)
					.getChildren()
					.find((p: DiagramParent<T>) => p.id === this.taxonomyChildElementSelection?.key);
				this.taxonomyChildShapeData = {
					...this.taxonomyChildShapeData,
					taxonomyRel: this.taxonomyChildElementSelection.key,
					technologiesIds: this.shapeDataTechnologies
						.filter((_, i) => ((p as any).valuesArray as boolean[])[i])
						.map((t) => t.id as string),
					name: p.name,
					description: p.description,
				};
			} else {
				this.taxonomyChildShapeData = {
					...this.taxonomyChildShapeData,
					taxonomyRel: '',
				};
			}
			this.currentDiagram.data[this.taxonomyChildShapeId] = this.taxonomyChildShapeData;
			this.shapeDataNameUpdate = true;
			const modeling: any = this.currentModeler.get('modeling');
			const replace: any = this.currentModeler.get('bpmnReplace');
			const elementRegistry = this.currentModeler.get<ElementRegistry>('elementRegistry');
			const type = this.taxonomyChildShapeData.taxonomyRel ? 'bpmn:CallActivity' : 'bpmn:Task';
			this.taxonomyChildShapeData = { ...this.taxonomyChildShapeData, type };
			replace.replaceElement(elementRegistry.get(this.taxonomyChildShapeId) as any, { type } as any);
			modeling.updateProperties(
				elementRegistry.get(this.taxonomyChildShapeId) as any,
				{ name: this.taxonomyChildShapeData.name } as any,
			);
			this.updatedDiagram = true;
			this.diagramRemoveBadge(this.taxonomyChildShapeId);
			this.diagramAddBadge(this.taxonomyChildShapeId, this.taxonomyChildShapeData);
			this.originalData[this.taxonomyChildShapeId] = this.taxonomyChildShapeData;
			this.elements_popover = this.elements_popover.filter((d) => this.taxonomyChildShapeId !== d);
			if (
				this.taxonomyChildShapeData &&
				this.taxonomyChildShapeData.technologiesIds &&
				this.taxonomyChildShapeData.technologiesIds.length
			) {
				this.diagramEventAddPopover([this.taxonomyChildShapeId]);
			}
			this.closeStepInfo().then(() => {
				this.diagramEventSelection({
					oldSelection: [],
					newSelection: [elementRegistry.get(this.taxonomyChildShapeId)],
				} as any);
			});
		}
		this.showTaxonomyChildModal = false;
	}

	async updateTaxonomyRelData() {
		if (this.currentModeler && this.currentDiagram && this.currentDiagram.data) {
			const modeling: any = this.currentModeler.get('modeling');
			const elementRegistry = this.currentModeler.get<ElementRegistry>('elementRegistry');
			for (const key in this.currentDiagram.data) {
				if (this.currentDiagram.data.hasOwnProperty(key)) {
					const data = this.currentDiagram.data[key];
					if (data.taxonomyRel) {
						const p: DiagramParent<T> | undefined = (this.diagramParent as any)
							.getChildren()
							.find((p: DiagramParent<T>) => p.id === data.taxonomyRel);

						if (p) {
							const newData: IDiagramDataItem = {
								...data,
								technologiesIds: this.shapeDataTechnologies
									.filter((_, i) => ((p as any).valuesArray as boolean[])[i])
									.map((t) => t.id as string),
								name: p.name,
								description: p.description,
							};
							this.currentDiagram.data[key] = newData;
							if (data.name !== p.name) {
								modeling.updateProperties(elementRegistry.get(key) as any, { name: p.name } as any);
							}
							const count = (this.aiOpportunitiesParent[data.taxonomyRel] || []).filter(
								(o) => o.valid,
							).length;
							if (count) {
								const svg = document.querySelector(`.djs-shape[data-element-id="${key}"]`);
								if (svg) {
									svg.innerHTML += `
										<circle class="badge-svg" fill="#CC3333" r="10" cy="0" cx="100"></circle>
										<text class="badge-svg" x="100" y="4" font-size="12px" text-anchor="middle" fill="white">${count}</text>
									`;
								}
							}
							this.elements_popover = this.elements_popover.filter((d) => key !== d);
							if (newData.technologiesIds && newData.technologiesIds.length) {
								this.diagramEventAddPopover([key]);
							}
						}
					}
				}
			}
		}
	}

	goTaxonomyRel(shapeId: string) {
		if (
			shapeId &&
			['E2E', 'Process'].includes(this.diagramType) &&
			this.diagramParent &&
			this.currentDiagram?.data
		) {
			this.confirmationService.confirm({
				header: 'Go to the child diagram in the taxonomy?',
				message:
					'This will close the current diagram without saving changes. To avoid losing changes, save this diagram first.',
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: 'Continue',
				acceptIcon: 'pi pi-reply',
				accept: () => {
					this._goTaxonomyRel(shapeId);
				},
				rejectLabel: 'Cancel',
				reject: (type: ConfirmEventType) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							break;
						case ConfirmEventType.CANCEL:
							break;
					}
				},
			});
		} else {
			this.messageService.add({
				severity: 'warn',
				summary: 'No available',
				detail: 'This type of diagram does not have taxonomy children available',
			});
		}
	}

	_goTaxonomyRel(shapeId: string) {
		if (this.currentDiagram?.data) {
			const shapeData = this.currentDiagram.data[shapeId];
			if (shapeData) {
				const p: DiagramParent<T> | undefined = (this.diagramParent as any)
					.getChildren()
					.find((p: DiagramParent<T>) => p.id === shapeData.taxonomyRel);
				if (p) {
					if (this.diagramType === 'E2E') {
						this.e2ETaxonomyService.initFunction = true;
						this.e2ETaxonomyService.lastFunction = this.diagramParent as any;
						this.closeDiagram();
						this.e2ETaxonomyService.editDiagramProcess(p as any);
					} else {
						if (!this.e2ETaxonomyService.lastFunction) {
							this.e2ETaxonomyService.initFunction = false;
						}
						this.e2ETaxonomyService.lastFunction = undefined;
						this.e2ETaxonomyService.lastLevel1 = this.diagramParent as any;
						this.closeDiagram();
						this.e2ETaxonomyService.editDiagramTemplate(p as any);
					}
				}
			}
		}
	}

	action6Class = '';
	useCasesEnabled: boolean = false;
	useCasesLoading: boolean = false;
	useCasesReady: boolean = false;
	useCaseItemModal: boolean = false;
	currentUseCaseLibrary: UseCaseLibrary | undefined = undefined;

	async openUseCaseLibraryModal() {
		if (['E2E', 'Process', 'SubProcess'].includes(this.diagramType)) {
			if (!this.useCasesReady) {
				this.useCasesLoading = true;
				this.useCases = await firstValueFrom(
					this.http.get<{
						data: { id: string }[];
					}>(
						'@api/simulation/use_case_library_rel/?' +
							{
								E2E: 'function',
								Process: 'level1',
								SubProcess: 'level2',
								UseCase: '',
							}[this.diagramType] +
							'=' +
							this.diagramParent.id,
					),
				).then((data) => {
					if (!data.data?.length) {
						return Promise.resolve([]);
					}
					return this.dbService.data_use_case_library
						.where('id')
						.anyOf(data.data.map((d) => d.id))
						.toArray();
				});
				this.useCasesLoading = false;
				this.useCasesReady = true;
			}
			this.action6Class = this.action6Class ? '' : 'active';
		}
	}

	hideAction6() {
		this.action6Class = '';
	}

	openUseCaseItemModal(useCase: UseCaseLibrary) {
		this.useCaseItemModal = true;
		this.currentUseCaseLibrary = useCase;
	}

	async chatGPT(prompt: string, stream: boolean = false) {
		this.controller = new AbortController();
		return fetch(
			'https://openai-askhackettbot-dev-eastus2.openai.azure.com/openai/deployments/gpt-4-32k/chat/completions?api-version=2023-07-01-preview',
			{
				method: 'POST',
				signal: this.controller.signal,
				headers: {
					'api-key': await this.commonService.chatgptKey(),
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: JSON.stringify({
					messages: [
						{
							role: 'user',
							content: prompt,
						},
					],
					model: 'gpt-4-32k',
					temperature: 0.75,
					top_p: 0.95,
					max_tokens: 13000,
					frequency_penalty: 0,
					presence_penalty: 0,
					stream,
					n: 1,
				}),
			},
		);
	}

	generateAIOpportunitiesLoading = false;

	generateAIOpportunities() {
		this.confirmationService.confirm({
			header: this.currentAIOpportunities.length
				? 'Generate more AI opportunities?'
				: 'Generate AI Opportunities?',
			message: 'AI Opportunities will be generated, this may take a while.',
			accept: () => {
				this._generateAIOpportunities().then(() => {});
			},
		});
	}

	async _generateAIOpportunities() {
		if (this.shapeData && this.diagramMetaData) {
			this.diagramRemoveBadge(this.shapeDataId);
			this.generateAIOpportunitiesLoading = true;
			this.updatedDiagram = true;
			this.hasCancelGenerateAIOpportunities = false;

			const stepData = {
				step: this.shapeData.name.replace(/(\r\n|\n|\r)/gm, ''),
				step_description: this.shapeData.description,
				process: this.diagramMetaData.name,
				category:
					(this.diagramParent as any).path ||
					((this.diagramParent as any)?.levels2 || []).map((l2: any) => l2.path || '').join(', ') ||
					'',
				ai_enablers: this.shapeDataTechnologies
					.filter((t) => this.shapeData?.technologiesIds.includes(t.id as string))
					.map((t) => t.name)
					.join(', '),
			};

			await this.simulationService.getEnterpriseContext();

			this.http
				.post<IOpportunity[]>(environment.url + '/api/ai/opportunity/generation/', {
					steps: [stepData],
					enterpriseContext: this.simulationService.enterpriseContext?.enterpriseContext || {},
				})
				.subscribe((data) => {
					if (this.shapeData && !this.hasCancelGenerateAIOpportunities) {
						// this.currentAIOpportunities.forEach((o) => this._deleteOpportunity(o));

						const aiOpportunities: IOpportunity[] = data.map((d) => ({
							id: uuid(),
							name: (d as any).title,
							description: d.description,
							valid: false,
							data: {
								assumptionsPocValidation: (d as any)['Assumptions for PoC Validation'],
								integrationRequirement: (d as any)['Integration Requirement'],
								knowledgeData: this.getKnowledgeData((d as any)['Knowledge Data']),
								complexity: (() => {
									const complexity = (d as any)['PoC Complexity'];
									if (complexity === 'Low') {
										return 1;
									}
									if (complexity === 'Medium') {
										return 2;
									}
									if (complexity === 'High') {
										return 3;
									}
									return 1;
								})(),
							},
							shape_id: this.shapeDataId,
							technologies: (d as any)['AI_Enabler'],
						}));

						aiOpportunities.forEach((o) => {
							this.currentAIOpportunities = [o, ...this.currentAIOpportunities];
							this.aiOpportunities = [o, ...this.aiOpportunities];
							this.editedOpportunity(o);
						});

						if (!this.shapeData.technologiesIds?.length) {
							this.shapeData.technologiesIds = this.shapeDataTechnologies.map((t) => t.id as string);
						}

						this.generateAIOpportunitiesLoading = false;
					}
				});
		}
	}

	getKnowledgeData(knowledgeData: string = '') {
		return knowledgeData
			.replace(/(\r\n|\n|\r)/gm, '')
			.replace('.', '')
			.split(',')
			.map((d) => d.trim())
			.map((d) => d.charAt(0).toUpperCase() + d.slice(1));
	}

	get currentKnowledgeData() {
		return this.currentEditOpportunity?.data?.knowledgeData || [];
	}

	trackByIndex(index: number, item: any): number {
		return index;
	}

	removeKnowledgeData(i: number) {
		if (this.currentEditOpportunity?.data?.knowledgeData) {
			this.currentEditOpportunity.data.knowledgeData.splice(i, 1);
		}
	}

	get hasAddKnowledgeData() {
		return this.currentEditOpportunity?.data?.knowledgeData?.length
			? !!this.currentEditOpportunity.data.knowledgeData[
					this.currentEditOpportunity.data.knowledgeData.length - 1
			  ]
			: false;
	}

	addKnowledgeData() {
		if (this.currentEditOpportunity) {
			if (this.currentEditOpportunity?.data?.knowledgeData?.length) {
				if (this.hasAddKnowledgeData) {
					this.currentEditOpportunity.data.knowledgeData.push('');
				}
			} else {
				this.currentEditOpportunity.data.knowledgeData = [''];
			}
		}
	}

	hasCancelGenerateAIOpportunities: boolean = false;

	cancelGenerateAIOpportunities() {
		this.hasCancelGenerateAIOpportunities = true;
		this.generateAIOpportunitiesLoading = false;
	}
}
