import { ChangeDetectorRef, Injectable } from '@angular/core';
import { DataUpdateDiagram } from '../components/diagram/diagram.types';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DiagramComponent } from '../components/diagram/diagram.component';
import { AuthService } from './auth.service';
import { DbService } from './db.service';
import { ChartType, TooltipItem } from 'chart.js';
import { CategoryQuestion, Question } from '../api/solution.api';
import { ConfirmationService, ConfirmEventType, MenuItem, Message, MessageService, TreeNode } from 'primeng/api';
import { SolutionService } from './solution.service';
import { E2ETaxonomyService } from './e2e-taxonomy.service';
import { Diagram, IBenefitData, IDiagram, IUseCase, UseCase } from '../api/use-case.api';
import { UseCaseService } from './use-case.service';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { Report } from '../components/use-cases/report';
import { CommonService } from './common.service';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { v4 as uuid } from 'uuid';
import { createdAt } from './services.data/utils.data';
import { HttpClient } from '@angular/common/http';
import { BenefitsAssessmentComponent } from '../components/use-cases/benefits/benefits-assessment';
import { evaluate } from 'mathjs';
import { UseCaseReadinessComponent } from '../components/use-cases/readiness/stepper/use-case-readiness.component';
import { Technology } from '../api/e2e-taxonomy.api';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UseCaseUIService {
	constructor(
		private http: HttpClient,
		private dialogService: DialogService,
		private commonService: CommonService,
		private dbService: DbService,
		private authService: AuthService,
		private solutionService: SolutionService,
		private e2ETaxonomyService: E2ETaxonomyService,
		private useCaseService: UseCaseService,
	) {}

	messageService: MessageService;
	confirmationService: ConfirmationService;
	cdr: ChangeDetectorRef;
	searchTerm: string = '';
	searchTermBenefits: string = '';

	// Load
	private _onLoad: boolean = false;
	loading: boolean = true;
	loading_data: boolean = true;
	ready_chart = false;

	// UI
	selectionView: number = 0;

	selectionViewOptions: { value: number; label: string; icon: string }[] = [
		{
			value: 0,
			label: 'Portfolio',
			icon: 'pi-bolt',
		},
		{
			value: 1,
			label: 'Use Cases',
			icon: 'pi-folder-open',
		},
		{
			value: 2,
			label: 'Benefits',
			icon: 'pi-chart-line',
		},
		{
			value: 3,
			label: 'Diagrams',
			icon: 'pi-sitemap',
		},
	];

	activatedMenu = false;

	itemsMenu: MenuItem[] = [
		{
			label: 'Add My Use Case',
			icon: 'pi pi-plus-circle',
			command: () => {
				this.newUseCase();
			},
		},
		// {
		// 	label: 'Report',
		// 	icon: 'pi pi-file-export',
		// 	command: () => {
		// 		this.selectionView = 0;
		// 		this.reportWait = true;
		// 		this.reportUseCases = this.dataHeatmap.map((u) => u.id || '');
		// 	},
		// },
		// {
		// 	label: 'Export Use Cases',
		// 	icon: 'pi pi-download',
		// 	command: () => {
		// 		this.selectionView = 1;
		// 		// this.layoutDataView = 'list';
		// 		this.exportWait = true;
		// 		this.exportUseCasesIds = this.data.map((u) => u.id || '');
		// 		this.exportUseCaseAll = true;
		// 	},
		// },
		// {
		// 	label: 'Import Use Cases',
		// 	icon: 'pi pi-upload',
		// 	command: () => {
		// 		this.visible_import_use_cases = true;
		// 	},
		// },
	];

	categoriesQuestion: CategoryQuestion[] = [];
	treeSelectCategoryFunction: TreeNode[] = [];
	questions: Question[] = [];
	taxonomy: TreeNode[] = [];
	taxonomySelected: TreeNode[] = [];
	technologies: TreeNode[] = [];
	technologiesSelected: TreeNode[] = [];
	impacts: TreeNode[] = [
		{ label: 'Low', key: '1' },
		{ label: 'Medium', key: '2' },
		{ label: 'High', key: '3' },
	];
	impactsSelected: TreeNode[] = [];
	efforts: TreeNode[] = [
		{ label: '0-3 m', key: '1' },
		{ label: '3-12 m', key: '2' },
		{ label: '+12 m', key: '3' },
	];
	effortsSelected: TreeNode[] = [];

	baseData: UseCase[] = [];
	filterBaseData: UseCase[] = [];
	data: UseCase[] = [];
	dataHeatmap: UseCase[] = [];
	benefitsDataHeatmapA: { label: string; value_a: string; value_b: string; sup_a: string; sup_b: string }[] = [
		{
			label: 'Revenue Growth',
			value_a: '',
			sup_a: '',
			value_b: '',
			sup_b: '',
		},
		{
			label: 'Savings',
			value_a: '',
			sup_a: '',
			value_b: '',
			sup_b: '',
		},
		{
			label: 'Total Benefits',
			value_a: '',
			sup_a: '',
			value_b: '',
			sup_b: '',
		},
	];

	benefitsDataHeatmapB: { label: string; value_a: string; value_b: string; sup_a: string; sup_b: string }[] = [
		{
			label: 'Process Productivity',
			value_a: '',
			sup_a: '',
			value_b: '',
			sup_b: '',
		},
		{
			label: 'Employee Productivity',
			value_a: '',
			sup_a: '',
			value_b: '',
			sup_b: '',
		},
		{
			label: 'Customer Experience',
			value_a: '',
			sup_a: '',
			value_b: '',
			sup_b: '',
		},
	];

	chartData: any;
	chartOptions: any;

	// Benefits

	can_edit_benefits: boolean = false;
	benefitsDisplayModal: boolean = false;
	benefitsDisplayCreateModal: boolean = false;
	benefitsUnsavedChanges: boolean = false;
	benefitsShowNonFinancial: boolean = false;
	displaySoftBenefits: boolean = true;

	benefitsSelectedUseCase: UseCase | undefined = undefined;
	benefitsModalTitle: string = '';
	benefitsNewName: string = '';

	benefitsOptionsSymbol = [
		{
			label: '%',
			data: '%',
		},
		{
			label: '$',
			data: '$',
		},
		{
			label: '#',
			data: '#',
		},
	];

	benefitsSelectedType: string | null = null;
	benefitsName = '';
	skeletonArray: number[] = Array(10).fill(0);

	async onLoad() {
		if (!this._onLoad) {
			await this.onLoadData();

			this.authService.organizationSource$.subscribe(() => {
				setTimeout(() => {
					this.prepareData().then(() => {
						this.getData();
					});
				}, 150);
			});

			this.dbService.dbOrganizationUpdateSource$.subscribe(() => {
				setTimeout(() => {
					this.prepareData().then(() => {
						this.getData();
					});
				}, 150);
			});
		}
	}

	public async onLoadData() {
		this.loading = false;

		await this.prepareData();

		await this.getData();

		this.loading = false;
	}

	public async reloadData() {
		this.baseData = await this.useCaseService.getUseCases();

		await this.getData();

		this._filteredDataCache = [];
	}

	async prepareData() {
		this.categoriesQuestion = await this.solutionService.getCategoriesQuestion();
		this.treeSelectCategoryFunction = await this.e2ETaxonomyService.getCategoriesFunction(
			{ extend: 'function.level1' },
			true,
		);
		this.questions = await this.solutionService.getQuestions();
		this.taxonomy = await this.e2ETaxonomyService.getCategoriesFunction({ extend: 'function' }, true);
		this.taxonomy.forEach((t) => {
			t.selectable = true;
			t.children?.forEach((f) => {
				f.selectable = true;
				f.children?.map((l1) => {
					l1.selectable = true;
				});
			});
		});

		this.technologies = await this.e2ETaxonomyService.getTechnologies({}, true);

		this.baseData = await this.useCaseService.getUseCases();
	}

	async getData() {
		this.loading_data = true;
		this.data = await this.useCaseService.getUseCases({}, this.baseData);

		this.data = this.data.sort((a, b) => a.benefitsOrder.benefits - b.benefitsOrder.benefits);

		this.loadDataHeatmap();
		// console.log('this.dataHeatmap', this.dataHeatmap);

		this.loading_data = false;

		await this.prepareChart();
	}

	async prepareChart() {
		this.ready_chart = false;

		const datasets = [
			{
				label: 'Empty',
				data: [
					{
						x: 0,
						y: 0,
						r: 0,
					},
				],
				backgroundColor: '#C8D3EB',
				borderColor: '#fff',
				meta: '',
				rowIndex: 0,
			},
			{
				label: 'Empty',
				data: [
					{
						x: 100,
						y: 100,
						r: 0,
					},
				],
				backgroundColor: '#C8D3EB',
				borderColor: '#fff',
				meta: '',
				rowIndex: 0,
			},
		];

		//console.log(this.dataHeatmap);
		const bValues = this.dataHeatmap.map((useCase) => useCase.calculatedData.matrixBenefits.value);
		let bMin = Math.min(...bValues);
		let bMax = Math.max(...bValues);
		let getRelativeBenefits: (num: number) => number = (num: number) => num;
		if (bMax > bMin) {
			const bM = (bMax - bMin) * 0.05;
			bMin = bMin - bM;
			bMax = bMax + bM;

			getRelativeBenefits = (num: number) => {
				return Math.min(99.99, Math.max(0.01, ((num - bMin) / (bMax - bMin)) * 100));
			};
		} else {
			getRelativeBenefits = (num: number) => 50;
		}

		this.dataHeatmap.forEach((useCase, i) => {
			datasets.push({
				label: useCase.name,
				data: [
					{
						x: useCase.calculatedData.matrixReadiness.value,
						y: getRelativeBenefits(useCase.calculatedData.matrixBenefits.value),
						r: 20,
					},
				],
				backgroundColor:
					{ Breakthrough: '#1532b8', Transformative: '#1b54f8', Incremental: '#569bff' }[
						useCase.calculatedData.impact
					] || '#9c9898',
				borderColor: '#fff',
				meta: useCase?.id || '',
				rowIndex: i + 1,
			});
		});

		this.chartData = {
			datasets,
		};

		this.chartOptions = {
			responsive: true,
			maintainAspectRatio: true,
			aspectRatio: 1,
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					displayColors: false,
					callbacks: {
						label: (tooltipItem: TooltipItem<ChartType>) => {
							// this.rowActiveIndex = (tooltipItem.dataset as any).rowIndex;
							return tooltipItem.label;
						},
					},
				},
				datalabels: {
					color: '#FFF',
					formatter: function (value: any, context: any) {
						return context.dataset.rowIndex || '';
					},
				},
			},
			scales: {
				xAxes: {
					title: {
						display: true,
						text: 'Feasibility',
						color: '#1b54f8',
					},
					ticks: {
						display: true,
						maxTicksLimit: 3,
						callback: (val: any) => {
							if (val == 0) {
								return 'LOW';
							} else if (val === 100) {
								return 'HIGH';
							} else {
								return '';
							}
						},
					},
				},
				yAxes: {
					title: {
						display: true,
						text: 'Benefits',
						color: '#1b54f8',
					},
					type: 'logarithmic',
					ticks: {
						display: true,
						maxTicksLimit: 3,
						callback: (val: any) => {
							if (val == 0) {
								return '';
							} else if (val === 100) {
								return 'HIGH';
							} else {
								return '';
							}
						},
					},
				},
			},
			onClick: (
				event: any,
				elements: { datasetIndex: number }[],
				chart: {
					data: { datasets: { meta: string }[] };
				},
			) => {
				if (elements[0]) {
					this.readinessModal(
						chart.data.datasets[elements[0].datasetIndex].meta,
						this.categoriesQuestion.length + 1,
					);
				}
			},
		};

		this.ready_chart = true;
	}

	hasModalDiagram: boolean = false;

	updateDiagramModal(data: DataUpdateDiagram): DynamicDialogRef | undefined {
		if (!this.hasModalDiagram) {
			document.body.style.cursor = 'wait';
			this.hasModalDiagram = true;
			const ref: DynamicDialogRef = this.dialogService.open(DiagramComponent, {
				data,
				header: 'Diagram',
				width: '100%',
				height: '100%',
				styleClass: 'diagram-modal',
				contentStyle: { overflow: 'auto' },
				showHeader: false,
				baseZIndex: 100,
				maximizable: false,
				closeOnEscape: false,
			});
			setTimeout(() => {
				document.body.style.cursor = 'default';
			}, 150);
			ref.onClose.subscribe(() => {
				this.hasModalDiagram = false;
			});
			ref.onDestroy.subscribe(() => {
				this.hasModalDiagram = false;
			});
			return ref;
		}
		return undefined;
	}

	currentUseCaseId: string = '';

	async readinessModal(useCaseId: string, current_index: number = 0) {
		await this.useCaseService.clearQueryUseCase();
		this.currentUseCaseId = '';
		const ref = this.dialogService.open(UseCaseReadinessComponent, {
			data: { useCaseId, current_index },
			header: 'Use Case Readiness',
			width: '95%',
			height: '90%',
			styleClass: 'use-case-readiness-modal',
			contentStyle: { overflow: 'auto' },
			showHeader: false,
			baseZIndex: 100,
			maximizable: true,
		});
		ref.onClose.subscribe(() => {
			this.changeSelection(true);
		});
		return ref;
	}

	deleteUseCaseModal(id: string) {
		this.confirmationService.confirm({
			message: 'Do you want to delete this Use Case?',
			header: 'Delete Confirmation',
			icon: 'pi pi-info-circle',
			accept: () => {
				this.data = [];
				this.useCaseService.deleteUseCase(id).then(async () => {
					await this.reloadData();
					this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Use Case deleted' });
				});
			},
			reject: (type: ConfirmEventType) => {
				switch (type) {
					case ConfirmEventType.REJECT:
						this.messageService.add({
							severity: 'error',
							summary: 'Rejected',
							detail: 'You have rejected',
						});
						break;
					case ConfirmEventType.CANCEL:
						this.messageService.add({
							severity: 'warn',
							summary: 'Cancelled',
							detail: 'You have cancelled',
						});
						break;
				}
			},
		});
	}

	async changeSelection(reload: boolean = false) {
		this.loading = true;
		this.loading_data = true;
		const technologiesSelected = this.technologiesSelected.map((t) => t.key);
		const vs = this.impactsSelected.map((t) => t.key);
		const impactsSelected = [
			{ key: '1', min: 0, max: 10 },
			{ key: '2', min: 10, max: 30 },
			{
				key: '3',
				min: 30,
				max: 100,
			},
		].filter((vi) => vs.includes(vi.key));

		const ve = this.effortsSelected.map((t) => t.key);
		const effortsSelected = [
			{ key: '1', min: 0, max: 10 },
			{ key: '2', min: 10, max: 50 },
			{
				key: '3',
				min: 50,
				max: 100,
			},
		].filter((vi) => ve.includes(vi.key));

		let functions: string[] = this.taxonomySelected.filter((t) => !t.children?.length).map((t) => t.key as string);

		this.taxonomySelected.forEach((t) => {
			if (t.children && t.children.length) {
				functions.push(...t.children.map((td) => td.key as string));
			}
		});

		functions = Array.from(new Set(functions));

		this.filterBaseData = this.baseData.filter((u) => {
			let valid_1: boolean;
			if (functions.length) {
				valid_1 = !!u.levels2.filter((l2) => functions.includes(l2.level1.functionId)).length;
			} else {
				valid_1 = true;
			}
			let valid_2: boolean;
			if (technologiesSelected.length) {
				valid_2 = !!u.getTechnologiesIds().filter((t) => technologiesSelected.includes(t)).length;
			} else {
				valid_2 = true;
			}
			let valid_3: boolean;
			if (impactsSelected.length) {
				valid_3 = !!impactsSelected.filter(
					(vi) =>
						u.calculatedData.matrixReadiness.value > vi.min &&
						u.calculatedData.matrixReadiness.value <= vi.max,
				).length;
			} else {
				valid_3 = true;
			}
			let valid_4: boolean;
			if (effortsSelected.length) {
				valid_4 = !!effortsSelected.filter(
					(vi) => u.calculatedData.matrixValue.value > vi.min && u.calculatedData.matrixValue.value <= vi.max,
				).length;
			} else {
				valid_4 = true;
			}
			return valid_1 && valid_2 && valid_3 && valid_4;
		});

		if (reload) {
			this.data = await this.useCaseService.getUseCases({}, this.filterBaseData);
		} else {
			this.data = [...this.filterBaseData];
		}

		this.loadDataHeatmap();

		this.loading = false;
		this.loading_data = false;

		await this.prepareChart();
	}

	loadDataHeatmap() {
		this.data.forEach((u) => {
			u.categories = this.categoriesQuestion;
		});

		this.dataHeatmap = this.data.filter(
			(u) => u.calculatedData.matrixReadiness.value && u.calculatedData.matrixBenefits.value !== 0,
		);

		this.loadBenefitsDataHeatmap();

		if (this.dataHeatmap.length) {
			this.dataHeatmap = this.dataHeatmap.sort((a, b) => a.benefitsOrder.heatmap - b.benefitsOrder.heatmap);
		}
	}

	// Global Results
	loadBenefitsDataHeatmap() {
		this.calculateFinancials();
		this.calculateNonFinancials();
		// Global Results Up (Financial Benefits)
		this.benefitsDataHeatmapA = [
			{
				label: 'Revenue Growth', // Global
				value_a: '',
				sup_a: '',
				value_b: '$' + this.formatWithSuffix(this.revenueGrowthValue),
				sup_b: '',
			},
			{
				label: 'Cost Reduction', // Global
				value_a: '',
				sup_a: '',
				value_b: this.formatWithSuffix(Math.abs(this.savingsValue + this.costReductionValue), true),
				sup_b: '',
			},
			{
				label: 'Total Benefits', // Global
				value_a: '',
				sup_a: '',
				value_b: this.formatWithSuffix(Math.abs(this.revenueGrowthValue) + Math.abs(this.savingsValue), true),
				sup_b: '',
			},
		];

		// Global Results Down (Soft Benefits*)
		this.benefitsDataHeatmapB = [
			{
				label: 'Working Capital', // Global
				value_a: '',
				sup_a: '',
				value_b: this.formatWithSuffix(Math.abs(this.workingCapitalValue), true),
				sup_b: '',
			},
			{
				label: 'Employee Capacity', // Global
				value_a: '',
				sup_a: '',
				value_b: this.formatWithSuffix(this.EmployeeCapacityNonFinancialValue) + ' hrs',
				sup_b: '',
			},
			{
				label: 'Customer Experience', // Global
				value_a: '',
				sup_a: '',
				value_b:
					this.formatWithSuffix(
						this.customerExperienceNonFinancialPercent === -Infinity
							? 0
							: this.customerExperienceNonFinancialPercent,
					) + '%',
				sup_b: '',
			},
		];

		this.originalBenefitsDataHeatmapA = JSON.parse(JSON.stringify(this.benefitsDataHeatmapA));
		this.originalBenefitsDataHeatmapB = JSON.parse(JSON.stringify(this.benefitsDataHeatmapB));
	}

	async loadUseCaseDiagram(id: string, back: boolean) {
		await this.loadUseCase(id, async () => {
			if (this.useCaseService.currentUseCase && this.useCaseService.currentUseCase.diagram?.id) {
				const ref = this.updateDiagramModal({
					diagramId: this.useCaseService.currentUseCase.diagram.id,
					back,
				});
				if (ref && back) {
					ref.onClose.subscribe(async () => {
						await this.loadUseCase(id, async () => {
							await this.readinessModal(id, 0);
						});
					});
				}
			}
		});
	}

	async loadUseCaseAssessment(id: string) {
		await this.loadUseCase(id, async () => {
			await this.readinessModal(id);
		});
	}

	loadedUseCase: Subject<string> = new Subject();
	loadedUseCase$: Observable<string> = this.loadedUseCase.asObservable();

	async loadUseCase(id: string, callback: () => Promise<void>) {
		await this.useCaseService.setCurrentUseCase(id);
		if (this.useCaseService.currentUseCase) {
			callback().then(() => {
				this.currentUseCaseId = id;
			});
			this.loadedUseCase.next(id);
		} else {
			await this.useCaseService.goToUseCases();
		}
	}

	async editDiagram(useCase: UseCase) {
		await this.useCaseService.clearQueryUseCase();
		this.useCaseService.currentUseCase = useCase;
		this.currentUseCaseId = '';
		if (useCase?.diagram?.id) {
			const ref = this.updateDiagramModal({ diagramId: useCase.diagram.id });

			if (ref) {
				ref.onClose.subscribe(() => {
					this.changeSelection(true);
				});
			}
		}
	}

	changeHubbleUseCaseModal(useCase: UseCase) {
		useCase.hubble = !useCase.hubble;

		this.data = this.data.map((d) => (d.id === useCase.id ? useCase : d));

		this.useCaseService
			.saveUseCase(useCase)
			.then(() => {
				this.messageService.add({
					severity: 'info',
					summary: 'Confirmed',
					detail: `Use Case ${useCase.hubble ? 'selected to' : 'deselected from'} Hubble Design`,
				});
			})
			.catch(() => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: `An error occurred while updating the Use Case status to ${
						useCase.hubble ? 'select' : 'deselect'
					} from Hubble Design`,
				});
			});
	}

	changeBenefitsUseCaseModal(useCase: UseCase) {
		useCase.benefits = !useCase.benefits;

		this.data = this.data.map((d) => (d.id === useCase.id ? useCase : d));

		this.useCaseService
			.saveUseCase(useCase)
			.then(() => {
				this.messageService.add({
					severity: 'info',
					summary: 'Confirmed',
					detail: `Use Case ${useCase.benefits ? 'selected to' : 'deselected from'} Benefits`,
				});
			})
			.catch(() => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: `An error occurred while updating the Use Case status to ${
						useCase.benefits ? 'select' : 'deselect'
					} from Benefits`,
				});
			});
	}

	sleep(ms: number) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	loadingReport: boolean = false;
	reportWait = false;
	reportUseCases: string[] = [];

	cancelReport() {
		this.loadingReport = false;
		this.reportWait = false;
		this.reportUseCases = [];
	}

	async exportReport() {
		this.loadingReport = true;
		await this.sleep(100);
		const organization = await this.authService.getCurrentOrganization();

		let account = '';

		if (organization) {
			account = organization.name;
		}

		await new Report({
			useCases: this.dataHeatmap.filter((u) => this.reportUseCases.includes(u.id || '')),
			questions: this.questions,
			account,
			user: this.authService.profile,
			useCaseService: this.useCaseService,
			commonService: this.commonService,
		}).create();
		this.loadingReport = false;
		this.reportWait = false;
		this.reportUseCases = [];
	}

	exportWait = false;
	exportUseCaseAll: boolean | null;
	exportUseCasesIds: string[] = [];

	toggleExportUseCaseAll(evt: any) {
		if (evt.value) {
			this.exportUseCasesIds = this.data.map((u) => u.id || '');
		} else if (evt.value === null) {
			this.exportUseCasesIds = [];
		} else {
			this.updateExportUseCasesIds();
		}
	}

	updateExportUseCasesIds() {
		if (this.exportUseCasesIds.length) {
			this.exportUseCaseAll = this.exportUseCasesIds.length === this.data.length;
		} else {
			this.exportUseCaseAll = null;
		}
	}

	loadingExport: boolean = false;
	loadingImport: boolean = false;
	importable_file: File | undefined = undefined;

	async exportUseCases() {
		this.loadingExport = true;
		const zip = new JSZip();

		const useCases = (await this.useCaseService.getUseCases()).filter(
			(u) => u.id && this.exportUseCasesIds.includes(u.id),
		);

		for (const useCase of useCases) {
			if (useCase.id) {
				const useCaseFolder = zip.folder(useCase.id);
				if (useCaseFolder) {
					useCaseFolder.file(
						'data.json',
						JSON.stringify({
							id: useCase.id,
							created_at: useCase.created_at,
							updated_at: useCase.updated_at,
							name: useCase.name,
							description: useCase.description,
							aiValues: useCase.aiValues,
							organizationId: useCase.organizationId,
							formData: useCase.formData,
							workPlanData: useCase.workPlanData,
							generalData: useCase.generalData,
							levels2Ids: useCase.levels2Ids,
							technologiesIds: useCase.technologiesIds,
							solutionId: useCase.solutionId,
						}),
					);

					if (useCase.diagram) {
						const diagram = useCase.diagram;
						if (diagram.xmlData && diagram.xmlImage) {
							const diagramFolder = useCaseFolder.folder('diagram');

							if (diagramFolder) {
								diagramFolder.file(
									'data.xml',
									await firstValueFrom(this.http.get(diagram.xmlData, { responseType: 'text' })),
								);

								diagramFolder.file(
									'data.svg',
									await firstValueFrom(this.http.get(diagram.xmlImage, { responseType: 'text' })),
								);

								diagramFolder.file('data.json', JSON.stringify(diagram.data));
							}
						}
					}
				}
			}
		}

		zip.generateAsync({ type: 'blob' })
			.then((content) => {
				saveAs(content, 'my_use_cases.zip');
			})
			.finally(() => {
				this.cancelExportUseCases();
			});
	}

	cancelExportUseCases() {
		this.loadingExport = false;
		this.exportWait = false;
		this.exportUseCasesIds = [];
	}

	onUploadZipUseCases(evt: { files: File[] }) {
		if (evt.files.length) {
			this.importable_file = evt.files[0];
		}
	}

	visible_import_use_cases: boolean = false;

	async importUseCases() {
		if (this.importable_file) {
			const reader = new FileReader();
			const organization = await this.authService.getCurrentOrganization();

			reader.onload = () => {
				this.visible_import_use_cases = false;
				this.loadingImport = true;
				JSZip.loadAsync(reader.result as ArrayBuffer)
					.then(async (zip) => {
						const useCasesDirPath: string[] = [];

						zip.forEach((path, file) => {
							if (file.dir && !path.includes('diagram')) {
								useCasesDirPath.push(path.replace('/', ''));
							}
						});

						for (const useCaseDirPath of useCasesDirPath) {
							const useCaseDir = zip.folder(useCaseDirPath);
							if (useCaseDir) {
								const useCaseFile = useCaseDir.file('data.json');

								if (useCaseFile) {
									const useCase = await useCaseFile.async('text').then((jsonContent) => {
										return plainToInstance(UseCase, JSON.parse(jsonContent) as IUseCase);
									});

									useCase.id = uuid();
									useCase.created_at = createdAt();
									useCase.updated_at = useCase.created_at;
									useCase.organization = organization as any;
									useCase.organizationId = (organization as any).id;

									await this.useCaseService.saveUseCase(useCase);

									const diagramFolder = useCaseDir.folder('diagram');
									if (diagramFolder) {
										const diagramXML = diagramFolder.file('data.xml');
										const diagramSVG = diagramFolder.file('data.svg');
										const diagramJSON = diagramFolder.file('data.json');

										let xml: string = '';
										if (diagramXML) {
											xml = await diagramXML.async('text');
										}

										let svg: string = '';
										if (diagramSVG) {
											svg = await diagramSVG.async('text');
										}

										let dataJson: any | undefined = undefined;
										if (diagramJSON) {
											dataJson = await diagramJSON.async('text').then((content) => {
												return JSON.parse(content);
											});
										}

										const diagram = plainToInstance<Diagram, IDiagram>(Diagram, {
											useCaseId: useCase.id,
											organizationId: (organization as any).id,
											xmlData: '',
											xmlImage: '',
											data: dataJson,
										} as IDiagram);

										diagram.id = uuid();
										diagram.created_at = createdAt();
										diagram.updated_at = diagram.created_at;
										diagram.organization = organization as any;

										await this.useCaseService.saveFilesDiagram(diagram, xml, svg).then(() => {
											this.useCaseService.saveDiagram(diagram);
										});
									}
								}
							}
						}
					})
					.finally(() => {
						setTimeout(() => {
							this.loadingImport = false;
							this.importable_file = undefined;
							this.prepareData().then(() => {
								this.getData().then(() => {});
							});
						}, 150);
					});
			};
			reader.readAsArrayBuffer(this.importable_file);
		}
	}

	onErrorImage(useCase: UseCase) {
		if (useCase.diagram) {
			this.http.get<IDiagram>('@api/' + this.dbService.get_urls_hook('diagram') + useCase.diagram.id).subscribe({
				next: (d) => {
					if (d) {
						const diagram = plainToInstance(Diagram, d);
						this.dbService.data_diagram.put(diagram).then(() => {
							useCase.diagram = diagram;
						});
					}
				},
			});
		}
	}

	// Benefits

	deleteBenefit(benefit: any) {
		if (!this.benefitsSelectedUseCase || !this.benefitsSelectedUseCase.benefitsValues) {
			return;
		}

		const selectedCategory = this.benefitsName as keyof IBenefitData;

		switch (selectedCategory) {
			case 'revenue_growth':
				this.deleteFromCategory(this.benefitsSelectedUseCase.benefitsValues.revenue_growth, benefit);
				break;
			case 'working_capital':
				this.deleteFromCategory(this.benefitsSelectedUseCase.benefitsValues.working_capital, benefit);
				break;
			case 'customer_experience':
				this.deleteFromCategory(this.benefitsSelectedUseCase.benefitsValues.customer_experience, benefit);
				break;
			case 'process_productivity':
				this.deleteFromCategory(this.benefitsSelectedUseCase.benefitsValues.process_productivity, benefit);
				break;
			case 'employee_productivity':
				this.deleteFromCategory(this.benefitsSelectedUseCase.benefitsValues.employee_productivity, benefit);
				break;
			case 'cost_savings':
				this.deleteFromCategory(this.benefitsSelectedUseCase.benefitsValues.cost_savings, benefit);
				break;
			default:
				console.error('Unknown benefit type selected');
				break;
		}
		this.benefitsSelectedUseCase.benefitsValues = { ...this.benefitsSelectedUseCase.benefitsValues };

		this.onLoadData();
	}

	originalBenefitsValues: any;

	async closeBenefitsModal(useCase: UseCase) {
		if (this.hasUnsavedChanges()) {
			this.confirmationService.confirm({
				message: 'You have unsaved changes. Close without saving?',
				header: 'Unsaved Changes',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.benefitsSelectedUseCase!.benefitsValues = JSON.parse(
						JSON.stringify(this.originalBenefitsValues),
					);
					this.benefitsDisplayModal = false;
					if (this.socket) {
						this.socket.close();
						this.messages = [];
					}
					this.messageService.add({ severity: 'info', summary: 'Closed', detail: 'Closed without saving' });
				},
				reject: (type: ConfirmEventType) => {
					this.benefitsDisplayModal = true;
					switch (type) {
						case ConfirmEventType.REJECT:
							this.messageService.add({
								severity: 'info',
								summary: 'Cancelled',
								detail: 'Continue editing',
							});
							break;
					}
				},
			});
		} else {
			this.benefitsDisplayModal = false;
			if (this.socket) {
				this.socket.close();
				this.messages = [];
			}
		}

		await this.getData();
	}

	deleteFromCategory(categoryArray: any[], benefit: any) {
		const index = categoryArray.indexOf(benefit);
		if (index !== -1) {
			categoryArray.splice(index, 1);
		}
	}

	private _filteredDataCache: UseCase[] = [];
	private _filteredDataBenefitsCache: UseCase[] = [];
	private previousSearchTerm: string = '';
	private previousSearchTermBenefits: string = '';
	public noResultsCache: boolean = false;
	public noResultsBenefitsCache: boolean = false;
	private _lastFilteredDataLength: number | null = null;
	private _lastFilteredBenefitsDataLength: number | null = null;

	private hasSearchTermChanged(): boolean {
		if (this.searchTerm !== this.previousSearchTerm) {
			this.previousSearchTerm = this.searchTerm;
			return true;
		}
		return false;
	}

	private hasSearchTermBenefitsChanged(): boolean {
		if (this.searchTermBenefits !== this.previousSearchTermBenefits) {
			this.previousSearchTermBenefits = this.searchTermBenefits;
			return true;
		}
		return false;
	}

	private filterData(
		searchTerm: string,
		data: UseCase[],
		cache: UseCase[],
		noResultsFlag: boolean,
		lastFilteredLength: number | null,
	): UseCase[] {
		if (!searchTerm || searchTerm.trim() === '') return data;

		const filteredResults = data.filter((useCase) =>
			useCase.name.toLowerCase().includes(searchTerm.trim().toLowerCase()),
		);

		if (filteredResults.length !== lastFilteredLength) {
			if (filteredResults.length === 0) {
				noResultsFlag = true;
				cache = [];
			} else {
				noResultsFlag = false;
				cache = filteredResults;
			}
		}

		return noResultsFlag ? [] : cache;
	}

	get filteredData(): UseCase[] {
		if (this.hasSearchTermChanged() || !this._filteredDataCache.length || this.noResultsCache) {
			this._filteredDataCache = this.filterData(
				this.searchTerm,
				this.data,
				this._filteredDataCache,
				this.noResultsCache,
				this._lastFilteredDataLength,
			);
			this._lastFilteredDataLength = this._filteredDataCache.length;
		}
		return this._filteredDataCache;
	}

	get filteredDataBenefits(): UseCase[] {
		if (
			this.hasSearchTermBenefitsChanged() ||
			!this._filteredDataBenefitsCache.length ||
			this.noResultsBenefitsCache
		) {
			this._filteredDataBenefitsCache = this.filterData(
				this.searchTermBenefits,
				this.data,
				this._filteredDataBenefitsCache,
				this.noResultsBenefitsCache,
				this._lastFilteredBenefitsDataLength,
			);
			this._lastFilteredBenefitsDataLength = this._filteredDataBenefitsCache.length;
		}
		return this._filteredDataBenefitsCache;
	}

	loadUseCaseBenefits(useCase: UseCase) {
		if (!useCase.benefitsOrder || Object.keys(useCase.benefitsOrder).length === 0) {
			useCase.benefitsOrder = { heatmap: 0, hubble: 0, benefits: 0, my_use_case: 0 };
		}
		if (useCase.benefitsOrder.heatmap === undefined) {
			useCase.benefitsOrder.heatmap = 0;
		}

		if (useCase.benefitsValues === undefined || Object.keys(useCase.benefitsValues).length === 0) {
			useCase.benefitsValues = {
				revenue_growth: [
					useCase.getBenefitEmpty(
						'Revenue',
						'A straightforward view of Growth, the Revenue measure is the percentage change in revenue from one year to the next.',
						'(Revenue this year minus revenue last year / Revenue last year) * 100%',
						'targetValue - currentBaseline',
						'Dollars',
						'$',
						true,
					),
					useCase.getBenefitEmpty(
						'Price',
						"Evaluates how a company's revenue growth compares to the overall market, indicating competitive strength and market position.",
						"(Company's Revenue / Total Market Revenue) x 100%",
						'((targetValue / currentBaseline) - 1) * 100',
						'Percentage',
						'%',
						true,
					),
					useCase.getBenefitEmpty(
						'Volume',
						'Provides a smoothed annual growth rate over a 3-year period, eliminating the volatility of year-to-year fluctuations.',
						'(Ending Revenue / Beginning Revenue)^(1/n) - 1',
						'((targetValue / currentBaseline) - 1) * 100',
						'Percentage',
						'%',
						true,
					),
				],
				working_capital: [
					useCase.getBenefitEmpty(
						'Days Sales Outstanding (DSO)',
						'N/A.',
						'N/A.',
						'((targetValue / currentBaseline) - 1) * 100',
						'Days',
						'#',
						true,
					),
					useCase.getBenefitEmpty(
						'Days Payable Outstanding (DPO)',
						'N/A.',
						'N/A.',
						'((targetValue / currentBaseline) - 1) * 100',
						'Days',
						'#',
						true,
					),
					useCase.getBenefitEmpty(
						'Days Inventory Outstanding (DIO)',
						'N/A.',
						'N/A.',
						'((targetValue / currentBaseline) - 1) * 100',
						'Days',
						'#',
						true,
					),
				],
				customer_experience: [
					useCase.getBenefitEmpty(
						'Customer Satisfaction',
						'Assesses overall customer satisfaction with a specific interaction or overall service.',
						'(Number of Satisfied Customers (or higher clients) / Total Customer responses) x 100%',
						'((targetValue / currentBaseline) - 1) * 100',
						'Percentage',
						'%',
						true,
					),
					useCase.getBenefitEmpty(
						'Customer Churn',
						'Indicates the percentage of customers who stop using your product or service over a given period, reflecting customer retention.',
						'(Total Customers Lost divided by Total Customers at Start) x 100%',
						'((targetValue / currentBaseline) - 1) * 100',
						'Percentage',
						'%',
						true,
					),
					useCase.getBenefitEmpty(
						'Customer LTV',
						'Estimates the total revenue a business can expect from a single customer account over its entire relationship.',
						'Average Purchase x Purchase Frequency x Customer Lifespan',
						'targetValue - currentBaseline',
						'Dollars',
						'$',
						true,
					),
					useCase.getBenefitEmpty(
						'Customers',
						'Indicates the number of Customers who sign up for your product or service.',
						'Total Customers - Existing Customers',
						'targetValue - currentBaseline',
						'Units',
						'#',
						true,
					),
				],
				process_productivity: [
					useCase.getBenefitEmpty(
						'Speed (hours)',
						'Assesses the time it takes to complete one cycle of a process from start to finish.',
						'Estimated time in hours from the start of a process to the end of a process',
						'targetValue - currentBaseline',
						'Hours',
						'#',
						true,
					),
					useCase.getBenefitEmpty(
						'Volume',
						'Measures the number of units or tasks completed within a given time frame for the process',
						'Total units produced within an annual time period.',
						'targetValue - currentBaseline',
						'Units',
						'#',
						true,
					),
					useCase.getBenefitEmpty(
						'Accuracy',
						'Also known as first pass yield -  this evaluates the effectiveness of a process by calculating the percentage of products or services that meet quality standards without rework.',
						'(Number of correctly produced units divided by the total units produced) x 100%',
						'((targetValue / currentBaseline) - 1) * 100',
						'Percentage',
						'%',
						true,
					),
				],
				employee_productivity: [
					useCase.getBenefitEmpty(
						'Units per FTE',
						'Evaluates the total output produced by each employee, providing insight into individual productivity levels.',
						'Total output for an annual period divided by total employees.',
						'targetValue - currentBaseline',
						'Units',
						'#',
						true,
					),
					useCase.getBenefitEmpty(
						'Capacity Creation (hours)',
						'Evaluates the total number of hours for one employee to produce one unit of output.',
						'Measure the total hours from start to end to produce a single unit.',
						'targetValue - currentBaseline',
						'Hours',
						'#',
						true,
					),
				],
				cost_savings: [
					useCase.getBenefitEmpty(
						'FTEs',
						'Measures the employee effort (full and part time) to complete a specific task.',
						'One FTE is equivalent to one employee working full-time, commonly defined as 40 hours per week.',
						'targetValue - currentBaseline',
						'Full Time Equivalent',
						'#',
						true,
					),
					useCase.getBenefitEmpty(
						'Cost Reduction',
						'This is the labor and non-labor costs associated with executing this use case for an annual period.  This can include technology, facilities, and other charges.',
						'Total dollar value of expected cost impacted by automating this use case.',
						'targetValue - currentBaseline',
						'Dollars',
						'$',
						true,
					),
					useCase.getBenefitEmpty(
						'Cost per Unit',
						'Tracks the decrease in cost per unit of production or service, highlighting improvements in efficiency.',
						'Total cost to produce the unit divided by the number of units.',
						'targetValue - currentBaseline',
						'Dollars',
						'$',
						true,
					),
				],
			};
		}

		if (useCase.benefitsValues.working_capital === undefined) {
			useCase.benefitsValues.working_capital = [
				useCase.getBenefitEmpty(
					'Days Sales Outstanding (DSO)',
					'N/A.',
					'N/A.',
					'((targetValue / currentBaseline) - 1) * 100',
					'Days',
					'#',
					true,
				),
				useCase.getBenefitEmpty(
					'Days Payable Outstanding (DPO)',
					'N/A.',
					'N/A.',
					'((targetValue / currentBaseline) - 1) * 100',
					'Days',
					'#',
					true,
				),
				useCase.getBenefitEmpty(
					'Days Inventory Outstanding (DIO)',
					'N/A.',
					'N/A.',
					'((targetValue / currentBaseline) - 1) * 100',
					'Days',
					'#',
					true,
				),
			];
		}

		useCase.benefitsValues.customer_experience = useCase.benefitsValues.customer_experience.filter(
			(d) => !['Net Promoter Score', 'New Customers'].includes(d.label),
		);

		useCase.benefitsValues.working_capital = useCase.benefitsValues.working_capital.map((d) => {
			d.measure = 'Days';
			d.symbol = '#';
			return d;
		});

		useCase.benefitsValues.cost_savings = useCase.benefitsValues.cost_savings.filter(
			(d) => !['Cost Reduction % (ROCRI)', 'Labor Cost'].includes(d.label),
		);

		useCase.benefitsValues.process_productivity = useCase.benefitsValues.process_productivity.filter(
			(d) => d.label !== 'OEE',
		);

		if (!useCase.benefitsValues.customer_experience.find((d) => d.label === 'Customers')) {
			useCase.benefitsValues.customer_experience.push(
				useCase.getBenefitEmpty(
					'Customers',
					'Indicates the number of Customers who sign up for your product or service.',
					'Total Customers - Existing Customers',
					'targetValue - currentBaseline',
					'Units',
					'#',
					true,
				),
			);
		}
	}

	openBenefitsModal(useCase: UseCase) {
		this.loadUseCaseBenefits(useCase);

		this.originalBenefitsValues = JSON.parse(JSON.stringify(useCase.benefitsValues));
		this.benefitsSelectedUseCase = useCase;
		this.showCommentaryRowBenefits = {};
		this.benefitsModalTitle = `${useCase.name}`;
		this.benefitsDisplayModal = true;
		this.webSocket(useCase.id as string);
		// console.log('open First modal', useCase)
	}

	hasUnsavedChanges(): boolean {
		return (
			JSON.stringify(this.originalBenefitsValues) !== JSON.stringify(this.benefitsSelectedUseCase?.benefitsValues)
		);
	}

	openCreateBenefitsModal(useCase: UseCase) {
		this.benefitsModalTitle = `${useCase.name}`;
		this.benefitsSelectedUseCase = useCase;
		this.benefitsDisplayCreateModal = true;
		// console.log(useCase);
	}

	onBenefitTypeSelect(node: any) {
		this.benefitsSelectedType = node.value;
		this.benefitsName = node.value;
	}

	addNewBenefit() {
		if (this.benefitsSelectedType && this.benefitsNewName && this.benefitsSelectedUseCase) {
			const selectedCategory = this.benefitsSelectedType as keyof IBenefitData;

			if (!this.benefitsSelectedUseCase.benefitsValues[selectedCategory]) {
				this.benefitsSelectedUseCase.benefitsValues[selectedCategory] = [];
			}

			const benefitExists = (() => {
				switch (this.benefitsName) {
					case 'revenue_growth':
						return this.benefitsSelectedUseCase.benefitsValues['revenue_growth'].some(
							(benefit: any) => benefit.label === this.benefitsNewName,
						);
					case 'working_capital':
						return this.benefitsSelectedUseCase.benefitsValues['customer_experience'].some(
							(benefit: any) => benefit.label === this.benefitsNewName,
						);
					case 'customer_experience':
						return this.benefitsSelectedUseCase.benefitsValues['customer_experience'].some(
							(benefit: any) => benefit.label === this.benefitsNewName,
						);
					case 'process_productivity':
						return this.benefitsSelectedUseCase.benefitsValues['process_productivity'].some(
							(benefit: any) => benefit.label === this.benefitsNewName,
						);
					case 'employee_productivity':
						return this.benefitsSelectedUseCase.benefitsValues['employee_productivity'].some(
							(benefit: any) => benefit.label === this.benefitsNewName,
						);
					case 'cost_savings':
						return this.benefitsSelectedUseCase.benefitsValues['cost_savings'].some(
							(benefit: any) => benefit.label === this.benefitsNewName,
						);
					default:
						return false;
				}
			})();
			if (benefitExists) {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: `Benefit "${this.benefitsNewName}" already exists in this use case.`,
				});
				return;
			}
			const newBenefit = {
				label: this.benefitsNewName,
				description: '',
				currentBaseline: 0,
				targetState: 0,
				timeFrame: '',
				fav: false,
				expr: '',
				calculation: '',
				commentary: '',
				default: false,
				measure: '',
				symbol: '',
				descriptionForClient: '',
				descriptionCalculation: '',
				required: false,
			};
			switch (this.benefitsName) {
				case 'revenue_growth':
					this.benefitsSelectedUseCase.benefitsValues['revenue_growth'].push(newBenefit);
					break;
				case 'working_capital':
					this.benefitsSelectedUseCase.benefitsValues['working_capital'].push(newBenefit);
					break;
				case 'customer_experience':
					this.benefitsSelectedUseCase.benefitsValues['customer_experience'].push(newBenefit);
					break;
				case 'process_productivity':
					this.benefitsSelectedUseCase.benefitsValues['process_productivity'].push(newBenefit);
					break;
				case 'employee_productivity':
					this.benefitsSelectedUseCase.benefitsValues['employee_productivity'].push(newBenefit);
					break;
				case 'cost_savings':
					this.benefitsSelectedUseCase.benefitsValues['cost_savings'].push(newBenefit);
					break;
				default:
					break;
			}

			this.benefitsSelectedUseCase.benefitsValues = { ...this.benefitsSelectedUseCase.benefitsValues };
			this.benefitsNewName = '';
			this.benefitsSelectedType = '';
		} else {
			if (!this.benefitsSelectedType) {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Please select the Benefit Type before adding a new Benefit',
				});
			}
			if (!this.benefitsNewName) {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Please enter the Benefit Name before adding a new Benefit',
				});
			}
		}
	}

	showCommentaryRowBenefits: { [key: string]: boolean } = {};

	toggleCommentaryBenefits(category: string, index: number) {
		const key = `${category}-${index}`;
		if (this.showCommentaryRowBenefits[key]) {
			delete this.showCommentaryRowBenefits[key];
		} else {
			this.showCommentaryRowBenefits[key] = true;
		}
	}

	closeCreateBenefitsModal() {
		this.benefitsDisplayCreateModal = false;
		this.cdr.detectChanges();
	}

	benefitsCategories = [
		{ label: 'Revenue Growth', value: 'revenue_growth' },
		{ label: 'Working Capital', value: 'working_capital' },
		{ label: 'Customer Experience', value: 'customer_experience' },
		{ label: 'Process Productivity', value: 'process_productivity' },
		{ label: 'Employee Productivity', value: 'employee_productivity' },
		{ label: 'Cost Savings', value: 'cost_savings' },
	];

	expandedRows: Set<number> = new Set<number>();
	allExpanded: boolean = false;

	hasNonZeroValues(benefits: any[] | undefined): boolean {
		return benefits ? benefits.some((data) => data.targetState !== 0 || data.currentBaseline !== 0) : false;
	}

	toggleAllRows() {
		this.allExpanded = !this.allExpanded;

		if (this.allExpanded) {
			this.data.forEach((row) => {
				if (row.id) {
					this.expandedRows.add(Number(row.id));
				}
			});
		} else {
			this.expandedRows.clear();
		}
	}

	toggleExpand(rowId: string | number | undefined) {
		if (rowId !== undefined) {
			const numericId = Number(rowId);
			if (this.expandedRows.has(numericId)) {
				this.expandedRows.delete(numericId);
			} else {
				this.expandedRows.add(numericId);
			}
			this.allExpanded = this.expandedRows.size === this.data.length;
		}
	}

	isRowExpanded(rowId: string | number | undefined): boolean {
		return rowId !== undefined && this.expandedRows.has(Number(rowId));
	}

	onRowReorder(event: any) {
		const draggedIndex = event.dragIndex;
		const targetIndex = event.dropIndex;

		if (draggedIndex !== targetIndex && !(draggedIndex === targetIndex)) {
			const [movedItem] = this.data.splice(targetIndex, 1);

			this.data.splice(targetIndex, 0, movedItem);

			const useCases: UseCase[] = this.data
				.map((useCase, index) => {
					if (useCase.benefitsOrder.benefits !== index) {
						useCase.benefitsOrder.benefits = index;
						return useCase;
					}
					return undefined;
				})
				.filter((u) => !!u) as UseCase[];

			if (useCases.length) {
				/*Promise.all(useCases.map((useCase) => this.useCaseService.saveUseCase(useCase)))
					.then(() => {
						this.messageService.add({
							severity: 'success',
							summary: 'Success',
							detail: `Order saved successfully`,
						});
					})
					.catch(() => {
						this.messageService.add({
							severity: 'error',
							summary: 'Error',
							detail: `Failed to save order`,
						});
					});*/
			}
		}
	}

	// Calc benefits
	selectedValue: string = 'Your Company Target';
	revenueGrowthValue: number = 0;
	savingsValue: number = 0;
	costReductionValue: number = 0;
	EmployeeCapacityValue: number = 0;
	workingCapitalValue: number = 0;
	customerExperienceValue: number = 0;
	revenueGrowthNonFinancialValue: number = 0;
	savingsNonFinancialValue: number = 0;
	costReductionNonFinancialValue: number = 0;
	EmployeeCapacityNonFinancialValue: number = 0;
	customerExperienceNonFinancialValue: number = 0;
	customerExperienceNonFinancialPercent: number = 0;

	formatValue(value: number): string {
		return Number.isInteger(value) ? value.toString() : value.toFixed(2);
	}

	calculateFinancials() {
		const revenueGrowth = this.calculateRevenueGrowth();
		this.revenueGrowthValue = parseFloat(this.formatValue(revenueGrowth.total));

		const savings = this.calculateSavings();
		this.savingsValue = parseFloat(this.formatValue(savings.total));

		const costReduction = this.calculateCostReduction();
		this.costReductionValue = parseFloat(this.formatValue(costReduction.total));
	}

	calculateNonFinancials() {
		const workingCapital = this.calculateWorkingCapital();
		this.workingCapitalValue = parseFloat(this.formatValue(workingCapital));

		const EmployeeCapacity = this.calculateNonFinancialEmployeeCapacity();
		this.EmployeeCapacityNonFinancialValue = parseFloat(this.formatValue(EmployeeCapacity.total));

		const customerExperience = this.calculateNonFinancialCustomerExperience();
		this.customerExperienceNonFinancialValue = parseFloat(this.formatValue(customerExperience.total));
		this.customerExperienceNonFinancialPercent = parseFloat(this.formatValue(customerExperience.percentTotal));
	}

	// Global Financial Benefits (Up) - Revenue Growth and Total Benefits Calculation
	calculateRevenueGrowth() {
		let totalRevenueGrowth = 0;

		this.dataHeatmap.forEach((useCase) => {
			const revenueGrowthDifference = this.calculateRevenueGrowthDifference(useCase);
			totalRevenueGrowth += Math.abs(revenueGrowthDifference.absoluteDifference);
		});

		return { total: totalRevenueGrowth };
	}

	// Global Soft Benefits (Down) - Working Capital
	calculateWorkingCapital() {
		let totalImpact = 0;
		let hasValidBenefit = false;

		this.dataHeatmap.forEach((useCase) => {
			let DSO = 0;
			let DPO = 0;
			let DIO = 0;

			if (useCase.benefitsValues?.working_capital) {
				const DSOBenefit = useCase.benefitsValues.working_capital.find(
					(b) => b.label === 'Days Sales Outstanding (DSO)',
				);
				const DPOBenefit = useCase.benefitsValues.working_capital.find(
					(b) => b.label === 'Days Payable Outstanding (DPO)',
				);
				const DIOBenefit = useCase.benefitsValues.working_capital.find(
					(b) => b.label === 'Days Inventory Outstanding (DIO)',
				);

				if (
					DSOBenefit &&
					DPOBenefit &&
					DIOBenefit &&
					DSOBenefit.targetState !== 0 &&
					DSOBenefit.currentBaseline !== 0 &&
					DPOBenefit.targetState !== 0 &&
					DPOBenefit.currentBaseline !== 0 &&
					DIOBenefit.targetState !== 0 &&
					DIOBenefit.currentBaseline !== 0
				) {
					DSO = Math.abs(DSOBenefit.targetState) - Math.abs(DSOBenefit.currentBaseline);
					DPO = Math.abs(DPOBenefit.targetState) - Math.abs(DPOBenefit.currentBaseline);
					DIO = Math.abs(DIOBenefit.targetState) - Math.abs(DIOBenefit.currentBaseline);
					hasValidBenefit = true;
				}
			}
			const revenueGrowthDifference = this.calculateRevenueGrowthTarget(useCase);

			if (hasValidBenefit && revenueGrowthDifference.absoluteDifference !== 0) {
				const workingCapitalImpact = (DSO + DIO - DPO) * (revenueGrowthDifference.absoluteDifference / 365);
				totalImpact += isNaN(workingCapitalImpact) ? 0 : workingCapitalImpact;
			}
		});
		return totalImpact;
	}

	// Global Financial Benefits (Up) - Used in Total Benefits Calculation and Cost Reduction
	calculateSavings(): { total: number } {
		let totalCurrentBaseline = 0;
		let totalTargetState = 0;

		this.dataHeatmap.forEach((useCase) => {
			if (useCase.benefitsValues?.cost_savings) {
				const costSavings = useCase.benefitsValues.cost_savings.find(
					(benefit) => benefit.label === 'Cost Reduction',
				);

				if (costSavings?.currentBaseline && costSavings?.targetState) {
					totalCurrentBaseline += costSavings.currentBaseline;
					totalTargetState += costSavings.targetState;
				}
			}
		});

		if (totalCurrentBaseline === 0) {
			return { total: 0 };
		}

		const absoluteSavings = totalCurrentBaseline - totalTargetState;

		return {
			total: absoluteSavings,
		};
	}

	// Global Financial Benefits (Up) - Cost Reduction
	calculateCostReduction() {
		let totalCostReduction = 0;

		this.dataHeatmap.forEach((useCase) => {
			const { impact } = this.calculateCostReductionImpact(useCase);
			const impactValue = parseFloat(impact);

			if (!isNaN(impactValue)) {
				totalCostReduction += impactValue;
			}
		});

		return { total: totalCostReduction };
	}

	// Global Soft Benefits (Down) - Employee Capacity
	calculateEmployeeCapacity() {
		return this.dataHeatmap.reduce(
			(acc, useCase) => {
				const capacityEffortBenefit = useCase.benefitsValues?.employee_productivity?.find(
					(b) => b.label === 'Capacity Creation (hours)',
				);
				const costPerEmployee = useCase.benefitsValues?.employee_productivity?.find(
					(b) => b.label === 'Costs per Employee',
				);
				const FTEs = useCase.benefitsValues?.cost_savings?.find((b) => b.label === 'FTEs');

				if (
					capacityEffortBenefit &&
					costPerEmployee &&
					FTEs &&
					capacityEffortBenefit.targetState !== 0 &&
					capacityEffortBenefit.currentBaseline !== 0 &&
					costPerEmployee.currentBaseline !== 0 &&
					costPerEmployee.targetState !== 0
				) {
					const netCapacity = capacityEffortBenefit.targetState - capacityEffortBenefit.currentBaseline;
					const hourlyWage = (costPerEmployee.targetState - costPerEmployee.currentBaseline) / 2000;
					const hourlyWageFTE = hourlyWage * FTEs.currentBaseline;

					if (netCapacity !== 0 && hourlyWage !== 0) {
						const productivityValue = netCapacity * hourlyWageFTE;
						acc.total += productivityValue;
					}
				}
				return acc;
			},
			{ total: 0, percentTotal: 0 },
		);
	}

	// Global Soft Benefits (Down) - Customer Experience
	calculateCustomerExperience() {
		return this.dataHeatmap.reduce(
			(acc, useCase) => {
				const customerLTVBenefit = useCase.benefitsValues?.customer_experience?.find(
					(b) => b.label === 'Customer LTV',
				);
				const newCustomerBenefit = useCase.benefitsValues?.customer_experience?.find(
					(b) => b.label === 'Customers',
				);

				if (
					customerLTVBenefit &&
					newCustomerBenefit &&
					customerLTVBenefit.targetState !== 0 &&
					customerLTVBenefit.currentBaseline !== 0 &&
					newCustomerBenefit.currentBaseline !== 0 &&
					newCustomerBenefit.targetState !== 0
				) {
					const newCustomers = newCustomerBenefit.targetState - newCustomerBenefit.currentBaseline;
					const ltv = customerLTVBenefit.targetState - customerLTVBenefit.currentBaseline;
					const customerExperience = newCustomers * ltv;

					acc.total += customerExperience;
					acc.percentTotal += (customerExperience / customerLTVBenefit.targetState) * 100;
				}
				return acc;
			},
			{ total: 0, percentTotal: 0 },
		);
	}

	// Global Soft Benefits (Down) - Employee Capacity
	calculateNonFinancialEmployeeCapacity() {
		let totalCapacity = 0;
		let totalCurrentBaseline = 0;
		let hasValidValues = false;

		this.dataHeatmap.forEach((useCase) => {
			const FTEs = useCase.benefitsValues?.cost_savings?.find((b) => b.label === 'FTEs');

			if (useCase.benefitsValues?.employee_productivity) {
				useCase.benefitsValues.employee_productivity.forEach((benefit) => {
					if (
						FTEs &&
						benefit.label === 'Capacity Creation (hours)' &&
						benefit.targetState !== 0 &&
						benefit.currentBaseline !== 0
					) {
						if (benefit.currentBaseline - benefit.targetState) {
							totalCapacity += (benefit.currentBaseline - benefit.targetState) * FTEs.currentBaseline;
							totalCurrentBaseline += benefit.currentBaseline;

							hasValidValues = true;
						}
					}
				});
			}
		});

		if (hasValidValues) {
			const percentCapacity = (totalCapacity / totalCurrentBaseline) * 100;

			return {
				total: totalCapacity,
				percentTotal: percentCapacity,
			};
		} else {
			return { total: 0, percentTotal: 0 };
		}
	}

	/* calculateNonFinancialCustomerExperience(): { total: number; percentTotal: number } {
		let total = 0;
		let percentTotal = 0;
		let totalCustomerExperineceArray: Array<number> = [];

		this.dataHeatmap.forEach((useCase) => {
			const customerSatisfactionBenefit = useCase.benefitsValues?.customer_experience?.find(
				(b) => b.label === 'Customer Satisfaction',
			);

			if (
				customerSatisfactionBenefit &&
				customerSatisfactionBenefit.targetState !== 0 &&
				customerSatisfactionBenefit.currentBaseline !== 0
			) {

				totalCustomerExperineceArray.push(customerSatisfactionBenefit.targetState - customerSatisfactionBenefit.currentBaseline);
				percentTotal += customerSatisfactionBenefit.targetState - customerSatisfactionBenefit.currentBaseline;

			}
		});

		//Get Max Value of Customer Experience
		percentTotal = Math.max(...totalCustomerExperineceArray);

		return { total, percentTotal };
	} */

	// Global Soft Benefits (Down) - Customer Experience
	calculateNonFinancialCustomerExperience(): { total: number; percentTotal: number } {
		let total = 0;
		let percentTotal = 0;
		let totalCustomerExperienceArray: Array<number> = [];

		this.dataHeatmap.forEach((useCase) => {
			const customerSatisfactionBenefit = useCase.benefitsValues?.customer_experience?.find(
				(b) => b.label === 'Customer Satisfaction',
			);

			if (
				customerSatisfactionBenefit &&
				customerSatisfactionBenefit.targetState !== 0 &&
				customerSatisfactionBenefit.currentBaseline !== 0
			) {
				const impact = customerSatisfactionBenefit.targetState - customerSatisfactionBenefit.currentBaseline;
				totalCustomerExperienceArray.push(impact);
				total += impact; // Update total impact
				percentTotal += impact; // Optionally keep this if needed
			}
		});

		percentTotal = Math.max(...totalCustomerExperienceArray);

		return { total, percentTotal };
	}

	onFinancialBenefitsChange() {
		if (this.benefitsShowNonFinancial) {
			this.calculateNonFinancials();
		} else {
			this.calculateFinancials();
		}
	}

	onCheckboxChange(value: string) {
		this.selectedValue = value;
	}

	visible_add_process: boolean = false;

	addTaxonomyModal() {
		this.visible_add_process = true;
	}

	closeTaxonomyModal() {
		this.visible_add_process = false;
	}

	benefitsModal() {
		this.dialogService.open(BenefitsAssessmentComponent, {
			data: {},
			header: 'Assessments',
			width: '95%',
			height: '90%',
			styleClass: 'benefits-assessments',
			contentStyle: { overflow: 'auto' },
			showHeader: false,
			baseZIndex: 100,
			maximizable: true,
		});
	}

	formatWithSuffix(value: number, dollar: boolean = false): string {
		const suffixes = ['', 'K', 'M', 'B', 'T'];
		let suffixIndex = 0;

		// Check if the number is negative
		const isNegative = value < 0;

		// Use absolute value for formatting
		value = Math.abs(value);

		while (value >= 1000 && suffixIndex < suffixes.length - 1) {
			value /= 1000;
			suffixIndex++;
		}

		const formattedValue =
			value % 1 === 0
				? value.toFixed(0)
				: value
						.toFixed(1)
						.replace(/\.0$/, '')
						.replace(/(\.\d)0$/, '$1');

		// Add the negative sign back if the number was negative
		return `${isNegative ? '-' : ''}${dollar ? '$' : ''}${formattedValue}${suffixes[suffixIndex]}`;
	}

	evaluateExpression(
		label: string,
		category: string,
		targetValue: number,
		currentBaseline: number,
		symbol: any,
		measure: string,
	): string {
		try {
			if (typeof symbol !== 'string') {
				symbol = symbol.data;
			}
			const absoluteDifference = targetValue - currentBaseline;
			const percentDifference = (absoluteDifference / currentBaseline) * 100;
			const increaseOrDecrease = percentDifference >= 0 ? 'increase' : 'decrease';

			if (symbol === '$') {
				return `${absoluteDifference >= 0 ? '' : '-'}$${this.formatWithSuffix(
					absoluteDifference,
				)} in ${category} (${percentDifference >= 0 ? '+' : '-'}${
					Number.isInteger(percentDifference)
						? Math.abs(percentDifference).toFixed(0)
						: Math.abs(percentDifference).toFixed(2)
				}%)`;
			} else if (symbol === '%') {
				return `${percentDifference >= 0 ? '+' : '-'}${
					Number.isInteger(percentDifference)
						? Math.abs(percentDifference).toFixed(0)
						: Math.abs(percentDifference).toFixed(2)
				}% ${increaseOrDecrease} in ${label} to ${absoluteDifference} for ${category}`;
			} else if (symbol === '#') {
				return `${absoluteDifference} ${measure.toLowerCase()} ${
					absoluteDifference >= 0 ? 'increase' : 'decrease'
				} in ${label} in ${category} (${percentDifference >= 0 ? '+' : '-'}${
					Number.isInteger(percentDifference)
						? Math.abs(percentDifference).toFixed(0)
						: Math.abs(percentDifference).toFixed(2)
				}%)`;
			} else {
				return 'Invalid measure';
			}
		} catch (error) {
			return 'Error';
		}
	}

	replaceNullValues(data: any) {
		if (data.benefitsValues) {
			Object.keys(data.benefitsValues).forEach((category) => {
				const benefits = data.benefitsValues[category];
				benefits.forEach((benefit: any) => {
					if (benefit.currentBaseline === null) {
						benefit.currentBaseline = 0;
					}
					if (benefit.targetState === null) {
						benefit.targetState = 0;
					}
				});
			});
		}
		return data;
	}

	calculateSavingsDifference(costSavingsData: any[]): string {
		let totalCurrentBaseline = 0;
		let totalTargetState = 0;

		costSavingsData.forEach((benefit) => {
			if (benefit.label === 'Cost Reduction' && benefit.targetState !== 0 && benefit.currentBaseline >= 0) {
				totalCurrentBaseline += benefit.currentBaseline;
				totalTargetState += benefit.targetState;
			}
		});

		if (totalCurrentBaseline !== 0 && totalTargetState !== 0) {
			const savingsDifference = totalTargetState - totalCurrentBaseline;
			return this.formatWithSuffix(savingsDifference);
		}

		return '---';
	}

	calculateRevenueDifference(revenueGrowth: any[]): string {
		const revenueBenefit = revenueGrowth?.find((benefit) => benefit.label === 'Revenue');
		if (revenueBenefit) {
			const targetState = revenueBenefit.targetState || 0;
			const currentBaseline = revenueBenefit.currentBaseline || 0;
			if (targetState === 0 && currentBaseline === 0) {
				return '---';
			}
			const difference = targetState - currentBaseline;
			return difference.toString();
		}
		return '---';
	}

	calculateTotalDifference(revenueGrowth: any[], costSavingsData: any[]): string {
		const revenueDifference = this.calculateRevenueDifference(revenueGrowth);
		const savingsDifference = this.calculateSavingsDifference(costSavingsData);

		if (revenueDifference === '---' || savingsDifference === '---') {
			return '---';
		}
		const revenueDiffValue = parseFloat(revenueDifference);
		const savingsDiffValue = parseFloat(savingsDifference);
		const totalDifference = revenueDiffValue - savingsDiffValue;

		return this.formatWithSuffix(Math.abs(totalDifference));
	}

	getMeasureSymbol(measure: string): string {
		switch (measure) {
			case 'Dollars':
				return '$';
			case 'Percentage':
				return '%';
			case 'Hours':
				return 'hrs';
			case 'Units':
			case 'Number':
			case 'Full Time Equivalent':
				return 'units';
			default:
				return '';
		}
	}

	saveUseCaseBenefits(useCase: UseCase) {
		if (!this.can_edit_benefits) {
			return;
		}
		const cleanedData = this.replaceNullValues(useCase);
		this.useCaseService
			.saveUseCase(cleanedData)
			.then(() => {
				this.originalBenefitsValues = JSON.parse(JSON.stringify(this.benefitsSelectedUseCase?.benefitsValues));
				this.messageService.add({
					severity: 'success',
					summary: 'Success',
					detail: `Use Case saved`,
				});

				useCase.calculatedData.matrixBenefits = useCase.getMatrixBenefit();

				this.data = this.data.map((data) => {
					return data.id === useCase.id ? useCase : data;
				});

				this.dataHeatmap = this.dataHeatmap.map((data) => {
					return data.id === useCase.id ? useCase : data;
				});

				this._filteredDataBenefitsCache = this._filteredDataBenefitsCache.map((data) => {
					return data.id === useCase.id ? useCase : data;
				});

				this.calculateFinancials();
				this.calculateNonFinancials();
			})
			.catch(() => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: `An error occurred while updating the Use Case`,
				});
			});

		// Aqui
	}

	visible_use_case_dialog: boolean = false;
	technologiesDropdown: Technology[] = [];
	tags: TreeNode[] = [];
	tagsSelection: TreeNode[] = [];
	benefitsTags: string[] = ['Revenue', 'Efficiency', 'Effectiveness', 'Experience'];
	timeTags: string[] = ['Extensive (Quarters)', 'Moderate (Months)', 'Short (Weeks)'];
	effortTags: string[] = ['Extensive (5+ FTEs)', 'Moderate (5 FTEs)', 'Easy (1 FTE)'];

	async newUseCase() {
		this.useCaseService.currentUseCase = plainToInstance(UseCase, {
			name: '',
			description: '',
			technologiesIds: [],
			levels2Ids: [],
			formData: {},
			simulationData: {},
			libraryData: {},
			nexus: {},
			organizationId: await this.authService.getCurrentOrganizationId(),
		});

		this.useCaseService.currentUseCase.diagram = this.useCaseService._newDiagramEmpty(
			this.useCaseService.currentUseCase,
		);

		this.useCaseService.currentUseCase.diagram.organizationId = await this.authService.getCurrentOrganizationId();

		this.useCaseService.currentOriginalUseCase = instanceToInstance(this.useCaseService.currentUseCase);

		if (!this.technologiesDropdown.length) {
			this.technologiesDropdown = await this.e2ETaxonomyService.getTechnologies();
		}

		this.tagsSelection = [];

		if (!this.tags.length) {
			this.tags = await this.e2ETaxonomyService.getCategoriesFunction(
				{
					extend: 'function.level1.level2',
				},
				true,
			);

			for (const categoryFunctionTag of this.tags) {
				if (categoryFunctionTag.children?.length) {
					categoryFunctionTag.selectable = false;
					for (const functionTag of categoryFunctionTag.children) {
						functionTag.selectable = false;
						if (functionTag.children?.length) {
							for (const level1Tag of functionTag.children) {
								level1Tag.selectable = false;
							}
						}
					}
				}
			}
		}

		this.visible_use_case_dialog = true;
	}

	blockedAddUseCase = false;
	blockedAddUseCaseGo = false;

	addUseCase(toGo?: boolean) {
		if (toGo) {
			this.blockedAddUseCaseGo = true;
			this.blockedAddUseCase = false;
		} else {
			this.blockedAddUseCase = true;
			this.blockedAddUseCaseGo = false;
		}
		if (this.useCaseService.currentUseCase) {
			this.useCaseService.currentUseCase.levels2Ids = this.tagsSelection
				.map((t) => t.key)
				.filter((id) => !!id) as string[];
			this.loadUseCaseBenefits(this.useCaseService.currentUseCase);
		}
		this.useCaseService.saveCurrentUseCase().then((error) => {
			if (error) {
				if (toGo) {
					this.blockedAddUseCaseGo = false;
				} else {
					this.blockedAddUseCase = false;
				}
				this.messageSaveUseCase(error);
			} else {
				if (this.useCaseService.currentUseCase?.diagram) {
					this.useCaseService.saveDiagram(this.useCaseService.currentUseCase.diagram).then(async () => {
						if (toGo) {
							this.blockedAddUseCaseGo = false;
						} else {
							this.blockedAddUseCase = false;
						}
						this.visible_use_case_dialog = false;
						this.messageSaveUseCase();

						await this.reloadData();
						if (toGo && this.useCaseService.currentUseCase?.id) {
							await this.useCaseService.goToUseCase(this.useCaseService.currentUseCase.id);
						}
					});
				}
			}
		});
	}

	messageSaveUseCase(error?: string) {
		if (error) {
			if (error === 'no-name') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No name',
					detail: 'Provide a name for the new Use Case',
				});
			} else if (error === 'no-description') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No description',
					detail: 'Provide a description for the new Use Case',
				});
			} else if (error === 'no-levels2') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No Taxonomy tags',
					detail: 'Provide a Taxonomy tags list for the new Use Case',
				});
			} else if (error === 'no-technologies') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No AI Enabler',
					detail: 'Provide a AI Enabler list for the new Use Case',
				});
			} else if (error === 'no-updated') {
				this.messageService.add({ severity: 'warn', summary: 'No changes', detail: 'Nothing to save' });
			} else {
				this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
			}
		} else {
			this.messageService.add({
				severity: 'success',
				summary: 'Confirmed',
				detail: 'Use Case saved on My Use Cases',
			});
		}
	}

	formPrioritization(label: string, value: string | number) {
		if (
			[
				'revenue',
				'customer satisfaction',
				'customer ltv',
				'speed (hours)',
				'volume',
				'days sales outstanding (dso)',
				'days payable outstanding (dpo)',
				'days inventory outstanding (dio)',
				'capacity creation (hours)',
				'cost reduction',
			].includes(label.toLowerCase()) &&
			!value
		) {
			return 'ng-invalid ng-dirty';
		}
		return '';
	}

	calculateDifference(currentBaseline: number, targetState: number, measure: string): string {
		const max = Math.max(currentBaseline, targetState);
		const min = Math.min(currentBaseline, targetState);
		const difference = max - min;
		return this.formatValueWithUnit(difference, measure);
	}

	formatValueWithUnit(value: number, unit: string): string {
		if (!value) {
			return '';
		}

		switch (unit) {
			case 'Dollars':
				return `$ ${value.toLocaleString()}`;
			case 'Percentage':
				return `${value.toLocaleString()} %`;
			case 'Hours':
				return `${value.toLocaleString()} hrs`;
			case 'Units':
			case 'Full Time Equivalent':
				return `${value.toLocaleString()} units`;
			default:
				return `${value.toLocaleString()}`;
		}
	}

	currentHoveredUseCase: UseCase | null = null;

	setCurrentUseCase(useCase: UseCase) {
		this.currentHoveredUseCase = useCase;
		this.calculateBenefitsForCurrentUseCase();
		// console.log('currentHoveredUseCase', useCase);
	}

	clearCurrentUseCase() {
		this.currentHoveredUseCase = null;
		this.resetBenefits();
	}

	calculateBenefitsForCurrentUseCase() {
		if (this.currentHoveredUseCase) {
			this.benefitsDataHeatmapA = this.calculateBenefitsHeatmapA(this.currentHoveredUseCase);
			this.benefitsDataHeatmapB = this.calculateBenefitsHeatmapB(this.currentHoveredUseCase);
		}
	}

	resetBenefits() {
		this.benefitsDataHeatmapA = JSON.parse(JSON.stringify(this.originalBenefitsDataHeatmapA));
		this.benefitsDataHeatmapB = JSON.parse(JSON.stringify(this.originalBenefitsDataHeatmapB));
	}

	// Individual Results Up (Financial Benefits)
	calculateBenefitsHeatmapA(useCase: UseCase) {
		const costReductionImpact = this.calculateCostReductionImpact(useCase) || {
			impact: 0,
			absoluteValue: '0',
		};
		const revenueGrowthDifference = this.calculateRevenueGrowthDifference(useCase) || {
			absoluteDifference: 0,
		};
		const costSavingsDifference = this.calculateCostSavings(useCase) || {
			absoluteSavings: 0,
			percentageSavings: '0%',
		};
		const totalAbsoluteBenefits = isNaN(
			Math.abs(revenueGrowthDifference.absoluteDifference) + Math.abs(costSavingsDifference.absoluteSavings),
		)
			? 0
			: Math.abs(revenueGrowthDifference.absoluteDifference) + Math.abs(costSavingsDifference.absoluteSavings);

		return [
			{
				label: 'Revenue Growth', // Individual
				value_a: '',
				sup_a: '',
				value_b: '$' + this.formatWithSuffix(revenueGrowthDifference.absoluteDifference ?? 0),
				sup_b: '',
			},
			{
				label: 'Cost Reduction', // Individual
				value_a: '',
				sup_a: '',
				value_b:
					'$' +
					this.formatWithSuffix(
						Math.abs(
							!isNaN(Number(costReductionImpact.impact)) && costReductionImpact.impact !== 'N/A'
								? Number(costReductionImpact.impact) + Number(costSavingsDifference.absoluteSavings)
								: Number(costSavingsDifference.absoluteSavings),
						),
					),
				sup_b: '',
			},
			{
				label: 'Total Benefits', // Individual
				value_a: '',
				sup_a: '',
				value_b: '$' + this.formatWithSuffix(totalAbsoluteBenefits),
				sup_b: '',
			},
		];
	}

	// Individual Results Down (Soft Benefits*)
	calculateBenefitsHeatmapB(useCase: UseCase) {
		const EmployeeCapacityNonFinancial = this.calculateNonFinancialEmployeeCapacityImpact(useCase) || {
			total: 0,
			percentTotal: 0,
		};
		const customerExperienceNonFinancial = this.calculateNonFinancialCustomerExperienceImpact(useCase) || {
			total: 0,
			percentTotal: 0,
		};

		const workingCapitalImpact = this.calculateWorkingCapitalImpact(useCase) || {
			impact: 0,
		};

		return [
			{
				label: 'Working Capital', // Individual
				value_a: '',
				sup_a: '',
				value_b: '$' + this.formatWithSuffix(Math.abs(workingCapitalImpact.impact)),
				sup_b: '',
			},
			{
				label: 'Employee Capacity', // Individual
				value_a: '',
				sup_a: '',
				value_b:
					this.formatWithSuffix(
						isNaN(EmployeeCapacityNonFinancial.total) ? 0 : EmployeeCapacityNonFinancial.total,
					) + ' hrs',
				sup_b: '',
			},
			{
				label: 'Customer Experience', // Individual
				value_a: '',
				sup_a: '',
				value_b:
					(isNaN(customerExperienceNonFinancial.percentTotal)
						? 0
						: this.formatWithSuffix(customerExperienceNonFinancial.percentTotal)) + '%',
				sup_b: '',
			},
		];
	}

	// Individual Soft Benefits (Down) - Working Capital
	calculateWorkingCapitalImpact(useCase: UseCase) {
		let DSO = 0;
		let DPO = 0;
		let DIO = 0;

		if (useCase.benefitsValues?.working_capital) {
			useCase.benefitsValues.working_capital.forEach((benefit) => {
				if (benefit.label === 'Days Sales Outstanding (DSO)') {
					DSO += Math.abs(benefit.targetState) - Math.abs(benefit.currentBaseline);
				}
				if (benefit.label === 'Days Payable Outstanding (DPO)') {
					DPO += Math.abs(benefit.targetState) - Math.abs(benefit.currentBaseline);
				}
				if (benefit.label === 'Days Inventory Outstanding (DIO)') {
					DIO += Math.abs(benefit.targetState) - Math.abs(benefit.currentBaseline);
				}
			});
		}
		const revenueGrowthDifference = this.calculateRevenueGrowthTarget(useCase);
		const workingCapitalImpact = (DSO + DIO - DPO) * (Math.abs(revenueGrowthDifference.absoluteDifference) / 365);

		return {
			impact: isNaN(workingCapitalImpact) ? 0 : workingCapitalImpact,
		};
	}

	// Individual Soft Benefits (Down) - Employee Capacity
	calculateNonFinancialEmployeeCapacityImpact(useCase: UseCase): { total: number; percentTotal: number } {
		let totalCapacity = 0;
		let totalCurrentBaseline = 0;
		let hasValidValues = false;

		const FTEs = useCase.benefitsValues?.cost_savings?.find((b) => b.label === 'FTEs');
		if (useCase.benefitsValues?.employee_productivity) {
			useCase.benefitsValues.employee_productivity.forEach((benefit) => {
				if (
					FTEs &&
					benefit.label === 'Capacity Creation (hours)' &&
					benefit.targetState !== 0 &&
					benefit.currentBaseline !== 0
				) {
					totalCapacity += (benefit.currentBaseline - benefit.targetState) * FTEs.currentBaseline;
					totalCurrentBaseline += benefit.currentBaseline;
					hasValidValues = true;
				}
			});
		}

		if (hasValidValues) {
			const percentCapacity = (totalCapacity / totalCurrentBaseline) * 100;
			return {
				total: totalCapacity,
				percentTotal: percentCapacity,
			};
		} else {
			return { total: 0, percentTotal: 0 };
		}
	}

	// Individual Soft Benefits (Down) - Customer Experience
	calculateNonFinancialCustomerExperienceImpact(useCase: UseCase): { total: number; percentTotal: number } {
		const customerSatisfactionBenefit = useCase.benefitsValues?.customer_experience?.find(
			(b) => b.label === 'Customer Satisfaction',
		);

		if (customerSatisfactionBenefit) {
			const percentTotal = Math.abs(
				customerSatisfactionBenefit.targetState - customerSatisfactionBenefit.currentBaseline,
			);
			return { total: percentTotal, percentTotal };
		}

		return { total: 0, percentTotal: 0 };
	}

	// Individual Financial Benefits (Up) - Revenue Growth
	calculateRevenueGrowthDifference(useCase: UseCase): { absoluteDifference: number } {
		const revenueGrowth = useCase.benefitsValues?.revenue_growth?.find((benefit) => benefit.label === 'Revenue');

		if (!revenueGrowth?.targetState || !revenueGrowth?.currentBaseline) {
			return { absoluteDifference: 0 };
		}

		const currentBaseline = revenueGrowth.currentBaseline;
		const targetState = revenueGrowth.targetState;
		const absoluteDifference = Math.abs(targetState - currentBaseline);

		return {
			absoluteDifference,
		};
	}

	// Individual Financial Benefits (Up) - Working Capital
	calculateRevenueGrowthTarget(useCase: UseCase): { percentageDifference: string; absoluteDifference: number } {
		const revenueGrowth = useCase.benefitsValues?.revenue_growth?.find((benefit) => benefit.label === 'Revenue');

		if (!revenueGrowth?.targetState || !revenueGrowth?.currentBaseline) {
			return { percentageDifference: '0%', absoluteDifference: 0 };
		}

		const currentBaseline = revenueGrowth.currentBaseline;
		const targetState = revenueGrowth.targetState;

		return {
			percentageDifference: '0%',
			absoluteDifference: targetState,
		};
	}

	// Individual Financial Benefits (Up) - Cost Savings
	calculateCostSavings(useCase: UseCase): { percentageSavings: string; absoluteSavings: number } {
		const costSavings = useCase.benefitsValues?.cost_savings?.find((benefit) => benefit.label === 'Cost Reduction');

		if (!costSavings?.currentBaseline || !costSavings?.targetState) {
			return { percentageSavings: '0%', absoluteSavings: 0 };
		}

		const currentBaseline = costSavings.currentBaseline;
		const targetState = costSavings.targetState;

		const percentageSavings = ((currentBaseline - targetState) / currentBaseline) * 100;
		const absoluteSavings = currentBaseline - targetState;

		return {
			percentageSavings: percentageSavings.toFixed(2) + '%',
			absoluteSavings: absoluteSavings,
		};
	}

	// Individual Soft Benefits (Down) - Customer Experience - OLD, it's not used anymore
	calculateCustomerExperienceImpact(useCase: UseCase): { absoluteDifference: number } {
		const customerLTVBenefit = useCase.benefitsValues?.customer_experience?.find(
			(benefit) => benefit.label === 'Customer LTV',
		);
		const newCustomerBenefit = useCase.benefitsValues?.customer_experience?.find(
			(benefit) => benefit.label === 'Customers',
		);

		if (
			!customerLTVBenefit ||
			!newCustomerBenefit ||
			!customerLTVBenefit.targetState ||
			!customerLTVBenefit.currentBaseline ||
			!newCustomerBenefit.targetState ||
			!newCustomerBenefit.currentBaseline
		) {
			return { absoluteDifference: 0 };
		}

		const newCustomers = newCustomerBenefit.targetState - newCustomerBenefit.currentBaseline;
		const ltv = customerLTVBenefit.targetState - customerLTVBenefit.currentBaseline;

		const absoluteDifference = newCustomers * ltv;

		return {
			absoluteDifference,
		};
	}

	// Individual Financial Benefits (Up) - Cost Reduction
	calculateCostReductionImpact(useCase: UseCase): { impact: string; absoluteValue: string } {
		const speed = useCase.benefitsValues?.process_productivity?.find(
			(benefit) => benefit.label === 'Speed (hours)',
		);
		const volume = useCase.benefitsValues?.process_productivity?.find((benefit) => benefit.label === 'Volume');
		const processCost = useCase.benefitsValues?.process_productivity?.find(
			(benefit) => benefit.label === 'Process Cost',
		);

		if (
			!speed?.targetState ||
			!speed?.currentBaseline ||
			!volume?.targetState ||
			!volume?.currentBaseline ||
			!processCost?.currentBaseline
		) {
			return { impact: 'N/A', absoluteValue: 'N/A' };
		}

		const netSpeed = Math.abs(speed.targetState) - Math.abs(speed.currentBaseline);
		const netVolume = volume.targetState;
		const processCostFinal = (processCost.targetState - processCost.currentBaseline) / 2000;
		const productivityValue = netSpeed * netVolume * processCostFinal;

		return {
			impact: productivityValue.toFixed(2),
			absoluteValue: productivityValue.toFixed(2),
		};
	}

	// Individual Soft Benefits (Down) - Employee Capacity - OLD, it's not used anymore
	calculateEmployeeCapacityImpact(useCase: UseCase): { absoluteDifference: number } {
		const capacityEffort = useCase.benefitsValues?.employee_productivity?.find(
			(benefit) => benefit.label === 'Capacity Creation (hours)',
		);
		const costPerEmployee = useCase.benefitsValues?.employee_productivity?.find(
			(benefit) => benefit.label === 'Costs per Employee',
		);
		const FTEs = useCase.benefitsValues?.cost_savings?.find((benefit) => benefit.label === 'FTEs');

		if (
			!capacityEffort ||
			!costPerEmployee ||
			!FTEs ||
			!capacityEffort.targetState ||
			!capacityEffort.currentBaseline ||
			!costPerEmployee.currentBaseline ||
			!costPerEmployee.targetState ||
			!FTEs.currentBaseline
		) {
			return { absoluteDifference: 0 };
		}

		const netCapacity = capacityEffort.targetState - capacityEffort.currentBaseline;
		const hourlyWage = (costPerEmployee.targetState - costPerEmployee.currentBaseline) / 2000;
		const hourlyWageFTE = hourlyWage * FTEs.currentBaseline;

		const absoluteDifference = netCapacity * hourlyWageFTE;

		return {
			absoluteDifference,
		};
	}

	originalBenefitsDataHeatmapA: any[] = [];
	originalBenefitsDataHeatmapB: any[] = [];

	socket: WebSocket;
	messageSocket: Message;
	messages: Message[];

	webSocket(useCaseId: string) {
		const url = environment.url.replace('http', 'ws');
		this.socket = new WebSocket(
			url + '/user-case/' + useCaseId + '/' + '?token=' + localStorage.getItem('auth-token'),
		);

		this.socket.addEventListener('message', (event) => {
			const data: { id: string; username: string } = JSON.parse(event.data);
			if (data.id) {
				this.can_edit_benefits = false;
				this.messageSocket = {
					severity: 'warn',
					summary: 'Use Case in use',
					detail: `The user '${data.username}' is currently using this use case, so you cannot save any changes while that user is connected.`,
					life: 5000,
				};
				this.messages = [{ ...this.messageSocket, life: 8000 }];
			} else {
				this.can_edit_benefits = true;
			}
		});
	}
}
