// @ts-nocheck
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Scalars = {
    String: string,
    GenericScalar: any,
    UUID: any,
    BigInt: any,
    Int: number,
    Boolean: boolean,
    Decimal: any,
}

export interface Query {
    common: (CommonQuery | null)
    e2eTaxonomy: (E2ETaxonomyQuery | null)
    solution: (SolutionQuery | null)
    useCase: (UseCaseQuery | null)
    maturity: (MaturityQuery | null)
    simulation: (SimulationQuery | null)
    __typename: 'Query'
}

export interface CommonQuery {
    commonData: (CommonDataQuery | null)
    organization: (OrganizationModelQuery | null)
    __typename: 'CommonQuery'
}

export interface CommonDataQuery {
    all: ((CommonDataObjectType | null)[] | null)
    __typename: 'CommonDataQuery'
}

export interface CommonDataObjectType {
    key: Scalars['String']
    data: (Scalars['GenericScalar'] | null)
    __typename: 'CommonDataObjectType'
}

export interface OrganizationModelQuery {
    fetch: ((OrganizationModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (OrganizationModelObjectType | null)
    all: ((OrganizationModelObjectType | null)[] | null)
    __typename: 'OrganizationModelQuery'
}

export interface OrganizationModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    summary: Scalars['String']
    taxonomy: (Scalars['GenericScalar'] | null)
    __typename: 'OrganizationModelObjectType'
}

export interface E2ETaxonomyQuery {
    categoryFunction: (CategoryFunctionModelQuery | null)
    diagramE2e: (DiagramE2EModelQuery | null)
    diagramProcess: (DiagramProcessModelQuery | null)
    diagramTemplate: (DiagramTemplateModelQuery | null)
    function: (FunctionModelQuery | null)
    level1: (Level1ModelQuery | null)
    level2: (Level2ModelQuery | null)
    technology: (TechnologyModelQuery | null)
    digitalWorker: (DigitalWorkerModelQuery | null)
    technologyByLevel2: (TechnologyByLevel2ModelQuery | null)
    aiOpportunity: (AIOpportunityModelQuery | null)
    __typename: 'E2ETaxonomyQuery'
}

export interface CategoryFunctionModelQuery {
    fetch: ((CategoryFunctionModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (CategoryFunctionModelObjectType | null)
    all: ((CategoryFunctionModelObjectType | null)[] | null)
    __typename: 'CategoryFunctionModelQuery'
}

export interface CategoryFunctionModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    short: (Scalars['String'] | null)
    sort: Scalars['Int']
    type: E2ETaxonomyCategoryFunctionTypeChoices
    office: E2ETaxonomyCategoryFunctionOfficeChoices
    functions: ((FunctionModelChildObjectType | null)[] | null)
    functionsIds: ((Scalars['String'] | null)[] | null)
    __typename: 'CategoryFunctionModelObjectType'
}


/** An enumeration. */
export type E2ETaxonomyCategoryFunctionTypeChoices = 'A_1' | 'A_2' | 'A_3'


/** An enumeration. */
export type E2ETaxonomyCategoryFunctionOfficeChoices = 'A_1' | 'A_2' | 'A_3'

export interface FunctionModelChildObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    short: (Scalars['String'] | null)
    name: Scalars['String']
    description: Scalars['String']
    sort: Scalars['Int']
    type: E2ETaxonomyFunctionTypeChoices
    overall: Scalars['Int']
    levels1: ((Level1ModelChildObjectType | null)[] | null)
    levels1Ids: ((Scalars['String'] | null)[] | null)
    __typename: 'FunctionModelChildObjectType'
}


/** An enumeration. */
export type E2ETaxonomyFunctionTypeChoices = 'A_1' | 'A_2' | 'A_3'

export interface Level1ModelChildObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    spend: Scalars['Int']
    sme: Scalars['Int']
    overall: Scalars['Int']
    type: E2ETaxonomyLevel1TypeChoices
    levels2: ((Level2ModelChildObjectType | null)[] | null)
    levels2Ids: ((Scalars['String'] | null)[] | null)
    __typename: 'Level1ModelChildObjectType'
}


/** An enumeration. */
export type E2ETaxonomyLevel1TypeChoices = 'A_1' | 'A_2' | 'A_3'

export interface Level2ModelChildObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    values: (Scalars['GenericScalar'] | null)
    type: E2ETaxonomyLevel2TypeChoices
    __typename: 'Level2ModelChildObjectType'
}


/** An enumeration. */
export type E2ETaxonomyLevel2TypeChoices = 'A_1' | 'A_2' | 'A_3'

export interface DiagramE2EModelQuery {
    all: ((DiagramE2EModelObjectType | null)[] | null)
    __typename: 'DiagramE2EModelQuery'
}

export interface DiagramE2EModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    data: (Scalars['GenericScalar'] | null)
    xmlData: (Scalars['String'] | null)
    xmlImage: (Scalars['String'] | null)
    functionId: (Scalars['String'] | null)
    __typename: 'DiagramE2EModelObjectType'
}

export interface DiagramProcessModelQuery {
    all: ((DiagramProcessModelObjectType | null)[] | null)
    __typename: 'DiagramProcessModelQuery'
}

export interface DiagramProcessModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    data: (Scalars['GenericScalar'] | null)
    xmlData: (Scalars['String'] | null)
    xmlImage: (Scalars['String'] | null)
    level1Id: (Scalars['String'] | null)
    __typename: 'DiagramProcessModelObjectType'
}

export interface DiagramTemplateModelQuery {
    all: ((DiagramTemplateModelObjectType | null)[] | null)
    __typename: 'DiagramTemplateModelQuery'
}

export interface DiagramTemplateModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    data: (Scalars['GenericScalar'] | null)
    xmlData: (Scalars['String'] | null)
    xmlImage: (Scalars['String'] | null)
    level2Id: (Scalars['String'] | null)
    __typename: 'DiagramTemplateModelObjectType'
}

export interface FunctionModelQuery {
    fetch: ((FunctionModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (FunctionModelObjectType | null)
    all: ((FunctionModelObjectType | null)[] | null)
    __typename: 'FunctionModelQuery'
}

export interface FunctionModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    short: (Scalars['String'] | null)
    name: Scalars['String']
    description: Scalars['String']
    sort: Scalars['Int']
    type: E2ETaxonomyFunctionTypeChoices
    overall: Scalars['Int']
    category: CategoryFunctionModelObjectType
    categoryId: Scalars['String']
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    levels1: ((Level1ModelChildObjectType | null)[] | null)
    levels1Ids: ((Scalars['String'] | null)[] | null)
    __typename: 'FunctionModelObjectType'
}

export interface Level1ModelQuery {
    fetch: ((Level1ModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (Level1ModelObjectType | null)
    all: ((Level1ModelObjectType | null)[] | null)
    __typename: 'Level1ModelQuery'
}

export interface Level1ModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    spend: Scalars['Int']
    sme: Scalars['Int']
    overall: Scalars['Int']
    type: E2ETaxonomyLevel1TypeChoices
    function: FunctionModelObjectType
    functionId: Scalars['String']
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    levels2: ((Level2ModelChildObjectType | null)[] | null)
    levels2Ids: ((Scalars['String'] | null)[] | null)
    __typename: 'Level1ModelObjectType'
}

export interface Level2ModelQuery {
    fetch: ((Level2ModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (Level2ModelObjectType | null)
    all: ((Level2ModelObjectType | null)[] | null)
    __typename: 'Level2ModelQuery'
}

export interface Level2ModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    values: (Scalars['GenericScalar'] | null)
    type: E2ETaxonomyLevel2TypeChoices
    level1: Level1ModelObjectType
    level1Id: Scalars['String']
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    __typename: 'Level2ModelObjectType'
}

export interface TechnologyModelQuery {
    fetch: ((TechnologyModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (TechnologyModelObjectType | null)
    all: ((TechnologyModelObjectType | null)[] | null)
    __typename: 'TechnologyModelQuery'
}

export interface TechnologyModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    short: (Scalars['String'] | null)
    neto: Scalars['Boolean']
    svg: (Scalars['String'] | null)
    __typename: 'TechnologyModelObjectType'
}

export interface DigitalWorkerModelQuery {
    all: ((DigitalWorkerModelObjectType | null)[] | null)
    __typename: 'DigitalWorkerModelQuery'
}

export interface DigitalWorkerModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    short: (Scalars['String'] | null)
    description: (Scalars['String'] | null)
    technologyId: (Scalars['String'] | null)
    __typename: 'DigitalWorkerModelObjectType'
}

export interface TechnologyByLevel2ModelQuery {
    all: ((TechnologyByLevel2ModelObjectType | null)[] | null)
    __typename: 'TechnologyByLevel2ModelQuery'
}

export interface TechnologyByLevel2ModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    value: Scalars['Decimal']
    technologyId: (Scalars['String'] | null)
    level2Id: (Scalars['String'] | null)
    __typename: 'TechnologyByLevel2ModelObjectType'
}

export interface AIOpportunityModelQuery {
    fetch: ((AIOpportunityModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (AIOpportunityModelObjectType | null)
    all: ((AIOpportunityModelObjectType | null)[] | null)
    __typename: 'AIOpportunityModelQuery'
}

export interface AIOpportunityModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    shapeId: Scalars['String']
    name: Scalars['String']
    description: Scalars['String']
    checked: Scalars['Boolean']
    valid: Scalars['Boolean']
    data: (Scalars['GenericScalar'] | null)
    poc: (PocModelObjectType | null)
    pocId: (Scalars['String'] | null)
    technologies: ((TechnologyModelObjectType | null)[] | null)
    technologiesIds: ((Scalars['String'] | null)[] | null)
    __typename: 'AIOpportunityModelObjectType'
}

export interface PocModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    title: Scalars['String']
    description: (Scalars['String'] | null)
    generalInformation: (Scalars['GenericScalar'] | null)
    functionalRequirements: (Scalars['GenericScalar'] | null)
    noFunctionalRequirements: (Scalars['GenericScalar'] | null)
    inputsDataSource: (Scalars['GenericScalar'] | null)
    genAIChecklist: (Scalars['GenericScalar'] | null)
    deploymentHandshake: (Scalars['GenericScalar'] | null)
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    useCase: UseCaseModelObjectType
    useCaseId: Scalars['String']
    aiEnablers: ((TechnologyModelObjectType | null)[] | null)
    aiEnablersIds: ((Scalars['String'] | null)[] | null)
    __typename: 'PocModelObjectType'
}

export interface UseCaseModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    generalData: (Scalars['GenericScalar'] | null)
    formData: (Scalars['GenericScalar'] | null)
    workPlanData: (Scalars['GenericScalar'] | null)
    aiValues: (Scalars['GenericScalar'] | null)
    hubble: Scalars['Boolean']
    benefits: Scalars['Boolean']
    benefitsValues: (Scalars['GenericScalar'] | null)
    benefitsOrder: (Scalars['GenericScalar'] | null)
    simulationData: (Scalars['GenericScalar'] | null)
    libraryData: (Scalars['GenericScalar'] | null)
    nexus: (Scalars['GenericScalar'] | null)
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    diagram: (DiagramModelObjectType | null)
    diagramId: (Scalars['String'] | null)
    levels2: ((Level2ModelObjectType | null)[] | null)
    levels2Ids: ((Scalars['String'] | null)[] | null)
    opportunities: ((AIOpportunityModelObjectType | null)[] | null)
    opportunitiesIds: ((Scalars['String'] | null)[] | null)
    technologies: ((TechnologyModelObjectType | null)[] | null)
    technologiesIds: ((Scalars['String'] | null)[] | null)
    pocs: ((PocModelObjectType | null)[] | null)
    pocsIds: ((Scalars['String'] | null)[] | null)
    __typename: 'UseCaseModelObjectType'
}

export interface DiagramModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    data: (Scalars['GenericScalar'] | null)
    xmlData: (Scalars['String'] | null)
    xmlImage: (Scalars['String'] | null)
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    useCase: UseCaseModelObjectType
    useCaseId: Scalars['String']
    __typename: 'DiagramModelObjectType'
}

export interface SolutionQuery {
    categoryQuestion: (CategoryQuestionModelQuery | null)
    subCategoryQuestion: (SubCategoryQuestionModelQuery | null)
    flow: (FlowModelQuery | null)
    functionFilter: (FunctionFilterModelQuery | null)
    skill: (SkillModelQuery | null)
    aiLever: (AiLeverModelQuery | null)
    solution: (SolutionModelQuery | null)
    question: (QuestionModelQuery | null)
    tool: (ToolModelQuery | null)
    icon: (IconModelQuery | null)
    __typename: 'SolutionQuery'
}

export interface CategoryQuestionModelQuery {
    all: ((CategoryQuestionModelObjectType | null)[] | null)
    __typename: 'CategoryQuestionModelQuery'
}

export interface CategoryQuestionModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    text: Scalars['String']
    sort: Scalars['Int']
    __typename: 'CategoryQuestionModelObjectType'
}

export interface SubCategoryQuestionModelQuery {
    all: ((SubCategoryQuestionModelObjectType | null)[] | null)
    __typename: 'SubCategoryQuestionModelQuery'
}

export interface SubCategoryQuestionModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    text: Scalars['String']
    sort: Scalars['Int']
    categoryId: (Scalars['String'] | null)
    __typename: 'SubCategoryQuestionModelObjectType'
}

export interface FlowModelQuery {
    all: ((FlowModelObjectType | null)[] | null)
    __typename: 'FlowModelQuery'
}

export interface FlowModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    data: (Scalars['GenericScalar'] | null)
    xmlData: (Scalars['String'] | null)
    xmlImage: (Scalars['String'] | null)
    solutionId: (Scalars['String'] | null)
    __typename: 'FlowModelObjectType'
}

export interface FunctionFilterModelQuery {
    all: ((FunctionFilterModelObjectType | null)[] | null)
    __typename: 'FunctionFilterModelQuery'
}

export interface FunctionFilterModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    short: (Scalars['String'] | null)
    __typename: 'FunctionFilterModelObjectType'
}

export interface SkillModelQuery {
    all: ((SkillModelObjectType | null)[] | null)
    __typename: 'SkillModelQuery'
}

export interface SkillModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    short: (Scalars['String'] | null)
    __typename: 'SkillModelObjectType'
}

export interface AiLeverModelQuery {
    all: ((AiLeverModelObjectType | null)[] | null)
    __typename: 'AiLeverModelQuery'
}

export interface AiLeverModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    short: (Scalars['String'] | null)
    __typename: 'AiLeverModelObjectType'
}

export interface SolutionModelQuery {
    all: ((SolutionModelObjectType | null)[] | null)
    __typename: 'SolutionModelQuery'
}

export interface SolutionModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    summary: Scalars['String']
    keyFeatures: (Scalars['GenericScalar'] | null)
    data: (Scalars['GenericScalar'] | null)
    taxonomy: (Scalars['GenericScalar'] | null)
    certified: (Scalars['Boolean'] | null)
    industries: (Scalars['GenericScalar'] | null)
    aiDrivers: (Scalars['GenericScalar'] | null)
    icon: (Scalars['String'] | null)
    iconDefault: (IconModelObjectType | null)
    iconDefaultId: (Scalars['String'] | null)
    levels2Ids: ((Scalars['String'] | null)[] | null)
    functionsIds: ((Scalars['String'] | null)[] | null)
    technologiesIds: ((Scalars['String'] | null)[] | null)
    toolsIds: ((Scalars['String'] | null)[] | null)
    skillsIds: ((Scalars['String'] | null)[] | null)
    aiLeversIds: ((Scalars['String'] | null)[] | null)
    __typename: 'SolutionModelObjectType'
}

export interface IconModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    file: (Scalars['String'] | null)
    __typename: 'IconModelObjectType'
}

export interface QuestionModelQuery {
    all: ((QuestionModelObjectType | null)[] | null)
    __typename: 'QuestionModelQuery'
}

export interface QuestionModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    text: Scalars['String']
    weight: Scalars['Decimal']
    sort: Scalars['Int']
    answers: (Scalars['GenericScalar'] | null)
    weightJson: (Scalars['GenericScalar'] | null)
    tag: (Scalars['String'] | null)
    categoryId: (Scalars['String'] | null)
    subcategoryId: (Scalars['String'] | null)
    technologyId: (Scalars['String'] | null)
    __typename: 'QuestionModelObjectType'
}

export interface ToolModelQuery {
    all: ((ToolModelObjectType | null)[] | null)
    __typename: 'ToolModelQuery'
}

export interface ToolModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    short: (Scalars['String'] | null)
    __typename: 'ToolModelObjectType'
}

export interface IconModelQuery {
    all: ((IconModelObjectType | null)[] | null)
    __typename: 'IconModelQuery'
}

export interface UseCaseQuery {
    diagram: (DiagramModelQuery | null)
    useCase: (UseCaseModelQuery | null)
    poc: (PocModelQuery | null)
    __typename: 'UseCaseQuery'
}

export interface DiagramModelQuery {
    fetch: ((DiagramModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (DiagramModelObjectType | null)
    all: ((DiagramModelObjectType | null)[] | null)
    __typename: 'DiagramModelQuery'
}

export interface UseCaseModelQuery {
    fetch: ((UseCaseModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (UseCaseModelObjectType | null)
    all: ((UseCaseModelObjectType | null)[] | null)
    __typename: 'UseCaseModelQuery'
}

export interface PocModelQuery {
    fetch: ((PocModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (PocModelObjectType | null)
    all: ((PocModelObjectType | null)[] | null)
    __typename: 'PocModelQuery'
}

export interface MaturityQuery {
    assessment: (AssessmentMaturityModelQuery | null)
    question: (QuestionMaturityModelQuery | null)
    subCategoryQuestion: (SubCategoryQuestionMaturityModelQuery | null)
    categoryQuestion: (CategoryQuestionMaturityModelQuery | null)
    __typename: 'MaturityQuery'
}

export interface AssessmentMaturityModelQuery {
    all: ((AssessmentMaturityModelObjectType | null)[] | null)
    __typename: 'AssessmentMaturityModelQuery'
}

export interface AssessmentMaturityModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    content: (Scalars['String'] | null)
    formData: (Scalars['GenericScalar'] | null)
    commentData: (Scalars['GenericScalar'] | null)
    recommendations: (Scalars['GenericScalar'] | null)
    organizationId: (Scalars['String'] | null)
    __typename: 'AssessmentMaturityModelObjectType'
}

export interface QuestionMaturityModelQuery {
    all: ((QuestionMaturityModelObjectType | null)[] | null)
    __typename: 'QuestionMaturityModelQuery'
}

export interface QuestionMaturityModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    text: Scalars['String']
    sort: Scalars['Int']
    categoryId: (Scalars['String'] | null)
    subcategoryId: (Scalars['String'] | null)
    __typename: 'QuestionMaturityModelObjectType'
}

export interface SubCategoryQuestionMaturityModelQuery {
    all: ((SubCategoryQuestionMaturityModelObjectType | null)[] | null)
    __typename: 'SubCategoryQuestionMaturityModelQuery'
}

export interface SubCategoryQuestionMaturityModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    sort: Scalars['Int']
    categoryId: (Scalars['String'] | null)
    __typename: 'SubCategoryQuestionMaturityModelObjectType'
}

export interface CategoryQuestionMaturityModelQuery {
    all: ((CategoryQuestionMaturityModelObjectType | null)[] | null)
    __typename: 'CategoryQuestionMaturityModelQuery'
}

export interface CategoryQuestionMaturityModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    sort: Scalars['Int']
    __typename: 'CategoryQuestionMaturityModelObjectType'
}

export interface SimulationQuery {
    context: (EnterpriseContextModelQuery | null)
    simulation: (SimulationModelQuery | null)
    useCaseSimulation: (UseCaseSimulationModelQuery | null)
    useCaseLibrary: (UseCaseLibraryModelQuery | null)
    m1Prep: (M1PrepModelQuery | null)
    __typename: 'SimulationQuery'
}

export interface EnterpriseContextModelQuery {
    all: ((EnterpriseContextModelObjectType | null)[] | null)
    __typename: 'EnterpriseContextModelQuery'
}

export interface EnterpriseContextModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    enterpriseContext: (Scalars['GenericScalar'] | null)
    organizationId: (Scalars['String'] | null)
    __typename: 'EnterpriseContextModelObjectType'
}

export interface SimulationModelQuery {
    all: ((SimulationModelObjectType | null)[] | null)
    __typename: 'SimulationModelQuery'
}

export interface SimulationModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    notes: Scalars['String']
    search: Scalars['Boolean']
    generate: Scalars['Boolean']
    taxonomyId: (Scalars['UUID'] | null)
    taxonomyRel: SimulationSimulationTaxonomyRelChoices
    enterpriseContext: (Scalars['GenericScalar'] | null)
    simulationContext: (Scalars['GenericScalar'] | null)
    organizationId: (Scalars['String'] | null)
    __typename: 'SimulationModelObjectType'
}


/** An enumeration. */
export type SimulationSimulationTaxonomyRelChoices = 'A_1' | 'A_2' | 'A_3'

export interface UseCaseSimulationModelQuery {
    all: ((UseCaseSimulationModelObjectType | null)[] | null)
    __typename: 'UseCaseSimulationModelQuery'
}

export interface UseCaseSimulationModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    deployedId: (Scalars['UUID'] | null)
    keyFeatures: (Scalars['GenericScalar'] | null)
    context: (Scalars['GenericScalar'] | null)
    data: (Scalars['GenericScalar'] | null)
    valid: Scalars['Boolean']
    type: SimulationUseCaseSimulationTypeChoices
    simulationId: (Scalars['String'] | null)
    organizationId: (Scalars['String'] | null)
    __typename: 'UseCaseSimulationModelObjectType'
}


/** An enumeration. */
export type SimulationUseCaseSimulationTypeChoices = 'A_1' | 'A_2'

export interface UseCaseLibraryModelQuery {
    all: ((UseCaseLibraryModelObjectType | null)[] | null)
    __typename: 'UseCaseLibraryModelQuery'
}

export interface UseCaseLibraryModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    data: (Scalars['GenericScalar'] | null)
    sourceId: (Scalars['UUID'] | null)
    sourceRel: SimulationUseCaseLibrarySourceRelChoices
    __typename: 'UseCaseLibraryModelObjectType'
}


/** An enumeration. */
export type SimulationUseCaseLibrarySourceRelChoices = 'A_1' | 'A_2' | 'A_3'

export interface M1PrepModelQuery {
    all: ((M1PrepModelObjectType | null)[] | null)
    __typename: 'M1PrepModelQuery'
}

export interface M1PrepModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    context: (Scalars['GenericScalar'] | null)
    companyAnalysis: (Scalars['GenericScalar'] | null)
    products: (Scalars['GenericScalar'] | null)
    competitiveLandscape: (Scalars['GenericScalar'] | null)
    conversation: (Scalars['GenericScalar'] | null)
    salesforce: (Scalars['GenericScalar'] | null)
    organizationId: (Scalars['String'] | null)
    __typename: 'M1PrepModelObjectType'
}

export interface QueryGenqlSelection{
    common?: CommonQueryGenqlSelection
    e2eTaxonomy?: E2ETaxonomyQueryGenqlSelection
    solution?: SolutionQueryGenqlSelection
    useCase?: UseCaseQueryGenqlSelection
    maturity?: MaturityQueryGenqlSelection
    simulation?: SimulationQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CommonQueryGenqlSelection{
    commonData?: CommonDataQueryGenqlSelection
    organization?: OrganizationModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CommonDataQueryGenqlSelection{
    all?: CommonDataObjectTypeGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CommonDataObjectTypeGenqlSelection{
    key?: boolean | number
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface OrganizationModelQueryGenqlSelection{
    fetch?: (OrganizationModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    get?: (OrganizationModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    all?: (OrganizationModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface OrganizationModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    summary?: boolean | number
    taxonomy?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface E2ETaxonomyQueryGenqlSelection{
    categoryFunction?: CategoryFunctionModelQueryGenqlSelection
    diagramE2e?: DiagramE2EModelQueryGenqlSelection
    diagramProcess?: DiagramProcessModelQueryGenqlSelection
    diagramTemplate?: DiagramTemplateModelQueryGenqlSelection
    function?: FunctionModelQueryGenqlSelection
    level1?: Level1ModelQueryGenqlSelection
    level2?: Level2ModelQueryGenqlSelection
    technology?: TechnologyModelQueryGenqlSelection
    digitalWorker?: DigitalWorkerModelQueryGenqlSelection
    technologyByLevel2?: TechnologyByLevel2ModelQueryGenqlSelection
    aiOpportunity?: AIOpportunityModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryFunctionModelQueryGenqlSelection{
    fetch?: (CategoryFunctionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    get?: (CategoryFunctionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    all?: (CategoryFunctionModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryFunctionModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    short?: boolean | number
    sort?: boolean | number
    type?: boolean | number
    office?: boolean | number
    functions?: (FunctionModelChildObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null)} })
    functionsIds?: { __args: {query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FunctionModelChildObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    short?: boolean | number
    name?: boolean | number
    description?: boolean | number
    sort?: boolean | number
    type?: boolean | number
    overall?: boolean | number
    levels1?: (Level1ModelChildObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null)} })
    levels1Ids?: { __args: {query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level1ModelChildObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    spend?: boolean | number
    sme?: boolean | number
    overall?: boolean | number
    type?: boolean | number
    levels2?: (Level2ModelChildObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null)} })
    levels2Ids?: { __args: {query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level2ModelChildObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    values?: boolean | number
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramE2EModelQueryGenqlSelection{
    all?: (DiagramE2EModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramE2EModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    data?: boolean | number
    xmlData?: boolean | number
    xmlImage?: boolean | number
    functionId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramProcessModelQueryGenqlSelection{
    all?: (DiagramProcessModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramProcessModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    data?: boolean | number
    xmlData?: boolean | number
    xmlImage?: boolean | number
    level1Id?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramTemplateModelQueryGenqlSelection{
    all?: (DiagramTemplateModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramTemplateModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    data?: boolean | number
    xmlData?: boolean | number
    xmlImage?: boolean | number
    level2Id?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FunctionModelQueryGenqlSelection{
    fetch?: (FunctionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    get?: (FunctionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    all?: (FunctionModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FunctionModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    short?: boolean | number
    name?: boolean | number
    description?: boolean | number
    sort?: boolean | number
    type?: boolean | number
    overall?: boolean | number
    category?: CategoryFunctionModelObjectTypeGenqlSelection
    categoryId?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    levels1?: (Level1ModelChildObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null)} })
    levels1Ids?: { __args: {query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level1ModelQueryGenqlSelection{
    fetch?: (Level1ModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    get?: (Level1ModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    all?: (Level1ModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level1ModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    spend?: boolean | number
    sme?: boolean | number
    overall?: boolean | number
    type?: boolean | number
    function?: FunctionModelObjectTypeGenqlSelection
    functionId?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    levels2?: (Level2ModelChildObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null)} })
    levels2Ids?: { __args: {query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level2ModelQueryGenqlSelection{
    fetch?: (Level2ModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    get?: (Level2ModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    all?: (Level2ModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level2ModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    values?: boolean | number
    type?: boolean | number
    level1?: Level1ModelObjectTypeGenqlSelection
    level1Id?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TechnologyModelQueryGenqlSelection{
    fetch?: (TechnologyModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    get?: (TechnologyModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    all?: (TechnologyModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TechnologyModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    short?: boolean | number
    neto?: boolean | number
    svg?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DigitalWorkerModelQueryGenqlSelection{
    all?: (DigitalWorkerModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DigitalWorkerModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    short?: boolean | number
    description?: boolean | number
    technologyId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TechnologyByLevel2ModelQueryGenqlSelection{
    all?: (TechnologyByLevel2ModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TechnologyByLevel2ModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    value?: boolean | number
    technologyId?: boolean | number
    level2Id?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AIOpportunityModelQueryGenqlSelection{
    fetch?: (AIOpportunityModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    get?: (AIOpportunityModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    all?: (AIOpportunityModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AIOpportunityModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    shapeId?: boolean | number
    name?: boolean | number
    description?: boolean | number
    checked?: boolean | number
    valid?: boolean | number
    data?: boolean | number
    poc?: PocModelObjectTypeGenqlSelection
    pocId?: boolean | number
    technologies?: (TechnologyModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null)} })
    technologiesIds?: { __args: {query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PocModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    title?: boolean | number
    description?: boolean | number
    generalInformation?: boolean | number
    functionalRequirements?: boolean | number
    noFunctionalRequirements?: boolean | number
    inputsDataSource?: boolean | number
    genAIChecklist?: boolean | number
    deploymentHandshake?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    useCase?: UseCaseModelObjectTypeGenqlSelection
    useCaseId?: boolean | number
    aiEnablers?: (TechnologyModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null)} })
    aiEnablersIds?: { __args: {query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    generalData?: boolean | number
    formData?: boolean | number
    workPlanData?: boolean | number
    aiValues?: boolean | number
    hubble?: boolean | number
    benefits?: boolean | number
    benefitsValues?: boolean | number
    benefitsOrder?: boolean | number
    simulationData?: boolean | number
    libraryData?: boolean | number
    nexus?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    diagram?: DiagramModelObjectTypeGenqlSelection
    diagramId?: boolean | number
    levels2?: (Level2ModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null)} })
    levels2Ids?: { __args: {query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    opportunities?: (AIOpportunityModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null)} })
    opportunitiesIds?: { __args: {query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    technologies?: (TechnologyModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null)} })
    technologiesIds?: { __args: {query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    pocs?: (PocModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null)} })
    pocsIds?: { __args: {query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    data?: boolean | number
    xmlData?: boolean | number
    xmlImage?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    useCase?: UseCaseModelObjectTypeGenqlSelection
    useCaseId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SolutionQueryGenqlSelection{
    categoryQuestion?: CategoryQuestionModelQueryGenqlSelection
    subCategoryQuestion?: SubCategoryQuestionModelQueryGenqlSelection
    flow?: FlowModelQueryGenqlSelection
    functionFilter?: FunctionFilterModelQueryGenqlSelection
    skill?: SkillModelQueryGenqlSelection
    aiLever?: AiLeverModelQueryGenqlSelection
    solution?: SolutionModelQueryGenqlSelection
    question?: QuestionModelQueryGenqlSelection
    tool?: ToolModelQueryGenqlSelection
    icon?: IconModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionModelQueryGenqlSelection{
    all?: (CategoryQuestionModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    text?: boolean | number
    sort?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionModelQueryGenqlSelection{
    all?: (SubCategoryQuestionModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    text?: boolean | number
    sort?: boolean | number
    categoryId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FlowModelQueryGenqlSelection{
    all?: (FlowModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FlowModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    data?: boolean | number
    xmlData?: boolean | number
    xmlImage?: boolean | number
    solutionId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FunctionFilterModelQueryGenqlSelection{
    all?: (FunctionFilterModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FunctionFilterModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    short?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SkillModelQueryGenqlSelection{
    all?: (SkillModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SkillModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    short?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AiLeverModelQueryGenqlSelection{
    all?: (AiLeverModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AiLeverModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    short?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SolutionModelQueryGenqlSelection{
    all?: (SolutionModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SolutionModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    summary?: boolean | number
    keyFeatures?: boolean | number
    data?: boolean | number
    taxonomy?: boolean | number
    certified?: boolean | number
    industries?: boolean | number
    aiDrivers?: boolean | number
    icon?: boolean | number
    iconDefault?: IconModelObjectTypeGenqlSelection
    iconDefaultId?: boolean | number
    levels2Ids?: boolean | number
    functionsIds?: boolean | number
    technologiesIds?: boolean | number
    toolsIds?: boolean | number
    skillsIds?: boolean | number
    aiLeversIds?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface IconModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    file?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionModelQueryGenqlSelection{
    all?: (QuestionModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    text?: boolean | number
    weight?: boolean | number
    sort?: boolean | number
    answers?: boolean | number
    weightJson?: boolean | number
    tag?: boolean | number
    categoryId?: boolean | number
    subcategoryId?: boolean | number
    technologyId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ToolModelQueryGenqlSelection{
    all?: (ToolModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ToolModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    short?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface IconModelQueryGenqlSelection{
    all?: (IconModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseQueryGenqlSelection{
    diagram?: DiagramModelQueryGenqlSelection
    useCase?: UseCaseModelQueryGenqlSelection
    poc?: PocModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramModelQueryGenqlSelection{
    fetch?: (DiagramModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    get?: (DiagramModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    all?: (DiagramModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseModelQueryGenqlSelection{
    fetch?: (UseCaseModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    get?: (UseCaseModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    all?: (UseCaseModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PocModelQueryGenqlSelection{
    fetch?: (PocModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} } | boolean | number
    get?: (PocModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null)} })
    all?: (PocModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MaturityQueryGenqlSelection{
    assessment?: AssessmentMaturityModelQueryGenqlSelection
    question?: QuestionMaturityModelQueryGenqlSelection
    subCategoryQuestion?: SubCategoryQuestionMaturityModelQueryGenqlSelection
    categoryQuestion?: CategoryQuestionMaturityModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AssessmentMaturityModelQueryGenqlSelection{
    all?: (AssessmentMaturityModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AssessmentMaturityModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    content?: boolean | number
    formData?: boolean | number
    commentData?: boolean | number
    recommendations?: boolean | number
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionMaturityModelQueryGenqlSelection{
    all?: (QuestionMaturityModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionMaturityModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    text?: boolean | number
    sort?: boolean | number
    categoryId?: boolean | number
    subcategoryId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionMaturityModelQueryGenqlSelection{
    all?: (SubCategoryQuestionMaturityModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionMaturityModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    sort?: boolean | number
    categoryId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionMaturityModelQueryGenqlSelection{
    all?: (CategoryQuestionMaturityModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionMaturityModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    sort?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SimulationQueryGenqlSelection{
    context?: EnterpriseContextModelQueryGenqlSelection
    simulation?: SimulationModelQueryGenqlSelection
    useCaseSimulation?: UseCaseSimulationModelQueryGenqlSelection
    useCaseLibrary?: UseCaseLibraryModelQueryGenqlSelection
    m1Prep?: M1PrepModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EnterpriseContextModelQueryGenqlSelection{
    all?: (EnterpriseContextModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EnterpriseContextModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    enterpriseContext?: boolean | number
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SimulationModelQueryGenqlSelection{
    all?: (SimulationModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SimulationModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    notes?: boolean | number
    search?: boolean | number
    generate?: boolean | number
    taxonomyId?: boolean | number
    taxonomyRel?: boolean | number
    enterpriseContext?: boolean | number
    simulationContext?: boolean | number
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseSimulationModelQueryGenqlSelection{
    all?: (UseCaseSimulationModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseSimulationModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    deployedId?: boolean | number
    keyFeatures?: boolean | number
    context?: boolean | number
    data?: boolean | number
    valid?: boolean | number
    type?: boolean | number
    simulationId?: boolean | number
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseLibraryModelQueryGenqlSelection{
    all?: (UseCaseLibraryModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseLibraryModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    data?: boolean | number
    sourceId?: boolean | number
    sourceRel?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface M1PrepModelQueryGenqlSelection{
    all?: (M1PrepModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface M1PrepModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    context?: boolean | number
    companyAnalysis?: boolean | number
    products?: boolean | number
    competitiveLandscape?: boolean | number
    conversation?: boolean | number
    salesforce?: boolean | number
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


    const Query_possibleTypes: string[] = ['Query']
    export const isQuery = (obj?: { __typename?: any } | null): obj is Query => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuery"')
      return Query_possibleTypes.includes(obj.__typename)
    }
    


    const CommonQuery_possibleTypes: string[] = ['CommonQuery']
    export const isCommonQuery = (obj?: { __typename?: any } | null): obj is CommonQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCommonQuery"')
      return CommonQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CommonDataQuery_possibleTypes: string[] = ['CommonDataQuery']
    export const isCommonDataQuery = (obj?: { __typename?: any } | null): obj is CommonDataQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCommonDataQuery"')
      return CommonDataQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CommonDataObjectType_possibleTypes: string[] = ['CommonDataObjectType']
    export const isCommonDataObjectType = (obj?: { __typename?: any } | null): obj is CommonDataObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCommonDataObjectType"')
      return CommonDataObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const OrganizationModelQuery_possibleTypes: string[] = ['OrganizationModelQuery']
    export const isOrganizationModelQuery = (obj?: { __typename?: any } | null): obj is OrganizationModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isOrganizationModelQuery"')
      return OrganizationModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const OrganizationModelObjectType_possibleTypes: string[] = ['OrganizationModelObjectType']
    export const isOrganizationModelObjectType = (obj?: { __typename?: any } | null): obj is OrganizationModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isOrganizationModelObjectType"')
      return OrganizationModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const E2ETaxonomyQuery_possibleTypes: string[] = ['E2ETaxonomyQuery']
    export const isE2ETaxonomyQuery = (obj?: { __typename?: any } | null): obj is E2ETaxonomyQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isE2ETaxonomyQuery"')
      return E2ETaxonomyQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryFunctionModelQuery_possibleTypes: string[] = ['CategoryFunctionModelQuery']
    export const isCategoryFunctionModelQuery = (obj?: { __typename?: any } | null): obj is CategoryFunctionModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryFunctionModelQuery"')
      return CategoryFunctionModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryFunctionModelObjectType_possibleTypes: string[] = ['CategoryFunctionModelObjectType']
    export const isCategoryFunctionModelObjectType = (obj?: { __typename?: any } | null): obj is CategoryFunctionModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryFunctionModelObjectType"')
      return CategoryFunctionModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const FunctionModelChildObjectType_possibleTypes: string[] = ['FunctionModelChildObjectType']
    export const isFunctionModelChildObjectType = (obj?: { __typename?: any } | null): obj is FunctionModelChildObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFunctionModelChildObjectType"')
      return FunctionModelChildObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const Level1ModelChildObjectType_possibleTypes: string[] = ['Level1ModelChildObjectType']
    export const isLevel1ModelChildObjectType = (obj?: { __typename?: any } | null): obj is Level1ModelChildObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel1ModelChildObjectType"')
      return Level1ModelChildObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const Level2ModelChildObjectType_possibleTypes: string[] = ['Level2ModelChildObjectType']
    export const isLevel2ModelChildObjectType = (obj?: { __typename?: any } | null): obj is Level2ModelChildObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel2ModelChildObjectType"')
      return Level2ModelChildObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramE2EModelQuery_possibleTypes: string[] = ['DiagramE2EModelQuery']
    export const isDiagramE2EModelQuery = (obj?: { __typename?: any } | null): obj is DiagramE2EModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramE2EModelQuery"')
      return DiagramE2EModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramE2EModelObjectType_possibleTypes: string[] = ['DiagramE2EModelObjectType']
    export const isDiagramE2EModelObjectType = (obj?: { __typename?: any } | null): obj is DiagramE2EModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramE2EModelObjectType"')
      return DiagramE2EModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramProcessModelQuery_possibleTypes: string[] = ['DiagramProcessModelQuery']
    export const isDiagramProcessModelQuery = (obj?: { __typename?: any } | null): obj is DiagramProcessModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramProcessModelQuery"')
      return DiagramProcessModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramProcessModelObjectType_possibleTypes: string[] = ['DiagramProcessModelObjectType']
    export const isDiagramProcessModelObjectType = (obj?: { __typename?: any } | null): obj is DiagramProcessModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramProcessModelObjectType"')
      return DiagramProcessModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramTemplateModelQuery_possibleTypes: string[] = ['DiagramTemplateModelQuery']
    export const isDiagramTemplateModelQuery = (obj?: { __typename?: any } | null): obj is DiagramTemplateModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramTemplateModelQuery"')
      return DiagramTemplateModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramTemplateModelObjectType_possibleTypes: string[] = ['DiagramTemplateModelObjectType']
    export const isDiagramTemplateModelObjectType = (obj?: { __typename?: any } | null): obj is DiagramTemplateModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramTemplateModelObjectType"')
      return DiagramTemplateModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const FunctionModelQuery_possibleTypes: string[] = ['FunctionModelQuery']
    export const isFunctionModelQuery = (obj?: { __typename?: any } | null): obj is FunctionModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFunctionModelQuery"')
      return FunctionModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const FunctionModelObjectType_possibleTypes: string[] = ['FunctionModelObjectType']
    export const isFunctionModelObjectType = (obj?: { __typename?: any } | null): obj is FunctionModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFunctionModelObjectType"')
      return FunctionModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const Level1ModelQuery_possibleTypes: string[] = ['Level1ModelQuery']
    export const isLevel1ModelQuery = (obj?: { __typename?: any } | null): obj is Level1ModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel1ModelQuery"')
      return Level1ModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const Level1ModelObjectType_possibleTypes: string[] = ['Level1ModelObjectType']
    export const isLevel1ModelObjectType = (obj?: { __typename?: any } | null): obj is Level1ModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel1ModelObjectType"')
      return Level1ModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const Level2ModelQuery_possibleTypes: string[] = ['Level2ModelQuery']
    export const isLevel2ModelQuery = (obj?: { __typename?: any } | null): obj is Level2ModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel2ModelQuery"')
      return Level2ModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const Level2ModelObjectType_possibleTypes: string[] = ['Level2ModelObjectType']
    export const isLevel2ModelObjectType = (obj?: { __typename?: any } | null): obj is Level2ModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel2ModelObjectType"')
      return Level2ModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const TechnologyModelQuery_possibleTypes: string[] = ['TechnologyModelQuery']
    export const isTechnologyModelQuery = (obj?: { __typename?: any } | null): obj is TechnologyModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTechnologyModelQuery"')
      return TechnologyModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const TechnologyModelObjectType_possibleTypes: string[] = ['TechnologyModelObjectType']
    export const isTechnologyModelObjectType = (obj?: { __typename?: any } | null): obj is TechnologyModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTechnologyModelObjectType"')
      return TechnologyModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const DigitalWorkerModelQuery_possibleTypes: string[] = ['DigitalWorkerModelQuery']
    export const isDigitalWorkerModelQuery = (obj?: { __typename?: any } | null): obj is DigitalWorkerModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDigitalWorkerModelQuery"')
      return DigitalWorkerModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const DigitalWorkerModelObjectType_possibleTypes: string[] = ['DigitalWorkerModelObjectType']
    export const isDigitalWorkerModelObjectType = (obj?: { __typename?: any } | null): obj is DigitalWorkerModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDigitalWorkerModelObjectType"')
      return DigitalWorkerModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const TechnologyByLevel2ModelQuery_possibleTypes: string[] = ['TechnologyByLevel2ModelQuery']
    export const isTechnologyByLevel2ModelQuery = (obj?: { __typename?: any } | null): obj is TechnologyByLevel2ModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTechnologyByLevel2ModelQuery"')
      return TechnologyByLevel2ModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const TechnologyByLevel2ModelObjectType_possibleTypes: string[] = ['TechnologyByLevel2ModelObjectType']
    export const isTechnologyByLevel2ModelObjectType = (obj?: { __typename?: any } | null): obj is TechnologyByLevel2ModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTechnologyByLevel2ModelObjectType"')
      return TechnologyByLevel2ModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const AIOpportunityModelQuery_possibleTypes: string[] = ['AIOpportunityModelQuery']
    export const isAIOpportunityModelQuery = (obj?: { __typename?: any } | null): obj is AIOpportunityModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAIOpportunityModelQuery"')
      return AIOpportunityModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const AIOpportunityModelObjectType_possibleTypes: string[] = ['AIOpportunityModelObjectType']
    export const isAIOpportunityModelObjectType = (obj?: { __typename?: any } | null): obj is AIOpportunityModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAIOpportunityModelObjectType"')
      return AIOpportunityModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const PocModelObjectType_possibleTypes: string[] = ['PocModelObjectType']
    export const isPocModelObjectType = (obj?: { __typename?: any } | null): obj is PocModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPocModelObjectType"')
      return PocModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseModelObjectType_possibleTypes: string[] = ['UseCaseModelObjectType']
    export const isUseCaseModelObjectType = (obj?: { __typename?: any } | null): obj is UseCaseModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseModelObjectType"')
      return UseCaseModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramModelObjectType_possibleTypes: string[] = ['DiagramModelObjectType']
    export const isDiagramModelObjectType = (obj?: { __typename?: any } | null): obj is DiagramModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramModelObjectType"')
      return DiagramModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const SolutionQuery_possibleTypes: string[] = ['SolutionQuery']
    export const isSolutionQuery = (obj?: { __typename?: any } | null): obj is SolutionQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSolutionQuery"')
      return SolutionQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionModelQuery_possibleTypes: string[] = ['CategoryQuestionModelQuery']
    export const isCategoryQuestionModelQuery = (obj?: { __typename?: any } | null): obj is CategoryQuestionModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionModelQuery"')
      return CategoryQuestionModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionModelObjectType_possibleTypes: string[] = ['CategoryQuestionModelObjectType']
    export const isCategoryQuestionModelObjectType = (obj?: { __typename?: any } | null): obj is CategoryQuestionModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionModelObjectType"')
      return CategoryQuestionModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionModelQuery_possibleTypes: string[] = ['SubCategoryQuestionModelQuery']
    export const isSubCategoryQuestionModelQuery = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionModelQuery"')
      return SubCategoryQuestionModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionModelObjectType_possibleTypes: string[] = ['SubCategoryQuestionModelObjectType']
    export const isSubCategoryQuestionModelObjectType = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionModelObjectType"')
      return SubCategoryQuestionModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const FlowModelQuery_possibleTypes: string[] = ['FlowModelQuery']
    export const isFlowModelQuery = (obj?: { __typename?: any } | null): obj is FlowModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFlowModelQuery"')
      return FlowModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const FlowModelObjectType_possibleTypes: string[] = ['FlowModelObjectType']
    export const isFlowModelObjectType = (obj?: { __typename?: any } | null): obj is FlowModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFlowModelObjectType"')
      return FlowModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const FunctionFilterModelQuery_possibleTypes: string[] = ['FunctionFilterModelQuery']
    export const isFunctionFilterModelQuery = (obj?: { __typename?: any } | null): obj is FunctionFilterModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFunctionFilterModelQuery"')
      return FunctionFilterModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const FunctionFilterModelObjectType_possibleTypes: string[] = ['FunctionFilterModelObjectType']
    export const isFunctionFilterModelObjectType = (obj?: { __typename?: any } | null): obj is FunctionFilterModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFunctionFilterModelObjectType"')
      return FunctionFilterModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const SkillModelQuery_possibleTypes: string[] = ['SkillModelQuery']
    export const isSkillModelQuery = (obj?: { __typename?: any } | null): obj is SkillModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSkillModelQuery"')
      return SkillModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const SkillModelObjectType_possibleTypes: string[] = ['SkillModelObjectType']
    export const isSkillModelObjectType = (obj?: { __typename?: any } | null): obj is SkillModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSkillModelObjectType"')
      return SkillModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const AiLeverModelQuery_possibleTypes: string[] = ['AiLeverModelQuery']
    export const isAiLeverModelQuery = (obj?: { __typename?: any } | null): obj is AiLeverModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAiLeverModelQuery"')
      return AiLeverModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const AiLeverModelObjectType_possibleTypes: string[] = ['AiLeverModelObjectType']
    export const isAiLeverModelObjectType = (obj?: { __typename?: any } | null): obj is AiLeverModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAiLeverModelObjectType"')
      return AiLeverModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const SolutionModelQuery_possibleTypes: string[] = ['SolutionModelQuery']
    export const isSolutionModelQuery = (obj?: { __typename?: any } | null): obj is SolutionModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSolutionModelQuery"')
      return SolutionModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const SolutionModelObjectType_possibleTypes: string[] = ['SolutionModelObjectType']
    export const isSolutionModelObjectType = (obj?: { __typename?: any } | null): obj is SolutionModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSolutionModelObjectType"')
      return SolutionModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const IconModelObjectType_possibleTypes: string[] = ['IconModelObjectType']
    export const isIconModelObjectType = (obj?: { __typename?: any } | null): obj is IconModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isIconModelObjectType"')
      return IconModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionModelQuery_possibleTypes: string[] = ['QuestionModelQuery']
    export const isQuestionModelQuery = (obj?: { __typename?: any } | null): obj is QuestionModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionModelQuery"')
      return QuestionModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionModelObjectType_possibleTypes: string[] = ['QuestionModelObjectType']
    export const isQuestionModelObjectType = (obj?: { __typename?: any } | null): obj is QuestionModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionModelObjectType"')
      return QuestionModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const ToolModelQuery_possibleTypes: string[] = ['ToolModelQuery']
    export const isToolModelQuery = (obj?: { __typename?: any } | null): obj is ToolModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isToolModelQuery"')
      return ToolModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const ToolModelObjectType_possibleTypes: string[] = ['ToolModelObjectType']
    export const isToolModelObjectType = (obj?: { __typename?: any } | null): obj is ToolModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isToolModelObjectType"')
      return ToolModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const IconModelQuery_possibleTypes: string[] = ['IconModelQuery']
    export const isIconModelQuery = (obj?: { __typename?: any } | null): obj is IconModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isIconModelQuery"')
      return IconModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseQuery_possibleTypes: string[] = ['UseCaseQuery']
    export const isUseCaseQuery = (obj?: { __typename?: any } | null): obj is UseCaseQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseQuery"')
      return UseCaseQuery_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramModelQuery_possibleTypes: string[] = ['DiagramModelQuery']
    export const isDiagramModelQuery = (obj?: { __typename?: any } | null): obj is DiagramModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramModelQuery"')
      return DiagramModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseModelQuery_possibleTypes: string[] = ['UseCaseModelQuery']
    export const isUseCaseModelQuery = (obj?: { __typename?: any } | null): obj is UseCaseModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseModelQuery"')
      return UseCaseModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const PocModelQuery_possibleTypes: string[] = ['PocModelQuery']
    export const isPocModelQuery = (obj?: { __typename?: any } | null): obj is PocModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPocModelQuery"')
      return PocModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const MaturityQuery_possibleTypes: string[] = ['MaturityQuery']
    export const isMaturityQuery = (obj?: { __typename?: any } | null): obj is MaturityQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMaturityQuery"')
      return MaturityQuery_possibleTypes.includes(obj.__typename)
    }
    


    const AssessmentMaturityModelQuery_possibleTypes: string[] = ['AssessmentMaturityModelQuery']
    export const isAssessmentMaturityModelQuery = (obj?: { __typename?: any } | null): obj is AssessmentMaturityModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAssessmentMaturityModelQuery"')
      return AssessmentMaturityModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const AssessmentMaturityModelObjectType_possibleTypes: string[] = ['AssessmentMaturityModelObjectType']
    export const isAssessmentMaturityModelObjectType = (obj?: { __typename?: any } | null): obj is AssessmentMaturityModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAssessmentMaturityModelObjectType"')
      return AssessmentMaturityModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionMaturityModelQuery_possibleTypes: string[] = ['QuestionMaturityModelQuery']
    export const isQuestionMaturityModelQuery = (obj?: { __typename?: any } | null): obj is QuestionMaturityModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionMaturityModelQuery"')
      return QuestionMaturityModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionMaturityModelObjectType_possibleTypes: string[] = ['QuestionMaturityModelObjectType']
    export const isQuestionMaturityModelObjectType = (obj?: { __typename?: any } | null): obj is QuestionMaturityModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionMaturityModelObjectType"')
      return QuestionMaturityModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionMaturityModelQuery_possibleTypes: string[] = ['SubCategoryQuestionMaturityModelQuery']
    export const isSubCategoryQuestionMaturityModelQuery = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionMaturityModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionMaturityModelQuery"')
      return SubCategoryQuestionMaturityModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionMaturityModelObjectType_possibleTypes: string[] = ['SubCategoryQuestionMaturityModelObjectType']
    export const isSubCategoryQuestionMaturityModelObjectType = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionMaturityModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionMaturityModelObjectType"')
      return SubCategoryQuestionMaturityModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionMaturityModelQuery_possibleTypes: string[] = ['CategoryQuestionMaturityModelQuery']
    export const isCategoryQuestionMaturityModelQuery = (obj?: { __typename?: any } | null): obj is CategoryQuestionMaturityModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionMaturityModelQuery"')
      return CategoryQuestionMaturityModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionMaturityModelObjectType_possibleTypes: string[] = ['CategoryQuestionMaturityModelObjectType']
    export const isCategoryQuestionMaturityModelObjectType = (obj?: { __typename?: any } | null): obj is CategoryQuestionMaturityModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionMaturityModelObjectType"')
      return CategoryQuestionMaturityModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const SimulationQuery_possibleTypes: string[] = ['SimulationQuery']
    export const isSimulationQuery = (obj?: { __typename?: any } | null): obj is SimulationQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSimulationQuery"')
      return SimulationQuery_possibleTypes.includes(obj.__typename)
    }
    


    const EnterpriseContextModelQuery_possibleTypes: string[] = ['EnterpriseContextModelQuery']
    export const isEnterpriseContextModelQuery = (obj?: { __typename?: any } | null): obj is EnterpriseContextModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEnterpriseContextModelQuery"')
      return EnterpriseContextModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const EnterpriseContextModelObjectType_possibleTypes: string[] = ['EnterpriseContextModelObjectType']
    export const isEnterpriseContextModelObjectType = (obj?: { __typename?: any } | null): obj is EnterpriseContextModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEnterpriseContextModelObjectType"')
      return EnterpriseContextModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const SimulationModelQuery_possibleTypes: string[] = ['SimulationModelQuery']
    export const isSimulationModelQuery = (obj?: { __typename?: any } | null): obj is SimulationModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSimulationModelQuery"')
      return SimulationModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const SimulationModelObjectType_possibleTypes: string[] = ['SimulationModelObjectType']
    export const isSimulationModelObjectType = (obj?: { __typename?: any } | null): obj is SimulationModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSimulationModelObjectType"')
      return SimulationModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseSimulationModelQuery_possibleTypes: string[] = ['UseCaseSimulationModelQuery']
    export const isUseCaseSimulationModelQuery = (obj?: { __typename?: any } | null): obj is UseCaseSimulationModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseSimulationModelQuery"')
      return UseCaseSimulationModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseSimulationModelObjectType_possibleTypes: string[] = ['UseCaseSimulationModelObjectType']
    export const isUseCaseSimulationModelObjectType = (obj?: { __typename?: any } | null): obj is UseCaseSimulationModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseSimulationModelObjectType"')
      return UseCaseSimulationModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseLibraryModelQuery_possibleTypes: string[] = ['UseCaseLibraryModelQuery']
    export const isUseCaseLibraryModelQuery = (obj?: { __typename?: any } | null): obj is UseCaseLibraryModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseLibraryModelQuery"')
      return UseCaseLibraryModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseLibraryModelObjectType_possibleTypes: string[] = ['UseCaseLibraryModelObjectType']
    export const isUseCaseLibraryModelObjectType = (obj?: { __typename?: any } | null): obj is UseCaseLibraryModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseLibraryModelObjectType"')
      return UseCaseLibraryModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const M1PrepModelQuery_possibleTypes: string[] = ['M1PrepModelQuery']
    export const isM1PrepModelQuery = (obj?: { __typename?: any } | null): obj is M1PrepModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isM1PrepModelQuery"')
      return M1PrepModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const M1PrepModelObjectType_possibleTypes: string[] = ['M1PrepModelObjectType']
    export const isM1PrepModelObjectType = (obj?: { __typename?: any } | null): obj is M1PrepModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isM1PrepModelObjectType"')
      return M1PrepModelObjectType_possibleTypes.includes(obj.__typename)
    }
    

export const enumE2ETaxonomyCategoryFunctionTypeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}

export const enumE2ETaxonomyCategoryFunctionOfficeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}

export const enumE2ETaxonomyFunctionTypeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}

export const enumE2ETaxonomyLevel1TypeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}

export const enumE2ETaxonomyLevel2TypeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}

export const enumSimulationSimulationTaxonomyRelChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}

export const enumSimulationUseCaseSimulationTypeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const
}

export const enumSimulationUseCaseLibrarySourceRelChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}
