import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { environment } from '../../../../../../environments/environment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-attachment-modal',
    templateUrl: './design-nexus-attachment-modal.component.html',
    providers: [MessageService],
})
export class AttachmentModalComponent implements OnInit {
    name = new FormControl('');
    description = new FormControl('');
    files: File[] = [];
    displayModal: boolean = false;
    showOnlyFileInput: boolean = false;
    previousFiles: any[] = [];

    @Output() attachmentSubmitted = new EventEmitter<void>();

    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private primeNgConfig: PrimeNGConfig,
    ) {}

    ngOnInit() {
        this.showOnlyFileInput = this.config?.data?.showOnlyFileInput ?? false;
        this.previousFiles = Array.isArray(this.config?.data?.files) ? [...this.config.data.files] : [];
    }

    submitAttachment() {
        if (this.files.length > 0) {
            const formData = new FormData();
            this.files.forEach((file) => {
                formData.append('upload', file, file.name);
                console.log('File being uploaded:', file.name);
            });
            formData.append('name', this.name.value || '');
            formData.append('description', this.description.value || '');

            this.http.post(environment.url + '/api/nexus/files', formData).subscribe({
                next: (response: any) => {
                    console.log('Upload response:', response);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Files uploaded successfully.',
                    });
                    this.attachmentSubmitted.emit();
                    this.ref.close([
						...this.previousFiles,
						...this.formatAttachmentData(response.files)
					]);
                },
                error: (error) => {
                    console.error('Upload failed:', error);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Upload failed. Please try again later.',
                    });
                },
            });
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Please select files to upload.',
            });
        }
    }

	choose(event: any, callback: any) {
		callback();
	}

    onFileSelect(event: any) {
        if (event?.files) {
            this.files = Array.isArray(event.files) ? event.files : Array.from(event.files);
        } else {
            console.error('No files selected or invalid format');
            this.files = [];
        }
    }

    formatAttachmentData(response?: string[]) {
        return this.files.map((file, index) => ({
			url: response?.[index],
			name: file.name,
			type: file.type,
			size: this.formatSize(file.size),
		}));
    }

    formatSize(bytes?: number) {
        const k = 1024;
        const dm = 3;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        if (bytes === 0 || !bytes) {
            return `0 ${sizes[0]}`;
        }

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

        return `${formattedSize} ${sizes[i]}`;
    }
}
