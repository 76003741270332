import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { AttachmentModalComponent } from '../attachment-modal/design-nexus-attachment-modal.component';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-design-nexus-inputs-data-source-table',
    templateUrl: './design-nexus-inputs-data-source-table.component.html',
    styleUrls: ['./design-nexus-inputs-data-source-table.component.scss'],
    providers: [],
})
export class DesignNexusInputsDataSourceTableComponent implements OnInit, OnDestroy, OnChanges {
    @Input() rows: any[] = [];
    @Output() rowsChange: EventEmitter<any[]> = new EventEmitter();

    options: any = {
        integrationRequirements: [
            'AWS Data Lake Access',
        ],
        format: [
            'Excel Spreadsheet',
            'Email',
            'Word Document',
            'PDF',
            'SQL DB',
        ],
        type: [
            'Numeric',
            'Text',
            'Text/Numeric',
        ],
        freqUpdate: [
            'Monthly',
            'Daily',
            'Weekly',
            'Quarterly',
        ],
        sourceSystems: [
            'AWS Analytics',
            'Salesforce',
        ],
    };

    columns = [
        { field: 'knowledgeData', header: 'Knowledge Data', element: 'input', width: '13%' },
        { field: 'description', header: 'Description', element: 'textarea', width: '13%' },
        { field: 'integrationRequirements', header: 'Integration Requirements', element: 'select', width: '13%' },
        { field: 'format', header: 'Format', element: 'select', width: '13%' },
        { field: 'type', header: 'Type', element: 'select', width: '13%' },
        { field: 'freqUpdate', header: 'Freq Update', element: 'select', width: '13%' },
        { field: 'sourceSystems', header: 'Source Systems', element: 'select', width: '13%' },
        { field: 'attachments', header: 'Attachments', element: 'file', width: '9%' },
    ];

    constructor(
        private dialogService: DialogService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log("changes", changes);
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.onRowUpdate();
    }


    addRow() {
        this.rows.unshift(
            {
                id: uuid(),
                knowledgeData: '',
                description: '',
                integrationRequirements: '',
                format: '',
                type: '',
                freqUpdate: '',
                sourceSystems: '',
                attachments: '',
            },
        );

        setTimeout(() => {
            this.startEditingFirstCell();
        }, 100);
    }

    startEditingFirstCell() {
        const cellEditors = document.querySelectorAll('p-cellEditor');
        const firstCellEditor = cellEditors[0] as HTMLElement;

        if (firstCellEditor) {
            firstCellEditor.click();
        }
    }


    onRowUpdate() {
        this.rowsChange.emit(this.rows);
    }

    openAttachModal(rowId: any) {
        const row = this.rows.find(item => item.id === rowId);

        const ref = this.dialogService.open(AttachmentModalComponent, {
            data: {
                showOnlyFileInput: true,
                files: row.attachments || [],
            },
            header: 'Add Attachment',
            width: '50%',
        });
        console.log("rowId", rowId)
        ref.onClose.subscribe(attachmentData => {
            if (attachmentData) {
                const updatedRows = this.rows.map(item =>
                    item.id === rowId
                        ? { ...item, attachments: attachmentData }
                        : item
                );
                console.log(updatedRows)
                this.rowsChange.emit(updatedRows);
            }
        });
    }
}
